const bengkel = [
    {
      "WILAYAH": "ANYER",
      "NAMA BENGKEL": "SUN MOTOR",
      "ALAMAT": "JL. RAYA ANYER NO. 65 WARUNG JUET, SAMANG RAYA CILEGON- BANTEN 42443",
      "TELEPHONE": "0254-315147",
      "EMAIL": "sun_motor16@yahoo.com",
      "PIC": "BP. ERLAND",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9100"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Bengkel  PT. Permata Auto Gallery",
      "ALAMAT": "Jl. Ahmad Yani Utara No.999 Peguyangan Denpasar Bali",
      "TELEPHONE": "T : 0361 -  423188 / 9008249",
      "EMAIL": "yuni_mktpag@yahoo.co.id; trisna_maryanti@yahoo.com",
      "PIC": "Toni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3223"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "CV Paramitha  Auto Graha",
      "ALAMAT": "Taman Jimbaran gang Seruni No. 1 Mumbul  Jimbaran Bali",
      "TELEPHONE": "T : 085100440032 / 0361 - 8480369 / 8480469",
      "EMAIL": "yuni_mktpag@yahoo.co.id; trisna_maryanti@yahoo.com",
      "PIC": "Kamto / Yunus",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3224"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "CV Paramitha Anugerah Govinda",
      "ALAMAT": "By Pass Dharma Giri Jl. Banteng No.8 Blahbatuh Gianyar",
      "TELEPHONE": "T : 0361 - 422274 / 085338427070",
      "EMAIL": "yuni_mktpag@yahoo.co.id; trisna_maryanti@yahoo.com",
      "PIC": "Hartono Tan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3225"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Muji Motor",
      "ALAMAT": "Jl. Trengguli Denpasar",
      "TELEPHONE": "T: 0361- 464646",
      "EMAIL": "photo.mujimotor1@gmail.com",
      "PIC": "Suryanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3226"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Panji Jaya Motor",
      "ALAMAT": "Jl. Pendidikan IV No. 26 Sidakarya, Denpasar Selatan",
      "TELEPHONE": "T : 0361 - 727986 ; 0362 � 3361117",
      "EMAIL": "panjidjaya@ymail.com; panjiwindjaya@yahoo.com",
      "PIC": "Rudy / Rina",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3227"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Auto Care Body Repair",
      "ALAMAT": "Jl. Tukad Balian No. 888, Renon Denpasar - Bali",
      "TELEPHONE": "T: 0361-722019 / 085138929665",
      "EMAIL": "dewaayuekaputri@gmail.com; autocare.bodyrepair.dps@gmail.com",
      "PIC": "Pramono / Novi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3228"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Panji Laras Motor",
      "ALAMAT": "Jl. Pemelisan No. 07 Suwung Batan Kendal, Denpasar",
      "TELEPHONE": "T: 0361-720965, F: 0361-720887",
      "EMAIL": "panjilarasmotor@yahoo.com",
      "PIC": "Kunawie / Willy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3229"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Bumen Redja Abadi - Denpasar",
      "ALAMAT": "Jl. Imam Bonjol No. 375 R",
      "TELEPHONE": "T: 0361-483002",
      "EMAIL": "ketut.yasa@bra.nag.co.id",
      "PIC": "Annisa / Arif",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3230"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Auto 2000 - Sanur",
      "ALAMAT": "Jl. Bypass I Gusti Ngurah Rai No. 395, Sanur Denpasar",
      "TELEPHONE": "T : 0361 282000",
      "EMAIL": "dimas.anjardinasti@tso.astra.co.id",
      "PIC": "Fitri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3231"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Astra International - BMW",
      "ALAMAT": "Jl. Cokrominoto No. 52, Denpasar Bali",
      "TELEPHONE": "T : 0361 - 419386",
      "EMAIL": "sularno@bmw.astra.co.id",
      "PIC": "Supandi / Oscar / Yasrul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3232"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Astrido Toyota (PT. Astrido Jaya Mobilindo)",
      "ALAMAT": "Jl. Mahendradatta No.229, Padangsambian, Denpasar Bali",
      "TELEPHONE": "T : 0361 - 8466287; 081585579579",
      "EMAIL": "ariksriwulandari@gmial.com; servis-bali@astridogroup.com",
      "PIC": "Bpk. Ari",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3233"
    },
    {
      "WILAYAH": "Bali",
      "NAMA BENGKEL": "Suzuki Indobali Trada (Indomobil)",
      "ALAMAT": "Jl. Bypass Ngurah Rai 104, Sanur",
      "TELEPHONE": "T: 0361-466030, 0361-466032",
      "EMAIL": "erik.susanto11@gmail.com",
      "PIC": "Indah / Faki",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3234"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "NEW BARONET",
      "ALAMAT": "Jl.Jendral Sudirman No.1 A-B",
      "TELEPHONE": "(0542) 426110",
      "EMAIL": "-",
      "PIC": "-",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6286"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "IMOLA MOTOR",
      "ALAMAT": "Jl. Soekarno Hatta Km 4.5 No.20",
      "TELEPHONE": "(0524) 861618",
      "EMAIL": "-",
      "PIC": "Bpk. Ali",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6287"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "DIAN CITRA",
      "ALAMAT": "JL. TIGA DALAM NO. 43",
      "TELEPHONE": "6,28E+12",
      "EMAIL": "bengkeldiancitra@gmail.com",
      "PIC": "BPK. LEWI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9101"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "GIFA MANDIRI",
      "ALAMAT": "JL. SYARIFUDIN YOES GG. DEPAN POLDA I",
      "TELEPHONE": "0542-874664",
      "EMAIL": "gifamandiri@ymail.com",
      "PIC": "IBU MEGA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9102"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "TRITRA INDOLIU PERSADA",
      "ALAMAT": "JL. MT. HARYONO DALAM RT.40 NO. 84",
      "TELEPHONE": "0542-7206499",
      "EMAIL": "tritra_ip@yahoo.co.id",
      "PIC": "IBU. EVA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9103"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "PERDANA",
      "ALAMAT": "JL. LETJEND S PARMAN NO. 03 RT. 37, GN. GUNTUR",
      "TELEPHONE": "0542-440822",
      "EMAIL": "annisasahara.93@gmail.com",
      "PIC": "IBU NISSA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9104"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "IMOLA",
      "ALAMAT": "JL. SOEKARNO HATTA NO. 20, BATU AMPAR",
      "TELEPHONE": 62811591552,
      "EMAIL": "imolamotor.bpn@gmail.com",
      "PIC": "BPK. WILLY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9105"
    },
    {
      "WILAYAH": "BALIKPAPAN",
      "NAMA BENGKEL": "SAMEKARINDO AUTHORIZED SUZUKI",
      "ALAMAT": "JL. AMAD YANI NO.9",
      "TELEPHONE": "0542-733633",
      "EMAIL": "zulkifli@samekarindo-indah.com",
      "PIC": "BP. ZULKIFLI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9106"
    },
    {
      "WILAYAH": "Balikpapan",
      "NAMA BENGKEL": "New Baronet",
      "ALAMAT": "Jl. Jend. Sudirman No. 1 A- 1B, Balikpapan",
      "TELEPHONE": "T : 0542.426110",
      "EMAIL": "newbaronet@gmail.com; newbaronet@yahoo.com",
      "PIC": "Rengga / Yadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3235"
    },
    {
      "WILAYAH": "Balikpapan",
      "NAMA BENGKEL": "Auto 2000 - Balikpapan",
      "ALAMAT": "Jl. MT. Haryono RT 31 no.67 Balikpapan Selatan",
      "TELEPHONE": "T : 0542 73200",
      "EMAIL": "tika.hariyanti@tso.astra.co.id",
      "PIC": "Fina / Mega / Ibu Ester",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3236"
    },
    {
      "WILAYAH": "Banda Aceh",
      "NAMA BENGKEL": "Capella Body Centre - Banda Aceh",
      "ALAMAT": "Jl. Soekarno hatta,depan spbu Lampeuneurut - Aceh Besar",
      "TELEPHONE": "T: 0813-20504444; 0651 - 42500; 6302121",
      "EMAIL": "tiacbcaceh@gmail.com",
      "PIC": "Ivan / Gozie",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3237"
    },
    {
      "WILAYAH": "Banda Aceh",
      "NAMA BENGKEL": "Pekanbaru",
      "ALAMAT": "Jl. T.P. Nyak Makam No. 88, Banda Aceh",
      "TELEPHONE": "T: 0651 - 7555551;0811684223",
      "EMAIL": "fendi.promotor1@gmail.com",
      "PIC": "Dede Haryadi / Diki",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3238"
    },
    {
      "WILAYAH": "Bandar Lampung",
      "NAMA BENGKEL": "Auto 2000 Radin Inten",
      "ALAMAT": "Jl. Raden Intan 61-A, Tanjungkarang 35111",
      "TELEPHONE": "T: 0721-252000",
      "EMAIL": "adriansyah@tso.astra.co.id",
      "PIC": "Joko / Muhaimin / Nurul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3239"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "GLOWS",
      "ALAMAT": "Jl. Jendral Ahmad Yani No.612",
      "TELEPHONE": "(022) 7205206",
      "EMAIL": "-",
      "PIC": "Bpk. Liemas Handy",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6288"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO CEMERLANG",
      "ALAMAT": "Jl.Soekarno Hatta no.390",
      "TELEPHONE": "(022) 522 1918",
      "EMAIL": "-",
      "PIC": "Bpk.Budi/Bpk.Dede",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6289"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "BINTANG JAYA 2",
      "ALAMAT": "Jl.Malabar No.31",
      "TELEPHONE": "(022) 733.4997",
      "EMAIL": "-",
      "PIC": "Bpk.Haryanto Tanubrata",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6290"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO COLOUR",
      "ALAMAT": "Jl.Peta no.115",
      "TELEPHONE": "(022) 6108 8887",
      "EMAIL": "-",
      "PIC": "Ibu Darmi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6291"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "THE STATION",
      "ALAMAT": "Jl.Setra Murni no.1",
      "TELEPHONE": "(022) 9125 0460",
      "EMAIL": "-",
      "PIC": "Bpk.Iwan",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6292"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "OTTO CEMERLANG",
      "ALAMAT": "Jl.Kom.Ud.Supadio no 30",
      "TELEPHONE": "(022) 607 5838",
      "EMAIL": "-",
      "PIC": "Bpk.Yudi/Dadang",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6293"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "MOBIL ROBPER",
      "ALAMAT": "Jl.Terusan Pasirkoja 223",
      "TELEPHONE": "(022) 607 0064",
      "EMAIL": "-",
      "PIC": "Bpk.Teguh",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6294"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "MENTARI",
      "ALAMAT": "Jl.Soekarno Hatta 250 B",
      "TELEPHONE": "(022) 543 1515",
      "EMAIL": "-",
      "PIC": "Bpk.Nandang/Deni",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6295"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO CEMERLANG",
      "ALAMAT": "JLN.SOEKARNO HATTA NO.390 BANDUNG",
      "TELEPHONE": "022-5221918 / 5221926 / 5221951",
      "EMAIL": "auto_cemerlang@yahoo.co.id",
      "PIC": "BPK. ATOM",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9107"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO BLINK",
      "ALAMAT": "JLN.GATOT SUBROTO NO.37 BANDUNG",
      "TELEPHONE": "022-70820037",
      "EMAIL": "autoblink168@gmail.com",
      "PIC": "IBU RUTH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9108"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "GAMO",
      "ALAMAT": "JLN.CIHAMPELAS NO.92 BANDUNG",
      "TELEPHONE": "022-2032066",
      "EMAIL": "gamoautobody@gmail.com",
      "PIC": "IBU YATNI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9109"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "GARUDA MOTOR",
      "ALAMAT": "JLN.GARUDA NO.2 BANDUNG",
      "TELEPHONE": "022-6016744",
      "EMAIL": "garudamotor.sa@gmail.com",
      "PIC": "BPK ENTIS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9110"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "HONDA IBRM",
      "ALAMAT": "JLN.CICENDO NO.18 BANDUNG",
      "TELEPHONE": "022-4240888",
      "EMAIL": "estbp1.ibrm@hondawestjava.com",
      "PIC": "BPK IWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9111"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "LCC",
      "ALAMAT": "JLN AHMAD YANI NO.889 BANDUNG",
      "TELEPHONE": "022-7206400",
      "EMAIL": "contact@lccmotor.com",
      "PIC": "IBU MEGI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9112"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "MEGAH JAYA",
      "ALAMAT": "JLN.BKR NO.28 BANDUNG",
      "TELEPHONE": "022-5227225",
      "EMAIL": "fitrimegahjaya888@gmail.com",
      "PIC": "BPK DUDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9113"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "WAHANA AUTO CARE",
      "ALAMAT": "JLN.JEND SUDIRMAN 297 BANDUNG",
      "TELEPHONE": "022-6033111",
      "EMAIL": "wac.cso@gmail.com",
      "PIC": "IBU HANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9114"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "MENTARI",
      "ALAMAT": "JLN.SOEKARNO HATTA NO.250B BANDUNG",
      "TELEPHONE": "022-5431515",
      "EMAIL": "mentari.bodyrepair@gmail.com",
      "PIC": "BPK MICHAEL / BPK NANDANG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9115"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "MOBIL ROBPER",
      "ALAMAT": "JL. TERS PASIRKOJA NO. 223 BANDUNG",
      "TELEPHONE": "(022) 6044899",
      "EMAIL": "mobil_robper@yahoo.com",
      "PIC": "IBU YENI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9116"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "NEO MEGAH JAYA",
      "ALAMAT": "JL. LASWI  NO.59 BANDUNG",
      "TELEPHONE": "(022) 7330760",
      "EMAIL": "office@neomegahjaya.co.id",
      "PIC": "BPK.ASEP",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9117"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "NISSAN SOEKARNO HATTA",
      "ALAMAT": "JL. SOEKARNO HATTA NO.382 BANDUNG",
      "TELEPHONE": "(022) 5209777",
      "EMAIL": "wsabodyrepair.skh@nissan.indomobil.co.id",
      "PIC": "BPK.WINARNO / BPK DOFFY / BPK DADAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9118"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "NISSAN VETERAN",
      "ALAMAT": "JL. VETERAN NO. 51-55 BANDUNG",
      "TELEPHONE": "(022) 4212143",
      "EMAIL": "wsabodyrepair.veteran@nissan.indomobil.co.id",
      "PIC": "BPK.ASEP / BPK JIMMY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9119"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "PITSTOP",
      "ALAMAT": "JL.HOLIS 246-248 BANDUNG",
      "TELEPHONE": "(022) 6021490",
      "EMAIL": "infopitstopbdg@gmail.com",
      "PIC": "IBU DESI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9120"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "SAPPHIRE",
      "ALAMAT": "JL. MOH. TOHA NO. 341 BANDUNG",
      "TELEPHONE": "(022) 5205695",
      "EMAIL": "sapphirebodypain@gmail.com",
      "PIC": "BPK.TEGUH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9121"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "STAR AUTO CENTER",
      "ALAMAT": "JL.HOLIS NO.433 BANDUNG",
      "TELEPHONE": "(022) 5414402",
      "EMAIL": "starautobdg@gmail.com",
      "PIC": "BPK,ENDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9122"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "VIRTUE",
      "ALAMAT": "JL. WASTUKENCANA NO.39-41 BANDUNG",
      "TELEPHONE": "(022) 4234524",
      "EMAIL": "virtue_autocare@yahoo.co.id",
      "PIC": "BPK MICHAEL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9123"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "HONDA AHMAD YANI",
      "ALAMAT": "JL. AHMAD YANI NO.352",
      "TELEPHONE": "(022) 7217271",
      "EMAIL": "bodypaint.hay352@gmail.com",
      "PIC": "BPK RAMDANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9124"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO 2000",
      "ALAMAT": "JL. SOEKARNO HATTA NO. 751",
      "TELEPHONE": "(022) 780200",
      "EMAIL": "aradhea.indrawardhani@tso.astra.co.id",
      "PIC": "BPK HARIS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9125"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "CEMERLANG ABADI",
      "ALAMAT": "JL.GATOT SUBROTO NO.74",
      "TELEPHONE": "(022) 7300772",
      "EMAIL": "174cemerlangabadi@gmail.com",
      "PIC": "BPK ANANG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9126"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "AUTO ONE",
      "ALAMAT": "JL.SOEKARNO HATTA NO.785 A",
      "TELEPHONE": "(022) 63725188",
      "EMAIL": "auto.one.bandung@gmail.com",
      "PIC": "BPK WIWIT",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9127"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "SINAR CEMERLANG",
      "ALAMAT": "JL.JUANDA TASIKMALAYA",
      "TELEPHONE": "(0265) 314145",
      "EMAIL": "sinarcemerlangbody@yahoo.com",
      "PIC": "BPK.YANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9128"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "TASIK OTO PRIMA",
      "ALAMAT": "JL.PASEH 32-34 TASIKMALAYA",
      "TELEPHONE": "(0265) 2351333",
      "EMAIL": "noviantidewi3@gmail.com",
      "PIC": "IBU.NOVI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9129"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "PERFECT AUTO ZONE",
      "ALAMAT": "JL.R.E MARTADINATA NO.133",
      "TELEPHONE": "(0265) 314888",
      "EMAIL": "perfect_autozone@yahoo.co.id",
      "PIC": "BPK.AGUNG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9130"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "BUANA AUTO BODY",
      "ALAMAT": "JL.OTTO ISKANDARDINATA",
      "TELEPHONE": "(0260) 416780",
      "EMAIL": "buanaautobodysubang@gmail.com",
      "PIC": "BPK.RIDWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9131"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "TANJUNG CEMERLANG",
      "ALAMAT": "JL.OTISTA NO.398",
      "TELEPHONE": "(0262) 2801495",
      "EMAIL": "tanjung.cemerlang01@gmail.com",
      "PIC": "BPK.EDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9132"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "ASTRA DAIHATSU BIZ",
      "ALAMAT": "JL. SOEKARNO HATTA NO,438 D",
      "TELEPHONE": "0812-2162-6541",
      "EMAIL": "hary.rahadian@dso.astra.co.id",
      "PIC": "BPK. HARY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9133"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "HONDA IBRM CIMAHI",
      "ALAMAT": "JL. RAYA CIMINDI NO.88",
      "TELEPHONE": "(022) 20567888",
      "EMAIL": "estimatorbp.ibrmcmh@hondawestjava.com",
      "PIC": "BPK. IRFAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9134"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "SUZUKI (NUSANTARA JAYA SENTOSA)",
      "ALAMAT": "JL. SOEKARNO HATTA NO. 513",
      "TELEPHONE": "(022) 7300723",
      "EMAIL": "bodyrepair@njs.co.id",
      "PIC": "BPK. SOMANTRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9135"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Otto Cemerlang",
      "ALAMAT": "Jl. K.U Supadio No. 30",
      "TELEPHONE": "T: 022-6075838, F: 022-6031061",
      "EMAIL": "garage@ottocemerlang.com",
      "PIC": "Aan / Ecky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3240"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Seize Body Repair",
      "ALAMAT": "Jl. Peta No. 239",
      "TELEPHONE": "T: 022-6124622, F: 022-6124492",
      "EMAIL": "seize_group@hotmail.com",
      "PIC": "Jun / Malik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3241"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Star Auto Center",
      "ALAMAT": "Jl. Holis No. 433",
      "TELEPHONE": "T: 022-5414402, F: 022-5416962",
      "EMAIL": "starautobdg@gmail.com",
      "PIC": "Alek / Debi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3242"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Virtue Autocare",
      "ALAMAT": "Jl. Wastukencana No. 39-41",
      "TELEPHONE": "T: 022-4234525, 022-4201559, F: 022-4230470",
      "EMAIL": "virtue_autocare@yahoo.co.id",
      "PIC": "Imam / Firmansyah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3243"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Wahana Auto Care",
      "ALAMAT": "Jl. Jend. Sudirman No. 297",
      "TELEPHONE": "T: 022-6033111, 022-6033112, F: 022-6022390",
      "EMAIL": "wac.cso@gmail.com",
      "PIC": "Antonius",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3244"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Glows",
      "ALAMAT": "Jl. Jend. Ahmad Yani No. 612 Cicadas",
      "TELEPHONE": "T: 022-7205206, 022-7205706, F: 022-7205887",
      "EMAIL": "glowsbodyrepair@gmail.com",
      "PIC": "Tatang Suherman / wahid Nurasidin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3245"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Kelvin Body Repair",
      "ALAMAT": "Komp. SPBU Cibolerang (Area SPBU No. 28)",
      "TELEPHONE": "T: 022-5406609",
      "EMAIL": "kelvin_bodyrepair@yahoo.com",
      "PIC": "Supriyono / Ikshan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3246"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Mentari Autohaus",
      "ALAMAT": "Jl. Soekarno Hatta No. 250 B",
      "TELEPHONE": "T: 022-5431515, F: 022-5431518",
      "EMAIL": "mentari@melsa.net.id; mentari.bodyrepair@gmail.com",
      "PIC": "Rike",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3247"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Mobil Robper",
      "ALAMAT": "Jl. Terusan Pasir Koja No. 223",
      "TELEPHONE": "T: 022-6044899, 022-6070064, F: 022-6001878",
      "EMAIL": "m_robper@yahoo.com",
      "PIC": "Rizal / Mila",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3248"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Bonti Car",
      "ALAMAT": "JL. Holis No. 222",
      "TELEPHONE": "F: 022-6034090, T: 022-6034333",
      "EMAIL": "bonticar@yahoo.com",
      "PIC": "Sumarwanto / Tanty/Fitri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3249"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Emerald Body Repair & Auto Detailing",
      "ALAMAT": "Jl. Soekarno Hatta No. 535, Bandung",
      "TELEPHONE": "T : 022 - 7312309",
      "EMAIL": "emeraldautocare@gmail.com",
      "PIC": "Handy / Rudjoyo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3250"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Auto Cemerlang",
      "ALAMAT": "Jl. Soekarno Hatta No. 390 B",
      "TELEPHONE": "T: 022-5221918, F: 022-5221219",
      "EMAIL": "auto_cemerlang@yahoo.co.id",
      "PIC": "Ani / Rustandi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3251"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "One Premium Auto Care",
      "ALAMAT": "Jl. Raya Barat No.654-656, Cimahi Bandung - Jawa Barat",
      "TELEPHONE": "T : 022-20675608",
      "EMAIL": "1premium.autocare@gmail.com\nrumahkaryaotomotif@gmail.com",
      "PIC": "Krisna / Subagio",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3252"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "CV Naripan Body Paint",
      "ALAMAT": "Jl. Sriwijaya No. 3, Bandung",
      "TELEPHONE": "T: 022-5222233, F: 022-5226968",
      "EMAIL": "bodypaint.naripanmotor@gmail.com",
      "PIC": "Andri / Rusli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3253"
    },
    {
      "WILAYAH": "Bandung",
      "NAMA BENGKEL": "Auto 2000 - Cibiru Bandung",
      "ALAMAT": "Jl. Soekarno-Hatta No. 759, Cibiru, Bandung Timur",
      "TELEPHONE": "T : 022 - 7802007",
      "EMAIL": "ricky.irawan@tso.astra.co.id",
      "PIC": "Neneng",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3254"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Autorep",
      "ALAMAT": "Jl. Koppo No. 366A, Bandung",
      "TELEPHONE": "022-6035750",
      "EMAIL": "quickautorep781@gmail.com",
      "PIC": "Taryo",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310777"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Auto Cemerlang",
      "ALAMAT": "Jl. Soekarno Hatta No. 390, Bandung",
      "TELEPHONE": "022-5221918",
      "EMAIL": "auto_cemerlang@yahoo.co.id",
      "PIC": "Mira / Ilham",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310778"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "LCC Motor",
      "ALAMAT": "Jl. Ahmad Yani No. 889, Bandung",
      "TELEPHONE": "022-7206400",
      "EMAIL": "contact@lccmotor.com",
      "PIC": "Meggi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310779"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Megalos Auto Cemerlang",
      "ALAMAT": "Jl. Koppo Cirangrang No. 443, Bandung",
      "TELEPHONE": "022-88885967",
      "EMAIL": "magalos.auto.cemerlang@gmail.com",
      "PIC": "Jose",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310780"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Otto Cemerlang",
      "ALAMAT": "Jl. Supadio No. 31, Bandung",
      "TELEPHONE": 8122042825,
      "EMAIL": "ottocemerlang123@yahoo.com",
      "PIC": "Yudi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310781"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Saphire Body Paint",
      "ALAMAT": "Jl. Moh Toha No. 264 & 341B, Bandung",
      "TELEPHONE": "022-5205696",
      "EMAIL": "sapphirebodypaint@yahoo.com",
      "PIC": "Teguh",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310782"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Prambanan",
      "ALAMAT": "Jl. Kawaluyaan No. 14",
      "TELEPHONE": 87823026634,
      "EMAIL": "prambananautocare@gmail.com",
      "PIC": "Gian",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310783"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "IBRM",
      "ALAMAT": "Jl. Cicendo No. 18, Bandung",
      "TELEPHONE": "022-4240888",
      "EMAIL": "estbp1.ibrm@hondawestjava.com",
      "PIC": "Budi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310784"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "IBRM Cimahi",
      "ALAMAT": "Jl. Raya Cimindi No. 88, Bandung",
      "TELEPHONE": "022-20567888",
      "EMAIL": "estimatorbp.ibrmcmh@hondawestjava.com",
      "PIC": "Adit",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310785"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Honda Ahmad Yani",
      "ALAMAT": "Jl. Jend A. Yani No. 352, Bandung",
      "TELEPHONE": "022-7217271",
      "EMAIL": "bodypaint.hay352@gmail.com",
      "PIC": "Ramdhani",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310786"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Honda Sonic",
      "ALAMAT": "Jl. Soekarno Hatta No. 368, Bandung",
      "TELEPHONE": "022-5230000",
      "EMAIL": "bodyrepair@hondasonic.co.id",
      "PIC": "Apep",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310787"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Honda Pasteur (Panel One)",
      "ALAMAT": "Jl. Soekarno Hatta No. 178, Bandung",
      "TELEPHONE": "022-6032065",
      "EMAIL": "panel1.saluyu@gmail.com",
      "PIC": "Wandi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310788"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Honda Abadi Cibiru",
      "ALAMAT": "Jl. Soekarno Hatta No. 760, Bandung",
      "TELEPHONE": "022-63727989",
      "EMAIL": "hacbodyrepair69@gmail.com",
      "PIC": "Rizal",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310789"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Auto 2000 Cibiru",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310790"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Buaran Motor",
      "ALAMAT": "Jl. Sejarah No. 99, Sukabumi",
      "TELEPHONE": "0266-233343",
      "EMAIL": "bmbodyrepair@gmail.com",
      "PIC": "Anisa",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310791"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "Tokorozawa Motor",
      "ALAMAT": "Jl. Irigasi No. 123, Purwakarta",
      "TELEPHONE": "0264-8623570",
      "EMAIL": "tokorozawa_motor@yahoo.co.id",
      "PIC": "Hendy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310792"
    },
    {
      "WILAYAH": "BANDUNG",
      "NAMA BENGKEL": "CTP",
      "ALAMAT": "Jl. Raya Kosambi, Karawang",
      "TELEPHONE": 81322910494,
      "EMAIL": "ctp.bocyrepair@gmail.com",
      "PIC": "Tulus",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310793"
    },
    {
      "WILAYAH": "Bangka Belitung",
      "NAMA BENGKEL": "CV. Karya Agung",
      "ALAMAT": "Jl. Pahlawan 12 No. 58 B Bukit Merapin - Pangkal Pinang",
      "TELEPHONE": "T : 0717 - 422000",
      "EMAIL": "karyaagung99@yahoo.co.id",
      "PIC": "Ardiarta Mukti",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3255"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "AUTO SERVICE DELTA NIAGA",
      "ALAMAT": "Jl.Ahmad Yani Km.5.5 No.23",
      "TELEPHONE": "(0511) 3251964,7231000",
      "EMAIL": "-",
      "PIC": "-",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6296"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "BERLIAN OTO SERVICE",
      "ALAMAT": "Jl. Ahmad Yani Km 23.7 Jl. Kurnia",
      "TELEPHONE": "(0511)  551141",
      "EMAIL": "-",
      "PIC": "Bapak Andri",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6297"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "SRIWIJAYA INTI MOTOR",
      "ALAMAT": "Jl. Trikora Manunggal Jaya No.113",
      "TELEPHONE": 81351619204,
      "EMAIL": "-",
      "PIC": "Bapak Imam",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6298"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "CV.MITRA ABADI MOTOR",
      "ALAMAT": "JL. A YANI KM 8 RT.09 NO. 22",
      "TELEPHONE": "0511-4281842",
      "EMAIL": "mam_bjm@yahoo.com",
      "PIC": "IBU NIA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9136"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "SUMBER REZEKI MOTOR",
      "ALAMAT": "JL. PRAMUKA RT.30 (SAMPING GG. SRIKAYA) BANJARMASIN",
      "TELEPHONE": "0511-3266741",
      "EMAIL": "sumberejekimtr@yahoo.com",
      "PIC": "BP. RUDY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9137"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "PT. AUTO SERVICE DELTA NIAGA",
      "ALAMAT": "JL. A. YANI KM 5.5 NO. 23",
      "TELEPHONE": "0511-3251964",
      "EMAIL": "klaim.banjarmasin@autoservice.id",
      "PIC": "BP. FAISAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9138"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "PT. MITRA MEGAH PROFITAMAS",
      "ALAMAT": "JL. A. YANI KM 5.5",
      "TELEPHONE": "0511-3253355",
      "EMAIL": "ahyatnoor@mitrasuzuki.com",
      "PIC": "BP. AHYAT",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9139"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "PT. WIRA MEGAH PROFITAMAS",
      "ALAMAT": "JL. A. YANI KM 10 NO.10",
      "TELEPHONE": "0511-4312000",
      "EMAIL": "bodypaint_bjm@wiratoyota.com",
      "PIC": "BP. ADIT",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9140"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "PT. ISTANA MOBIL TRIO MOTOR",
      "ALAMAT": "JL. A. YANI KM 13",
      "TELEPHONE": "0511-4221678",
      "EMAIL": "hondatrio_km13@yahoo.com",
      "PIC": "BP. HARRY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9141"
    },
    {
      "WILAYAH": "BANJARMASIN",
      "NAMA BENGKEL": "CV. LEON JAYA MOTOR",
      "ALAMAT": "JL. PRONA LINGKAR DALAM SELATAN RT. 22",
      "TELEPHONE": "0511-6781560",
      "EMAIL": "leonjayamotor@yahoo.com",
      "PIC": "BP. ARIE PRATAMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9142"
    },
    {
      "WILAYAH": "Banjarmasin",
      "NAMA BENGKEL": "Sriwijaya Inti Motor",
      "ALAMAT": "Jl. Raya Trikora Manunggal Jaya No.113 Banjarbaru",
      "TELEPHONE": "T: 0812-8738204, 0822-51946088",
      "EMAIL": "sriwijaya_im10@yahoo.com; sriwijayamotor@ymail.com",
      "PIC": "Suroso",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3256"
    },
    {
      "WILAYAH": "Banjarmasin",
      "NAMA BENGKEL": "Auto 2000 Banjarmasin",
      "ALAMAT": "Jl. Ahmad Yani KM 19 Kayu Bawang",
      "TELEPHONE": "T: 0511-3202000",
      "EMAIL": "raden.ardy@tso.astra.co.id",
      "PIC": "Firman / Ferry",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3257"
    },
    {
      "WILAYAH": "Banten",
      "NAMA BENGKEL": "Karya Agung Otomotif",
      "ALAMAT": "Jl. Seneja No. 45C Cilegon - Banten",
      "TELEPHONE": "T : 0254-389255, 0811-1249899",
      "EMAIL": "karya.agung.otomotif@gmail.com",
      "PIC": "Aan Wijayanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3258"
    },
    {
      "WILAYAH": "Banten",
      "NAMA BENGKEL": "Serang Indah Motor",
      "ALAMAT": "Jl. Raya Keagungan No.18 Lontar Baru Serang",
      "TELEPHONE": "T: 0254-7915948, 7915333",
      "EMAIL": "kimserang01@gmail.com",
      "PIC": "Asep",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3259"
    },
    {
      "WILAYAH": "Banten",
      "NAMA BENGKEL": "Cilegon Indah Motor",
      "ALAMAT": "Jl. Lingkar Selatan KM. 2 Ds. Harjatani, Kramatwatu, Serang Banten",
      "TELEPHONE": "T: 0254-7819778, 7819768",
      "EMAIL": "kimcilegon99@gmail.com",
      "PIC": "Samuel",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3260"
    },
    {
      "WILAYAH": "BANTEN",
      "NAMA BENGKEL": "Karya Agung Otomotif Serang",
      "ALAMAT": "Jl. Raya Serang jakarta KM. 06 Ciruas",
      "TELEPHONE": "(0254) 280396",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310794"
    },
    {
      "WILAYAH": "BANTEN",
      "NAMA BENGKEL": "Serang Indah Motor",
      "ALAMAT": "Jl. Raya Keagungan No. 18, Serang",
      "TELEPHONE": "(0254) 7915333",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310795"
    },
    {
      "WILAYAH": "BANTEN",
      "NAMA BENGKEL": "Cilegon Indah Motor",
      "ALAMAT": "Jl. Lingkar Selatan KM.2 No.8, Harjatani, Kec. Kramatwatu, Serang, Banten 42616",
      "TELEPHONE": "(0254) 7819768",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310796"
    },
    {
      "WILAYAH": "BANYUMAS",
      "NAMA BENGKEL": "BERDIKARI",
      "ALAMAT": "Jl.Gatot Subroto No.113",
      "TELEPHONE": "(0281) 796500, 796005",
      "EMAIL": "-",
      "PIC": "Bpk.Kukuh Irianto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6299"
    },
    {
      "WILAYAH": "Banyumas",
      "NAMA BENGKEL": "Auto 168",
      "ALAMAT": "Jl. Gatot Subroto Ruko No. 27 Sokaraja",
      "TELEPHONE": "T: 0281-6441688 , F: 0281-6441839",
      "EMAIL": "auto168_cs@yahoo.com",
      "PIC": "Antonius",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3261"
    },
    {
      "WILAYAH": "Banyuwangi",
      "NAMA BENGKEL": "Focuzz",
      "ALAMAT": "Jl. Yos Sudarso No. 56",
      "TELEPHONE": "T: 0333-424710, F: 0333-423203",
      "EMAIL": "focuzzbodyrepair@yahoo.com",
      "PIC": "Febian Arief",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3262"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "GABA TEKNIKATAMA",
      "ALAMAT": "Batam",
      "TELEPHONE": "(0778) 430554",
      "EMAIL": "-",
      "PIC": "Bpk.Dedi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6300"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "BATAM MAKMUR WAHANA MTR",
      "ALAMAT": "Jl.Prambanan No.21",
      "TELEPHONE": "(0778) 423312",
      "EMAIL": "-",
      "PIC": "Bpk.Dedy Harjono",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6301"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "MILENIUM ASEAN MOTOR",
      "ALAMAT": "-",
      "TELEPHONE": "(0778) 433380",
      "EMAIL": "-",
      "PIC": "Bpk.Tri Haryatno",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6302"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "CV.AUTO STAR MANDIRI",
      "ALAMAT": "Jl.Seraya No.88, Lubuk Raja",
      "TELEPHONE": "(0778) 7061234",
      "EMAIL": "-",
      "PIC": "Ibu Yustiani",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6303"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "CV.NEW ERA JAYA",
      "ALAMAT": "Jl.Sriwijaya Komp.Pelita Square",
      "TELEPHONE": "(0778) 702 0780",
      "EMAIL": "-",
      "PIC": "Bpk.Steven Chen / Athong",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6304"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "INTI MITRAGUNA ANUGERAH",
      "ALAMAT": "Kwarta Karsa Perdana Blok D 8 & 9",
      "TELEPHONE": "(0778) 429678",
      "EMAIL": "-",
      "PIC": "Bpk.Akim",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6305"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "BENGKEL INDOMOBIL BATAM",
      "ALAMAT": "JL. YOS SUDARSO, SEI-BALAOI-BATAM",
      "TELEPHONE": "0778-421206",
      "EMAIL": "anto.suryanto27@yahoo.com",
      "PIC": "BP.SURYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9143"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "BENGKEL AGUNG AUTO MALL",
      "ALAMAT": "JL. JENDRAL SDIRMAN, TAMAN BALOI -BATAM",
      "TELEPHONE": "0778-4088000",
      "EMAIL": "Katmo.atbtm@gmail.com",
      "PIC": "IBU WINARNO / BP. MEGGIE / BP. SAMUEL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9144"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "JAYAMAS MAKMUR",
      "ALAMAT": "KOMPLEK RUKO AKU TAHU BLOK H NO.08, SUNGAI PANAS-BATAM CENTRE",
      "TELEPHONE": "0778-466510",
      "EMAIL": "jayamasmakmurbengkel@yahoo.co.id",
      "PIC": "IBU YANTI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9145"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "GALAXY OTOMOTIF",
      "ALAMAT": "JL.LAKSAMANA BINTAN KOMPLEK TANJUNG TRISAKTI BLOK J NO.01, SUNGAI PANAS -BATAM",
      "TELEPHONE": "0778-492035",
      "EMAIL": "galaxyotomotif@yahoo.com",
      "PIC": "IBU ALING / IBU DILA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9146"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "PT. CAHAYA ABADI MOTORINDO",
      "ALAMAT": "KOMPLEK PERGUDANGAN VILLA MAS BLOK A13 NO.1, SUNGAI PANAS- BATAM",
      "TELEPHONE": "Tlp kantor belum ada",
      "EMAIL": "CAMAUTO25@GMAIL.COM",
      "PIC": "BP. ANDRY / IBU NUR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9147"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "PATMOS SERVICE",
      "ALAMAT": "JL. DUA SAUDARA , KEL.TANJUNG AYUN SAKTI KEC.BUKIT LESTARI , TANJUNG PINANG",
      "TELEPHONE": "0771-317168",
      "EMAIL": "ajong.patmos@gmail.com",
      "PIC": "BP. AJONG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9148"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "LARIS JAYA",
      "ALAMAT": "JL. IR. SUTAMI GANG MENTE NO.28, KEL.TANJUNG PINANG TIMUR KEC. BUKIT BESTARI, TANJUNG PINANG",
      "TELEPHONE": "0771-24765",
      "EMAIL": "bengkellarisjayamobil@gmail.com",
      "PIC": "IBU MARINA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9149"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "PT. HONDA BINTAN PRATAMA",
      "ALAMAT": "JALAN GATOTO SUBROTO NO.81-82, KEL.KAMPUNG BULANG , KEC. TANJUNG PINANG TIMUR- KOTA TANJUNG PINANG",
      "TELEPHONE": "0771-318227",
      "EMAIL": "hondabintan@yahoo.co.id",
      "PIC": "IBU VIVI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9150"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Milenium Asean Motor",
      "ALAMAT": "Jl. Yos Sudarso Depan Kodim Seraya, Batu Ampar",
      "TELEPHONE": "T: 0778-433380, F: 0778-433375",
      "EMAIL": "mileniumaseanmotor@yahoo.co.id",
      "PIC": "Maman / Moko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3263"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "CV. Patmos Auto Mobil",
      "ALAMAT": "Jln. Dua Saudara (Blkng Samsat) No. 36 A, Tanjung Pinang",
      "TELEPHONE": "T : 0771 -  317168",
      "EMAIL": "patmos.automobil@gmail.com",
      "PIC": "Diah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3264"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Tata Motor Perkasa",
      "ALAMAT": "Jl. MT Haryono Gg Kapur No. 51 Tanjung Pinang",
      "TELEPHONE": "T : 0771 � 27729 , Hp : 082386489998",
      "EMAIL": "tatamotorperkasa@gmail.com",
      "PIC": "Dida",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3265"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Indo Agung Motor",
      "ALAMAT": "Komplek Prima Sejati Blok A No. 17-18-Batam Center",
      "TELEPHONE": "T : 0778 4803 108/ 0812 7767 3384/   0812 7567 2020",
      "EMAIL": "diniihanifah95@gmail.com",
      "PIC": "Toto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3266"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "PT. Diesel energy Batam",
      "ALAMAT": "Kara Industrial park Blok A No. 11 Kel. Baloi Permai, Kec. Batam Kota",
      "TELEPHONE": "T : 0778 - 465 615",
      "EMAIL": "amgworks16@yahoo.com",
      "PIC": "Rohman / Gembong",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3267"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "PT. Ethan otomotif mandiri",
      "ALAMAT": "Ruko Mega Indah Blok C No. 10, Batam",
      "TELEPHONE": "T : 0778 - 473454",
      "EMAIL": "ethan.otomotif@gmail.com",
      "PIC": "Gunawan / Jamal",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3268"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Torindo Indah Motor (TIM)",
      "ALAMAT": "Jl. Yos Sudarso Komp. Pinang Jaya Ware Housing No. 8 Batu Ampar",
      "TELEPHONE": "T: 0778-7021368, F: 0778-429823",
      "EMAIL": "tim.torrindo@gmail.com",
      "PIC": "Heri Supaat",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3269"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Agung Automall - Batam",
      "ALAMAT": "Jl. Jendral Sudirman, Taman Baloi",
      "TELEPHONE": "T: 0778-4088000, F: 0778-4089585",
      "EMAIL": "tri.yuliani.zedd@gmail.com",
      "PIC": "Edy / Gugun",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3270"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Honda Taman Kota Batam (PT Pionika Automobil)",
      "ALAMAT": "Jl. Teuku Umar Komplek Pionika No. 1-9 Kp Pelita, Lubuk Baja - Batam",
      "TELEPHONE": "T : 0778-488688",
      "EMAIL": "bpmgrhondatamankota@gmail.com",
      "PIC": "Sofia",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3271"
    },
    {
      "WILAYAH": "Batam",
      "NAMA BENGKEL": "Rodamas Makmur Motor (Indomobil)",
      "ALAMAT": "Jl. Yos Sudarso, Sei Baloi",
      "TELEPHONE": "T: 0778-421206, F: 0778-421276",
      "EMAIL": "anto.suryanto27@yahoo.com",
      "PIC": "Dicky / Diana",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3272"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Indo Agung Motor",
      "ALAMAT": "Komp. Ruko Prima Sejati A. 17-18, Batam Center",
      "TELEPHONE": "0778 4803108",
      "EMAIL": "bengkel@indoagungmakmur.com",
      "PIC": "Joni Riyono",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310797"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Gama",
      "ALAMAT": "Komp. Batu Batam Mas B 1-2, Batam",
      "TELEPHONE": "0811 7020538",
      "EMAIL": "bengkelgama1@gmail.com",
      "PIC": "Gek Hai",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310798"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Galaxy Automotif",
      "ALAMAT": "Jl. Laksamana Bintan Komplek Tanjung Trisakti, Blok J No. 01, Sei Panas, Batam",
      "TELEPHONE": "0778 - 492035 / 0811 692 7888",
      "EMAIL": "galaxyotomotif@yahoo.com",
      "PIC": "Berliana/Aling",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310799"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Milenium Asean Motor",
      "ALAMAT": "Jl. Yos Sudarso (Depan Kodim Seraya) Batu Ampar � Batam",
      "TELEPHONE": "0778 - 433 380",
      "EMAIL": "mileniumaseanmotor@yahoo.co.id",
      "PIC": "Tri Hariyanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310800"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "JS Auto Paint",
      "ALAMAT": "Komp. Tanjung Tri Sakti Blok G No.3A Sei.Panas",
      "TELEPHONE": "0778 -07437369",
      "EMAIL": "jsautopaint@outlook.com",
      "PIC": "Mario",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310801"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Toyota Agung Automall",
      "ALAMAT": "Jl. Jendral Sudirman, Taman Baloi, Batam Kota",
      "TELEPHONE": "(0778) 4088000",
      "EMAIL": "fathinhamamah10@gmail.com, katmo.atbtm@gmail.com",
      "PIC": "(0778) 4088000",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310802"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "Honda Bintan Pratama",
      "ALAMAT": "Jl. Gatot Subroto No. 81-82 Tanjung Pinang",
      "TELEPHONE": "0771 - 319541",
      "EMAIL": "hondabintan@yahoo.co.id",
      "PIC": "0771 - 319541",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310803"
    },
    {
      "WILAYAH": "BATAM",
      "NAMA BENGKEL": "PT. Rodamas Makmur Motor (Indomobil Batam)",
      "ALAMAT": "Jl. Yos Sudarso ( Indomobil Batam ) Sei Baloi, Laut Lubuk Baja, Pangkalan Petai, Batam",
      "TELEPHONE": "0778 - 421206, 0778 - 421216,",
      "EMAIL": "hevi.rosmawati@rmm.indomobil.co.id",
      "PIC": "0778 - 421206, 0778 - 421216,",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310804"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "DUTA AUTO BODY",
      "ALAMAT": "Jl. Raya Serang Cibarusah No.68",
      "TELEPHONE": "(021) 89954072",
      "EMAIL": "-",
      "PIC": "Bpk. Samuel",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6306"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "KARYA INDAH MOTOR",
      "ALAMAT": "Jl. Siliwangi No.20",
      "TELEPHONE": "(021) 82422689",
      "EMAIL": "-",
      "PIC": "Bpk. Rengga",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6307"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "AUTO GLAD/PT. INDOMOBIL SOMPO JAPAN",
      "ALAMAT": "JL. RAYA PEKAYON NO. 25, JAKASETIA, BEKASI",
      "TELEPHONE": "021-22100777",
      "EMAIL": "gunawan@autoglad.indomobil.co.id, donny.balle@autoglad.indomobil.co.id",
      "PIC": "BP DONI, BP. GUNAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9151"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "CAHAYA UTAMA (DAPAT MENERIMA TRUCK)",
      "ALAMAT": "JL. RAYA NAROGONG NO.222, BOJONG RAWALUMBU - BEKASI",
      "TELEPHONE": "021-82433568",
      "EMAIL": "cahayautamabekasi@gmail.com",
      "PIC": "BP. SUGIANTO, BU DIANA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9152"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "PRIMA HASTAUTO UTAMA (KHUSUS TRUK/MINIBUS)",
      "ALAMAT": "JL. RAYA KRANGGAN NO. 88 , KEL. JATIRANGGON, KEC. JATISAMPURNA, BEKASI",
      "TELEPHONE": "021-33615358/\n0816-1113961",
      "EMAIL": "hastauto.bodyrepair@yahoo.co.id, hastauto@yahoo.co.id",
      "PIC": "BP. ALEX, BU NOVIKA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9153"
    },
    {
      "WILAYAH": "BEKASI",
      "NAMA BENGKEL": "X2 PAINT",
      "ALAMAT": "JL. PERJUANGAN NO.10, BEKASI UTARA",
      "TELEPHONE": "021-88385106,\n88887073",
      "EMAIL": "x2_paint@yahoo.co.id",
      "PIC": "BP. BENNY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9154"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Cahaya Utama Bekasi",
      "ALAMAT": "Jl. Raya Narogong No. 222 Bojong, Rawalumbu",
      "TELEPHONE": "T: 021-82433568",
      "EMAIL": "cahayautamabekasi@gmail.com",
      "PIC": "Widiyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3273"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Dharma Motor",
      "ALAMAT": "Jl. Raya Jati Bening Estate No. 91, Jati Kramat, Pondok Gede",
      "TELEPHONE": "T: 021-84992507, 021-84992508",
      "EMAIL": "novi.dharma2@gmail.com",
      "PIC": "Dicky / Ricky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3274"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Harmonic Jaya Motor",
      "ALAMAT": "Jl. Bintara Jaya No. 17",
      "TELEPHONE": "T: 021-86900749",
      "EMAIL": "harmonicjayamotor@yahoo.com",
      "PIC": "Nurmatias / Harris",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3275"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Ruby Auto Repair",
      "ALAMAT": "Jl. Raya Kranggan No. 99, Jatisampurna",
      "TELEPHONE": "T: 021-84307823, 021-84307824",
      "EMAIL": "ruby.bodyrepair@gmail.com ;",
      "PIC": "Bpk. Leo / Ibu Sisca",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3276"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "May Car",
      "ALAMAT": "Jl. Masjid Nurul Huda (Gudang Walet), Mekarsari, Tambun Selatan - Bekasi",
      "TELEPHONE": "T: 021-89527040; 021-89527195; 021-89521375 / 087775473242",
      "EMAIL": "indomaycar.supartini@gmail.com ; indomaycar@yahoo.com;",
      "PIC": "Ridwan / Agus",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3277"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Formula Auto Service",
      "ALAMAT": "Jl. Babelan Raya No. 18, Kebalen, Babelan - Bekasi Utara",
      "TELEPHONE": "T: 021-89237000 ; 081318818784 ; 08217241238",
      "EMAIL": "formulaautoservice@gmail.com",
      "PIC": "Husen / Zaenal",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3278"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Tara Motor (CV. Tara Motor)",
      "ALAMAT": "Jl. Bintara jaya No 15 Cibening , Kec Bintara , Bekasi Barat",
      "TELEPHONE": "T : 021-86902588",
      "EMAIL": "ari_taramotor@yahoo.com",
      "PIC": "Edo / Zeni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3279"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "PT. Karya Murni Sentosa (KMS)",
      "ALAMAT": "Jl. Raya Siliwangi Km.7 No. 50 RT 01/03, Bojong Menteng, Bekasi Timur",
      "TELEPHONE": "T : 021-82602625 / 82420757",
      "EMAIL": "karyamurni@gmail.com; karyamurnitruck@gmail.com",
      "PIC": "Rudy / Yuli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3280"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "CV Karya Indah Motor",
      "ALAMAT": "Jl. Raya Siliwangi No. 20, Rawapanjang - Bekasi",
      "TELEPHONE": "T : 021-82422689 / 82400102",
      "EMAIL": "karyaindah_motor@yahoo.com",
      "PIC": "Benny / Frida",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3281"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Raperind Motor",
      "ALAMAT": "Jl. Raya Jatikramat No. 9, Jatiasih, Bekasi\nJl. Raya Kalimalang Komp Billy Moon Blok Q No 2D, Jakarta Timur",
      "TELEPHONE": "T : 02184993977 / 0218643594-95 / 082112880880",
      "EMAIL": "raperindmotor2@gmail.com",
      "PIC": "Bpk. Emanuel wijaya / Ibu Mimi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3282"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "PT Global Mobil Indonesia",
      "ALAMAT": "Jl. Raya Hankam Melati III, Jatiwarna, Pondok Melati, Bekasi",
      "TELEPHONE": "T : 021-85519000 / 081282106799",
      "EMAIL": "globalmobil21@gmail.com",
      "PIC": "Amin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3283"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Auto 2000 Bekasi Timur",
      "ALAMAT": "Jl. Diponegoro No. 38",
      "TELEPHONE": "T: 021-8802000",
      "EMAIL": "dede.haryadi@tso.astra.co.id",
      "PIC": "Clement",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3284"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Astra Daihatsu Narogonng",
      "ALAMAT": "Jl. Raya Siliwangi No.180-181, RT.001/RW.001, Sepanjang Jaya, Kec. Rawalumbu, Kota Bks",
      "TELEPHONE": "T: 087879213765, 081318311776, 081229271669",
      "EMAIL": "joko.waluyo@dso.astra.co.id; muhaimin@dso.astra.co.id",
      "PIC": "Tarijo / Ratna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3285"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Honda Prima Harapan Indah",
      "ALAMAT": "JL. Harapan Indah Boulevard Commercial Park 1. Cav. Cp 1 no.9G Kota Harapan, Indah",
      "TELEPHONE": "021 - 29680505",
      "EMAIL": "ecky.ridho@honda-prima.com",
      "PIC": "Fitri / Febri / Wati",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3286"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Honda Kencana Kranji",
      "ALAMAT": "Jl. Jend. Sudirman KM. 31, Kranji, Bekasi",
      "TELEPHONE": "T: 021-88964139 / 081380363188",
      "EMAIL": "junprianto@hondakencanakranji.co.id",
      "PIC": "Adi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3287"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Honda Triputra Bekasi",
      "ALAMAT": "Jl. Siliwangi Raya Narogong No.30 Sepanjang Jaya, Rawalumbu, Bekasi",
      "TELEPHONE": "T: 021-82734462 / 021-53165858",
      "EMAIL": "alek@honda-bekasi.co.id\nbodyrepair@honda-bekasi.co.id",
      "PIC": "Imam Tauchid",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3288"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Hyundai Bekasi (PT. Hyundai Mobil Indonesia)",
      "ALAMAT": "Jl. H. Wahab Affan , Km. 28 Pondok Ungu , Bekasi",
      "TELEPHONE": "T : 021 - 8854220; F : 021 - 8890617",
      "EMAIL": "body.repair@hyundaimobil.com",
      "PIC": "Herning / Mike",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3289"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Naracitra Otowarna (Cerfified Authorized Daihatsu & Kia)",
      "ALAMAT": "Jl. Jababeka VI Blok. J 4E Cikarang",
      "TELEPHONE": "T: 021-89836262, F: 021-89835983",
      "EMAIL": "claim@otowarna.com",
      "PIC": "Rachmawati / Edward",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3290"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Suzuki Lippo Cikarang (PT. Sejahtera Buana Trada)",
      "ALAMAT": "Jl. Mh Thamrin Kav 200A Lippo Cikarang",
      "TELEPHONE": "T : 021 - 29370081",
      "EMAIL": "tatang.lbg@gmail.com/;wahid.repair@gmail.com",
      "PIC": "Thomson Hasibuan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3291"
    },
    {
      "WILAYAH": "Bekasi",
      "NAMA BENGKEL": "Sun Star Motor (Authorized Mitsubishi)",
      "ALAMAT": "Jl. Ir. H. Juanda No. 35, Margahayu",
      "TELEPHONE": "T: 021-82678100, 0882-14282328",
      "EMAIL": "sabp.bekasi@sunmotor.com",
      "PIC": "Dimas / Deni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3292"
    },
    {
      "WILAYAH": "BENGKULU",
      "NAMA BENGKEL": "BENGKEL RAFFLESIA",
      "ALAMAT": "Jl.Wijaya Kusuma No.25",
      "TELEPHONE": "(0736) 22757",
      "EMAIL": "-",
      "PIC": "Bpk.Sofian (Ayen)",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6308"
    },
    {
      "WILAYAH": "BENGKULU",
      "NAMA BENGKEL": "BENGKEL SUMBER TEKNIK",
      "ALAMAT": "Jl.Sungai Hitam KM.6",
      "TELEPHONE": "(0736) 23500",
      "EMAIL": "-",
      "PIC": "Bpk.Aciek",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6309"
    },
    {
      "WILAYAH": "Bengkulu",
      "NAMA BENGKEL": "International Body",
      "ALAMAT": "Jl. Flamboyan 23 No. 40 SKIP Bengkulu",
      "TELEPHONE": "T: 0736-346540",
      "EMAIL": "internasional.body01@gmail.com",
      "PIC": "Soffie / Ghofur",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3293"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "MAKMUR JAYA MOTOR",
      "ALAMAT": "Jl. Pajajaran No.15 A",
      "TELEPHONE": "(0251) 8310166",
      "EMAIL": "-",
      "PIC": "Bpk.Aeng",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6310"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "OTOPRIMA",
      "ALAMAT": "Jl.Raya Tajur No.32",
      "TELEPHONE": "(0251) 330557, 339003",
      "EMAIL": "-",
      "PIC": "Bpk.Saman",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6311"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "SUZUKI BOGOR/PT. DWIPERKASA MOBILTAMA (KHUSUS SUZUKI)",
      "ALAMAT": "JL. RAYA TAJUR NO. 79, BOGOR",
      "TELEPHONE": "0251-8363376,\n8363167",
      "EMAIL": "tobok.situmeang@dwiperkasa.co.id, agprasojo@yahoo.com",
      "PIC": "BP. TOBOK, BP. AGUNG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9155"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "OTOMITRA",
      "ALAMAT": "JL. RAYA WANGUN NO. 10 CIAWI, BOGOR",
      "TELEPHONE": "0251-8240560;\n0251-8240568",
      "EMAIL": "otomitrawangun@gmail.com",
      "PIC": "BP. AMIN, IBU RINI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9156"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "PLAZA TOYOTA (KHUSUS TOYOTA)",
      "ALAMAT": "JL. KERANGGAN NO.85 RT/RW : 07/04, GUNUNG PUTRI - BOGOR",
      "TELEPHONE": "021-87929407, 021-\n87929409",
      "EMAIL": "ema@plazatoyota.net; crc-kr@plazatoyota.net",
      "PIC": "BP. DIDIK, BP. SALAMUN, IBU EMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9157"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "SUKAWARNA",
      "ALAMAT": "JL. RAYA CIPAKU, BOGOR",
      "TELEPHONE": "0251-315332",
      "EMAIL": "sukawarna_bgr@ymail.com",
      "PIC": "BP. SUTOMO GUNAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9158"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Asco Body Repair",
      "ALAMAT": "Jl. Alternatif Cibubur,  KM. 7 Nagrak ,Cileungsi",
      "TELEPHONE": "T: 021-82499358, 0851-00240767, 0858-92748518",
      "EMAIL": "bodyrepair_asco@yahoo.co.id",
      "PIC": "Yohanes / Siti",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3294"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Cahaya Nusa Citra (CNC)",
      "ALAMAT": "Jl. Raya Ciangsana No. 37 Gunung Puteri",
      "TELEPHONE": "T: 021-84931412, 021-84935469",
      "EMAIL": "cnc_bodyrepair@gmail.com",
      "PIC": "Komar / Clement",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3295"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Sinar Inti Body & Paint",
      "ALAMAT": "Jl. Raya Tajur No. 91 , Bogor",
      "TELEPHONE": "T : 0251 - 8339460;8391272",
      "EMAIL": "sinarinti.tajur@gmail.com",
      "PIC": "Ria / Yanti",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3296"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Golden Car Center",
      "ALAMAT": "Jalan Raya Cibinong � Bogor KM.42",
      "TELEPHONE": "T : 021 - 8759225/6",
      "EMAIL": "gcc.cibinong@gmail.com ; rustandi@gcc.co.id",
      "PIC": "Siti Masitoh",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3297"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Jaya Satria Metalindo",
      "ALAMAT": "Jl. Raya Tapos No. 307 Kel. Ciriung, Kec. Cibinong",
      "TELEPHONE": "T: 021-8753551, 021-8753557",
      "EMAIL": "bengkel.jsmmotor@gmail.com",
      "PIC": "Suherman",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3298"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Makmur Jaya Motor",
      "ALAMAT": "Jl. Pajajaran No.15A",
      "TELEPHONE": "T: 0251-8310166",
      "EMAIL": "mjm_bogor@yahoo.com",
      "PIC": "Arianto / Iponk",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3299"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Millenium Motor2",
      "ALAMAT": "Jl. Nagrak No. 9 Kampung Cikeas ilir, RT. 2/1 Ds Ciangsana, Kec. Gn Putri",
      "TELEPHONE": "T : 021 - 22962787",
      "EMAIL": "b2m_motor@yahoo.co.id",
      "PIC": "Husen / Iwan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3300"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Auto 2000 Bogor Yasmin",
      "ALAMAT": "Jl. Soleh Iskandar No. 9 Yasmin",
      "TELEPHONE": "T: 0251-8362000 ; F : 022 - 7807766",
      "EMAIL": "adiarta.mukti@tso.astra.co.id",
      "PIC": "Ilmi / Ayu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3301"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Mazda Cibubur (PT. Asia Berjaya Mobilindo)",
      "ALAMAT": "JL. Raya Alternatif Cibubur Km. 6 Nagrak, Gn Putri - Bogor",
      "TELEPHONE": "T : 021 - 82487000",
      "EMAIL": "suroso.mazdacibubur@gmail.com",
      "PIC": "Saifullah / Ayu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3302"
    },
    {
      "WILAYAH": "Bogor",
      "NAMA BENGKEL": "Plaza Auto Prima (Plaza Toyota)",
      "ALAMAT": "Jl. Karanggan No. 85 Karanggan, Gunung Putri",
      "TELEPHONE": "T: 021-87929410, F: 021-87929401",
      "EMAIL": "ferry.kr@plazatoyota.net",
      "PIC": "Ricky / Ahmad",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3303"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "MJM (Makmur Jaya Motor) Bogor",
      "ALAMAT": "Jl.Padjajaran No.15 A Bogor",
      "TELEPHONE": "0251-8310166/ 8351759/ 8351684",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310805"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "Golden Car Center",
      "ALAMAT": "Jl. Raya Jakarta Bogor KM 42 Cibinong",
      "TELEPHONE": "021- 87910019",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310806"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "CNC Cibubur",
      "ALAMAT": "Jl. Raya Ciangsana Cikeas, Bogor",
      "TELEPHONE": "021-84931412 / 84935396",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310807"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "PT. Plaza Auto Prima Body and Paint",
      "ALAMAT": "Jl. Karanggan No.85 Gunung Putri-Bogor (exit Tol Jagorawi KM 23)",
      "TELEPHONE": "021-87929410",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310808"
    },
    {
      "WILAYAH": "BOGOR",
      "NAMA BENGKEL": "Plaza Toyota Cibinong",
      "ALAMAT": "Jl. Raya Mayor Oking Jaya Atmaja No.19, Citeureup, Bogor",
      "TELEPHONE": "(021) 8792 9988",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310809"
    },
    {
      "WILAYAH": "Brebes",
      "NAMA BENGKEL": "Nasmoco Brebes",
      "ALAMAT": "Jl. Raya Klampok, Klampok, Wanasari, Kabupaten Brebes,",
      "TELEPHONE": "T : 0283 - 6172300",
      "EMAIL": "nasmocobrebesbody@gmail.com",
      "PIC": "Demesta / Wirjo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3304"
    },
    {
      "WILAYAH": "Cianjur",
      "NAMA BENGKEL": "Cipendawa Motor - Cianjur",
      "ALAMAT": "Jl. Raya Sukabumi No 77 D",
      "TELEPHONE": "T: 0263-263355, 0896-37855620",
      "EMAIL": "cipendawa77@yahoo.com",
      "PIC": "Daniel / Sri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3305"
    },
    {
      "WILAYAH": "CIBINONG",
      "NAMA BENGKEL": "GOLDEN CAR CENTER/PT. OTOMOTIF INTI PERKASA",
      "ALAMAT": "JL. RAYA CIBINONG BOGOR KM.42 PADURENAN, CIBINONG",
      "TELEPHONE": "021-8759225",
      "EMAIL": "ika@gcc.co.id; ani@gcc.co.id; rustandi@gcc.co.id",
      "PIC": "BP. RUSTANDI, IBU IKA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9159"
    },
    {
      "WILAYAH": "CIBINONG",
      "NAMA BENGKEL": "HONDA CIBINONG/PT. GENKI KIRANA INTERNUSA (KHUSUS HONDA)",
      "ALAMAT": "JL. RAYA MAYOR OKING NO. 30, CIBINONG - BOGOR",
      "TELEPHONE": "021-8763888",
      "EMAIL": "bp.hondainternusa@gmail.com, robby19nugroho@gmail.com, yudiprasetyainternusa@gmail.com",
      "PIC": "BP. ROBBY, BP YUDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9160"
    },
    {
      "WILAYAH": "CIBUBUR",
      "NAMA BENGKEL": "MAZDA CIBUBUR/PT. ASIA BERJAYA MOBILINDO (KHUSUS MAZDA)",
      "ALAMAT": "JL. RAYA TRANSYOGI (ALTERNATIF CIBUBUR) KM. 6, KP NANGGEWER RT 02 RW 07, GUNUNG PUTRI, BOGOR 16967",
      "TELEPHONE": "021-82487000",
      "EMAIL": "susilowati.mazdacibubur@gmail.com; faqihbudi.mazdacibubur@gmail.com; hobp.mazdacibubur@gmail.com",
      "PIC": "BP. IRFAN,  IBU SUSI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9161"
    },
    {
      "WILAYAH": "CIBUBUR",
      "NAMA BENGKEL": "NISSAN CIBUBUR",
      "ALAMAT": "JL. ALTENATIF CIBUBUR KM.7, CIBUBUR",
      "TELEPHONE": "021-82494850",
      "EMAIL": "wsabodyrepair.cibubur@nissan.indomobil.co.id, shandy.widyatama@nissan.indomobil.co.id",
      "PIC": "BP. AGUS MUSTOFA, BP. SHANDY, IBU LINDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9162"
    },
    {
      "WILAYAH": "CIBUBUR",
      "NAMA BENGKEL": "PADI ABADI KREATIFIKA",
      "ALAMAT": "JL. LETDA NATSIR, BOJONGKULUR, CIANGSANA, (DPN VILLA NUSA INDAH) CIBUBUR",
      "TELEPHONE": "021-82415582, 021-\n82415780",
      "EMAIL": "info.padiautocare@gmail.com",
      "PIC": "BP. SAID, BP IMRAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9163"
    },
    {
      "WILAYAH": "CIBUBUR",
      "NAMA BENGKEL": "TIDAR MOTOR",
      "ALAMAT": "JL. TRANS YOGI NAGRAK CIKEAS NO.3, GUNUNG PUTRI, BOGOR",
      "TELEPHONE": "021-82490188",
      "EMAIL": "tidarmotor@yahoo.com",
      "PIC": "BP.  SUDJARI, BP. RUDI, BP. ADE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9164"
    },
    {
      "WILAYAH": "CIKARANG",
      "NAMA BENGKEL": "SUZUKI CIKARANG/PT. SEJAHTERA BUANA TRADA (KHUSUS SUZUKI)",
      "ALAMAT": "JL. M.H THAMRIN KAVLING 200A, CIBATU, LIPPO CIKARANG, BEKASI, JABAR 17530",
      "TELEPHONE": "021-2937-0080/81",
      "EMAIL": "suprayetno7@gmail.com",
      "PIC": "BP. SUPRAYETNO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9165"
    },
    {
      "WILAYAH": "Cikarang",
      "NAMA BENGKEL": "Duta Auto Body",
      "ALAMAT": "Jl. Raya Serang Cibarusah No. 68, Pasirandu Cikarang",
      "TELEPHONE": "T : 021-89954072; 021-29370209; 081311355125",
      "EMAIL": "dutasht@yahoo.com",
      "PIC": "Hersu / Hasim",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3306"
    },
    {
      "WILAYAH": "Cikarang",
      "NAMA BENGKEL": "Naracitra Otowarna (Cerfified Authorized Daihatsu & Kia)",
      "ALAMAT": "Jl. Jababeka VI Blok. J 4E Cikarang",
      "TELEPHONE": "T : 021-89836262 / F: 021-89835983",
      "EMAIL": "claim@otowarna.com",
      "PIC": "Irvan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3307"
    },
    {
      "WILAYAH": "CIKARANG",
      "NAMA BENGKEL": "PT. Prospect Motor",
      "ALAMAT": "Jalan MH. Thamrin No. 152 Lippo Cikarang, Cikarang Selatan",
      "TELEPHONE": "(021) 8974142",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310810"
    },
    {
      "WILAYAH": "Cilacap",
      "NAMA BENGKEL": "Nasmoco Cilacap",
      "ALAMAT": "Jalan MT Haryono No.81, Cilacap Tengah, Donan, Cilacap Tengah, Kabupaten Cilacap",
      "TELEPHONE": "T : 0282 -  533200",
      "EMAIL": "bpcilacap@yahoo.co.id",
      "PIC": "Jefri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3308"
    },
    {
      "WILAYAH": "CILEGON",
      "NAMA BENGKEL": "ANUGRAH MOTOR",
      "ALAMAT": "JL. RAYA TEMU PUTIH NO.55 KAV BLOK C, CILEGON - BANTEN",
      "TELEPHONE": "0254-391941",
      "EMAIL": "anugrah_motor@yahoo.com",
      "PIC": "BP. H. RIDWAN SYAHYADI, BU RIRIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9166"
    },
    {
      "WILAYAH": "CILEGON",
      "NAMA BENGKEL": "KARYA AGUNG OTOMOTIF (DAPAT MENERIMA TRUCK)",
      "ALAMAT": "JL. RAYA SENEJA NO.15, CILEGON - BANTEN",
      "TELEPHONE": "0254-389255",
      "EMAIL": "karya.agung.otomotif@gmail.com",
      "PIC": "BP. WAHYU NUGROHO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9167"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT. SELECTA GARAGE JAYA",
      "ALAMAT": "Jl Kalijaga No. 108",
      "TELEPHONE": "(0231) 203313",
      "EMAIL": "-",
      "PIC": "Bpk. Joy/Ibu Desi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6312"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT CINTA DAMAI PUTRA BAHAGIA",
      "ALAMAT": "JL. KALIJAGA NO.117, CIREBON",
      "TELEPHONE": "62231 230506",
      "EMAIL": "bodyrepaircdpb@yahoo.com",
      "PIC": "BP. DODI / BP.  NANA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9168"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT LAMBANG PUTRA PERKASA MOTOR",
      "ALAMAT": "JL. KALIJAGA NO.29, CIREBON",
      "TELEPHONE": "62231 204157",
      "EMAIL": "honda.bplppm@gmail.com",
      "PIC": "BP. RUDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9169"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT TEJA BERLIAN",
      "ALAMAT": "JL. KALIJAGA NO.144, CIREBON",
      "TELEPHONE": "62231 201651",
      "EMAIL": "mitsubishi@tejaberlian.com",
      "PIC": "BP. RANGGA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9170"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT SELECTA GRAGE JAYA",
      "ALAMAT": "JL. KALIJAGA NO.108, CIRBEON",
      "TELEPHONE": "62231 203313",
      "EMAIL": "joy_selecta@yahoo.co.id",
      "PIC": "BP. YUDI / BP. JOY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9171"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "CV AUTOKOOL PRIMA",
      "ALAMAT": "JL. RAYA SUNAN GUNUNG JATI NO.91 (PASINDANGAN), CIREBON",
      "TELEPHONE": "62231 202948",
      "EMAIL": "autokoolbr@yahoo.com",
      "PIC": "BP. DODY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9172"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT ASTRA INTERNATIONAL, TBK - DAIHATSU",
      "ALAMAT": "JL. TUPAREV NO.76, CIREBON",
      "TELEPHONE": "62231 206691",
      "EMAIL": "dadangdaihatzu3828@gmail.com",
      "PIC": "BP. DADANG / BP. BRANDO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9173"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT WAHANA REJEKI MOBILINDO CIREBON",
      "ALAMAT": "JL. BRIGJEND DARSONO NO.05, CIREBON",
      "TELEPHONE": "62231 488881",
      "EMAIL": "muhammad.rifki@nissan.indomobil.co.id",
      "PIC": "BP. RIFQY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9174"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT ASTRA INTERNATIONAL, TBK (AUTO2000)",
      "ALAMAT": "JL. BRIGJEND DARSONO NO.14, CIREBON",
      "TELEPHONE": "62231 232000",
      "EMAIL": "johana@tso.astra.co.id",
      "PIC": "BP JOHANA / BP. DADANG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9175"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT HONDA MULYA PUTRA",
      "ALAMAT": "JL. BRIGJEND DARSONO NO.18, CIREBON",
      "TELEPHONE": "62231 221148",
      "EMAIL": "sa_hmp@yahoo.com",
      "PIC": "BP. ELON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9176"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "CV AUTO TRYAS",
      "ALAMAT": "JL. KATIYASA NO.9A, CIREBON",
      "TELEPHONE": "62231 484444",
      "EMAIL": "autotryas.jimmi@gmail.com",
      "PIC": "BP. BAYU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9177"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "AUTOREP",
      "ALAMAT": "JL. DUKUH DUWUR, NO.38, CIREBON",
      "TELEPHONE": "62231 8305720",
      "EMAIL": "autorep.crb@gmail.com",
      "PIC": "BP. ANGGA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9178"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "PT. HONDA EIYU",
      "ALAMAT": "JL. PASARBARU V NO. 5 INDRAMAYU",
      "TELEPHONE": "0234 277700",
      "EMAIL": "bodyrepaireds@yahoo.com",
      "PIC": "BP. DWI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9179"
    },
    {
      "WILAYAH": "Cirebon",
      "NAMA BENGKEL": "Auto Rep",
      "ALAMAT": "Jl.Dukuh Duwur No. 38 Pegambiran",
      "TELEPHONE": "T: 0231-8305720, 0231-8305720, 0897-4533323",
      "EMAIL": "autorep.crb@gmail.com",
      "PIC": "Agung / Untung",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3309"
    },
    {
      "WILAYAH": "Cirebon",
      "NAMA BENGKEL": "Nissan Cirebon (PT. Wahana Rejeki Mobilindo Cirebon)",
      "ALAMAT": "Jl. Brigjen Darsono RT. 05 , RW. 04, Desa Kedawung, Kec. Kedawung - Cirebon",
      "TELEPHONE": "T : 0231 - 4888 81",
      "EMAIL": "bodyrepairnissankedawung@gmail.com",
      "PIC": "Jamaluddin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3310"
    },
    {
      "WILAYAH": "Cirebon",
      "NAMA BENGKEL": "Auto 2000 Cirebon",
      "ALAMAT": "Jl. Brigjend Darsono No. 14, Cirebon",
      "TELEPHONE": "T : 0231 - 232000 ; F : 0231 - 204555",
      "EMAIL": "dida.kridayanto@tso.astra.co.id",
      "PIC": "Eko Hadi S",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3311"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Selecta Grage Jaya Cirebon",
      "ALAMAT": "Jl. Kalijaga No.108, Cirebon",
      "TELEPHONE": "0231-203313",
      "EMAIL": "",
      "PIC": "Yudi Hermawan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310811"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "AutoRep Cirebon",
      "ALAMAT": "Jl. Dukuh Duwur No.38 Pegambiran, Cirebon",
      "TELEPHONE": "0231-8305720",
      "EMAIL": "",
      "PIC": "Indah Triana",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310812"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Auto 2000 Cirebon",
      "ALAMAT": "Jl. Brigjend Dharsono No.14, Cirebon",
      "TELEPHONE": "0231-232000",
      "EMAIL": "basuki.rahmanto@tso.astra.co.id",
      "PIC": "Desyanto Dwi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310813"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Astra Daihatsu Cirebon",
      "ALAMAT": "Jl. Tuparev No. 76, Cirebon",
      "TELEPHONE": "0231-206691",
      "EMAIL": "hermawan.yuliantoro@dso.astra.co.id",
      "PIC": "Heramawan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310814"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Honda LPPM Cirebon",
      "ALAMAT": "Jl. Kalijaga No.29, Cirebon",
      "TELEPHONE": "0231-204157",
      "EMAIL": "honda.bplppm@gmail.com",
      "PIC": "Rudi Kurniawan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310815"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Honda Mulya Putra Cirebon",
      "ALAMAT": "Jl. Brigjend Dharsono No.18, Cirebon",
      "TELEPHONE": "0231-221148",
      "EMAIL": "sa_hmp@yahoo.com",
      "PIC": "Elon Jaelani",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310816"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Mitsubishi Teja Berlian Cirebon",
      "ALAMAT": "Jl. Kalijaga No. 144, Cirebon",
      "TELEPHONE": "0231-201651",
      "EMAIL": "anton.suhardi@tejaberlian.co.id",
      "PIC": "Anton Suhardi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310817"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Suzuki Cinta Damai Putra Bahagia",
      "ALAMAT": "Jl. Kalijaga No. 117, Cirebon",
      "TELEPHONE": "0231-230506",
      "EMAIL": "bodyrepaircdpb@yahoo.com",
      "PIC": "Dodi Tanoto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310818"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Gedong Jembar",
      "ALAMAT": "Jl. Brawijaya No. 22 Muarareja, Tegal",
      "TELEPHONE": "0283-350634",
      "EMAIL": "gedongbengkel@ymail.com",
      "PIC": "Rudi Sujanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310819"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "New Ragil Pekalongan",
      "ALAMAT": "Jl. Ahmad Yani No. 16 Wiradesa, Pekalongan",
      "TELEPHONE": "0285-4417285",
      "EMAIL": "ragilmotor@gmail.com",
      "PIC": "Denny Burhani",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310820"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Honda Eiyu Indramayu",
      "ALAMAT": "Jl. Pasarbaru V No. 5, Indramayu",
      "TELEPHONE": "0234-277666",
      "EMAIL": "bodyrepaireds@yahoo.com",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310821"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Honda Pekalongan Motor",
      "ALAMAT": "Jl. Dr. Sutomo No. 168, Pekalongan",
      "TELEPHONE": "0285-433800",
      "EMAIL": "ida@merdeka-group.com",
      "PIC": "Mahmudah",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310822"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Honda Tegal Raya",
      "ALAMAT": "Jl. Martoloyo� No. 111, Tegal",
      "TELEPHONE": "0283-320111",
      "EMAIL": "wahyu@merdeka-group.com",
      "PIC": "Wahyu Hidayat",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310823"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Toyota Nasmoco Brebes",
      "ALAMAT": "Jl. Raya klampok, Wanassari Kab Brebes",
      "TELEPHONE": "0283-6172300",
      "EMAIL": "nasmocobrebesbody@gmail.com",
      "PIC": "Farid",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310824"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Toyota Nasmoco Pekalongan",
      "ALAMAT": "Jl. Raya Kalibanger Km.3 Pekalongan",
      "TELEPHONE": "0285-420020",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310825"
    },
    {
      "WILAYAH": "CIREBON",
      "NAMA BENGKEL": "Astra Daihatsu Pekalongan",
      "ALAMAT": "Jl. Dr. Sutomo No. 12-14 Pekalongan",
      "TELEPHONE": "0285-431222",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310826"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "MUJI MOTOR",
      "ALAMAT": "Jl.Trengguli Gg.XXII/5",
      "TELEPHONE": "(0361) 464646",
      "EMAIL": "-",
      "PIC": "Bpk.Muji",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6313"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "PARAMITHA AUTOGRAHA",
      "ALAMAT": "Jl. Ahmad Yani No. 999",
      "TELEPHONE": "(0361)423188",
      "EMAIL": "-",
      "PIC": "-",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6314"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "PANJI DJAYA/PANJI WIN DJAYA",
      "ALAMAT": "Jl. Pendidikan IV no.26 Denpasar",
      "TELEPHONE": "0361-727986",
      "EMAIL": "panjidjaya@ymail.com",
      "PIC": "Esti / 081337561071",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310827"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "panjiwindjaya@yahoo.com",
      "PIC": "Desi / 081239939964",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310828"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "PANJI LARAS",
      "ALAMAT": "Jl. Kepura Sakenan Suwung Denpasar",
      "TELEPHONE": "0361-720965",
      "EMAIL": "panjilarasmotor@yahoo.com",
      "PIC": "Ayu / 082144799121",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310829"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "PAG ( Paramitha Auto Graha ) - Ayani, Jimbaran, Gianyar",
      "ALAMAT": "Jl. A Yani No.229 -  Denpasar",
      "TELEPHONE": "0361-423188",
      "EMAIL": "anistia pramita (tyapag@yahoo.com)",
      "PIC": "Ayu PAG ayani / 081934335529",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310830"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "bengkelpag2_n2@yahoo.com",
      "PIC": "Tari PAG gianyar / 085792039680",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310831"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "Santi PAG nusa 2 / 0823-3968-9612",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310832"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "CS AUTO",
      "ALAMAT": "Jl. Gunung Andakasa Belakang Indosat, Denpasar",
      "TELEPHONE": "0361-8066771",
      "EMAIL": "csautobodyrepair@gmail.com",
      "PIC": "Ira / 082343132792",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310833"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "Arik / 081246488344",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310834"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "Catur Yoga Mas",
      "ALAMAT": "Jl. subur NO 26X monang-maning Denpasar",
      "TELEPHONE": "0361-483353/4716285",
      "EMAIL": "caturyogamas2_bp@yahoo.com",
      "PIC": "Ananda Dewi / 08113856207",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310835"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "Muji Motor",
      "ALAMAT": "Jl. Trengguli Gg. XXII No.5, Penatih, Denpasar",
      "TELEPHONE": "0361-464646",
      "EMAIL": "bengkel.mujimotor@gmail.com",
      "PIC": "Ayu Ariati / 085739075054",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310836"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "Agung Toyota Tabanan",
      "ALAMAT": "Jl. Ahmad Yani No 4a Abiantuwung Kediri Tabanan",
      "TELEPHONE": "0361-8942020",
      "EMAIL": "Andicka.nengah@Gmail.com",
      "PIC": "I Nengah Andika",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310837"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "Top Motor - Suzuki",
      "ALAMAT": "Jl. Raya batu Bulan No. 18, Denpasar",
      "TELEPHONE": "0361-296989",
      "EMAIL": "topmotor18@gmail.com",
      "PIC": "Ita / 0812-3844-3342",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310838"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "ASTRA INTERNATIONAL - Daihatsu Authorized",
      "ALAMAT": "Jl. Cokroaminoto No. 52, Denpasar",
      "TELEPHONE": "0361-429000",
      "EMAIL": "winarno@dso.astra.co.id",
      "PIC": "Pak Winarno / 0812-3971-8796",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310839"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "ASTRA INTERNATIONAL - Daihatsu Authorized",
      "ALAMAT": "Jl. Cokroaminoto No. 52, Denpasar",
      "TELEPHONE": "0361-429000",
      "EMAIL": "mghozi.nanangazilan@dso.astra.co.id",
      "PIC": "Pak Ghozi / 0813-8082-5693",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310840"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "SEJAHTERA INDOBALI TRADA - Suzuki Authorized",
      "ALAMAT": "JL. By Pass Ngurah Rai No.104 Sanur, Denpasar",
      "TELEPHONE": "0361-466030",
      "EMAIL": "suzuki.bodyrepair@gmail.com",
      "PIC": "Pak I Ketut Mertayoga / 0815-5761-412",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310841"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "SEJAHTERA INDOBALI TRADA - Suzuki Authorized",
      "ALAMAT": "JL. By Pass Ngurah Rai No.104 Sanur, Denpasar",
      "TELEPHONE": "0361-466030",
      "EMAIL": "nengahsit@gmail.com",
      "PIC": "Pak Eko / 0878-5394-5499",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310842"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "SEJAHTERA INDOBALI TRADA - Suzuki Authorized",
      "ALAMAT": "JL. By Pass Ngurah Rai No.104 Sanur, Denpasar",
      "TELEPHONE": "0361-466030",
      "EMAIL": "ketutmertayoga@gmail.com",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310843"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "HONDA KUTA RAYA",
      "ALAMAT": "Jl. Imam Bonjol No.452-456, Denpasar",
      "TELEPHONE": "0361-488877",
      "EMAIL": "bodyrepair@hondakutaraya.com",
      "PIC": "Pak Gede Wira / 0821-4400-7977",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310844"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "HONDA KUTA RAYA",
      "ALAMAT": "Jl. Imam Bonjol No.452-456, Denpasar",
      "TELEPHONE": "0361-488877",
      "EMAIL": "",
      "PIC": "Julita Ari / 0819-3902-9982",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310845"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "HONDA DEWATA MOTOR",
      "ALAMAT": "Jl. Imam Bonjol No.104 Denpasar",
      "TELEPHONE": "0361-490499",
      "EMAIL": "hondadewatamotor@yahoo.com",
      "PIC": "Karisma / 0831-1723-4948",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310846"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "HONDA DEWATA MOTOR",
      "ALAMAT": "Jl. Imam Bonjol No.104 Denpasar",
      "TELEPHONE": "0361-490499",
      "EMAIL": "",
      "PIC": "Vera / 0878-6231-2241",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310847"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "Agung Toyota Tabanan",
      "ALAMAT": "Jl Ahmad Yani No 4a Abiantuwung Kediri Tabanan",
      "TELEPHONE": "0361-8942020",
      "EMAIL": "Andicka.nengah@Gmail.com",
      "PIC": "I Nengah Andika",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310848"
    },
    {
      "WILAYAH": "DENPASAR",
      "NAMA BENGKEL": "PT BUMEN REDJA ABADI, Denpasar - Bali",
      "ALAMAT": "JL IMAM BONJOL NO 375R",
      "TELEPHONE": "0361-483002",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310849"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "PANJI DJAYA",
      "ALAMAT": "JL. PENDIDIKAN IV NO 26 DENPASAR",
      "TELEPHONE": "0361-727986",
      "EMAIL": "panjidjaya@ymail.com",
      "PIC": "BP PANDE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9180"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "PARWATHA",
      "ALAMAT": "JL. AHMAD YANI 168X/208 DENPASAR",
      "TELEPHONE": "0361-434709",
      "EMAIL": "ekaparwata2013@yahoo.com",
      "PIC": "BP KOMANG EKA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9181"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "SEJAHTERA INDOBALI TRADA",
      "ALAMAT": "JL. BYPASS NGURAH RAI 104, JL BYPASS NGURAH RAI NO 35",
      "TELEPHONE": "0361-466030, 0361-720210",
      "EMAIL": "gusdhe.indobali@gmail.com, erik.susanto11@gmail.com, ekosuzukibp@gmail.com, br_indobalibenoa@yahoo.com",
      "PIC": "BP GUSDE, BP ERIK, BP EKO, BU AIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9182"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "PERMATA AUTO GALLERY",
      "ALAMAT": "JL. AHMAD YANI 168X/208 DENPASAR",
      "TELEPHONE": "0361-423188",
      "EMAIL": "cs.bengkelpag@yahoo.com",
      "PIC": "IBU ILUH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9183"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "PARAMITHA AUTO GRAHA GIANYAR",
      "ALAMAT": "JL. BANTENG NO 8 BYPASS DHARMA GIRI",
      "TELEPHONE": 85338427070,
      "EMAIL": "taripag2@gmail.com",
      "PIC": "IBU TARI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9184"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "PARAMITHA AUTO GRAHA MUMBUL",
      "ALAMAT": "PERUMAHAN TAMAN JIMBARAN, GG SERUNI NO 1 MUMBUL",
      "TELEPHONE": 81236118898,
      "EMAIL": "bengkelpag1_n2@yahoo.com",
      "PIC": "IBU FITA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9185"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "MUJI MOTOR",
      "ALAMAT": "JL TRENGGULI GG. XXII / 5",
      "TELEPHONE": "0361-464646",
      "EMAIL": "mujimotorabadi1@gmail.com",
      "PIC": "IBU SRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9186"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "ASTRIDO",
      "ALAMAT": "JL MAHENDRADATTA NO 229",
      "TELEPHONE": "0361-484948",
      "EMAIL": "astrido@indosat.net.id",
      "PIC": "BP PUTU, BP SIRAT, IBU NINGSIH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9187"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "ASTRA INTERNATIONAL SANUR (AUTO 2000)",
      "ALAMAT": "JL. BYPASS NGURAH RAI NO. 395, SANUR",
      "TELEPHONE": "0361-282000",
      "EMAIL": "ide.sandika@tso.astra.co.id, i.komang.abdiyasa@tso.astra.co.id, eka.widiarta@tso.astra.co.id, igede.pandeeka@tso.astra.co.id",
      "PIC": "BP IDE, BP ABDI, BP EKA, BP PANDE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9188"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "AGUNG TOYOTA",
      "ALAMAT": "JL. A YANI 4A ABIANTUWUNG",
      "TELEPHONE": "0361-8942020",
      "EMAIL": "agusyadnya92@gmail.com",
      "PIC": "BP AGUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9189"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "TOP MOTOR",
      "ALAMAT": "JL RAYA BATUBULAN NO 18",
      "TELEPHONE": 85969039001,
      "EMAIL": "topmotor18@gmail.com",
      "PIC": "IBU ITA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9190"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "BUMEN REDJA ABADI",
      "ALAMAT": "JL IMAM BONJOL 375 R",
      "TELEPHONE": "0361-483001",
      "EMAIL": "wayan.puspiana@bra.nag.co.id",
      "PIC": "IBU YANIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9191"
    },
    {
      "WILAYAH": "DENPASSAR",
      "NAMA BENGKEL": "DAIHATSU KHARISMA SENTOSA",
      "ALAMAT": "JL. TEUKU UMAR BARAT NO 99",
      "TELEPHONE": "0361-481999",
      "EMAIL": "susilo_ksd@yahoo.co.id",
      "PIC": "BP SUSILO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9192"
    },
    {
      "WILAYAH": "DEPOK",
      "NAMA BENGKEL": "SURYA JAYA MOTOR",
      "ALAMAT": "Jl.Abdul Gani No.6 Rt.005 / Rw.03",
      "TELEPHONE": "(021) 8763970, 8793971",
      "EMAIL": "-",
      "PIC": "Bpk. Ferri",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6315"
    },
    {
      "WILAYAH": "DEPOK",
      "NAMA BENGKEL": "HONDA SAWANGAN/PT. AMBARA KARYA PRADANA",
      "ALAMAT": "JL. CINANGKA RAYA NO.9 SERUA, BOJONGSARI - DEPOK",
      "TELEPHONE": "021-30498889",
      "EMAIL": "adm.bp@hondapradana.com, budi.arisafdi@gmail.com",
      "PIC": "BP. BUDI, IBU INTAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9193"
    },
    {
      "WILAYAH": "DEPOK",
      "NAMA BENGKEL": "NISSAN SUKAMAJU",
      "ALAMAT": "JL. RAYA BOGOR RT 005/004 KEL. SUKAMAJU, KEC. CILODONG, DEPOK",
      "TELEPHONE": "021-29629400",
      "EMAIL": "wsabodyrepair.sukamaju@nissan.indomobil.co.id; heri.supaat@nissan.indomobil.co.id",
      "PIC": "BP. RAMON, BP. HERI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9194"
    },
    {
      "WILAYAH": "DEPOK",
      "NAMA BENGKEL": "PAINT AUTO REPAIR",
      "ALAMAT": "JL. RAYA KSU SERAB NO.1 RT/RW : 06/05, TIRTAJAYA - DEPOK",
      "TELEPHONE": "021-77835421",
      "EMAIL": "paintautorepair@yahoo.com",
      "PIC": "BP. GUNAWAN, BP RAMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9195"
    },
    {
      "WILAYAH": "DEPOK",
      "NAMA BENGKEL": "SURYA JAYA MOTOR",
      "ALAMAT": "JL. ABDUL GANI NO.6 RT/RW : 05/03, CILODONG - DEPOK",
      "TELEPHONE": "021-8763970 - 71",
      "EMAIL": "surya_motor2@yahoo.com",
      "PIC": "BP. DAUD T, BP. INDRA, BP. FERRY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9196"
    },
    {
      "WILAYAH": "Depok",
      "NAMA BENGKEL": "Proton Edar Depok  (PT. Duta Bima Nusantara)",
      "ALAMAT": "Jl. Kartini Raya No. 47 Pancoran Mas",
      "TELEPHONE": "T : 021-2909 7510 / 11",
      "EMAIL": "protondepok@gmail.com",
      "PIC": "Sugeng Pujadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3312"
    },
    {
      "WILAYAH": "Depok",
      "NAMA BENGKEL": "Cars Tarantula",
      "ALAMAT": "Jl. Raya Lenteng Agung Barat No. 55, Jagakarta",
      "TELEPHONE": "T : 021 - 7888 7999",
      "EMAIL": "tarantulamp45@gmail.com",
      "PIC": "Azis Subarna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3313"
    },
    {
      "WILAYAH": "Depok",
      "NAMA BENGKEL": "Paint Auto Repair",
      "ALAMAT": "Jl. Raya KSU No. 1 Serab",
      "TELEPHONE": "T: 021-77835421;29441308;081385004227",
      "EMAIL": "paintautorepair@yahoo.com",
      "PIC": "Iwan Syarief",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3314"
    },
    {
      "WILAYAH": "Depok",
      "NAMA BENGKEL": "Nissan Sukamaju (PT. Wahana Wirawan)",
      "ALAMAT": "Jl. Raya Bogor Rt. 005/004, Kel. Sukamaju Kec. Cilodong, Depok",
      "TELEPHONE": "T : 021 - 2962 9400",
      "EMAIL": "heri.supaat@nissan.indomobil.co.id",
      "PIC": "Niko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3315"
    },
    {
      "WILAYAH": "Garut",
      "NAMA BENGKEL": "CV. Tanjung Cemeralang",
      "ALAMAT": "Jl. Otista No. 398, Warung Tanjung, Tarogong Kaler Kab. Garut",
      "TELEPHONE": "T : 0262 - 2801495",
      "EMAIL": "tanjung.cemerlang01@gmail.com",
      "PIC": "Endang",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3316"
    },
    {
      "WILAYAH": "Gorontalo",
      "NAMA BENGKEL": "Kawanua Sejati",
      "ALAMAT": "Jl. Taki Niode No. 61, Ipilo Kota Timur - Gorontalo",
      "TELEPHONE": "T: 0811-4316699 , 0435 - 831202 / 831202",
      "EMAIL": "bintangautocat@yahoo.co.id",
      "PIC": "Iwan / Kustri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3317"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "METRO MOTOR",
      "ALAMAT": "Jl. Arteri Kedoya Raya No. 27",
      "TELEPHONE": "(021) 5821826-28",
      "EMAIL": "-",
      "PIC": "Bpk.Asep/ Ibu Amini",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6316"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "LIDO JAYA PERKASA",
      "ALAMAT": "Jl.H.Saaba Raya No.2",
      "TELEPHONE": "(021) 5890 3858-59",
      "EMAIL": "-",
      "PIC": "Bpk.Jeni/ Bpk.Kris",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6317"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "CHUNG SERVICE",
      "ALAMAT": "Jl.Duri TSS No.54-56",
      "TELEPHONE": "(021) 631 3255, 631 0789",
      "EMAIL": "-",
      "PIC": "Bpk.Suhandi/ Ibu Fitri",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6318"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "NEW BERINGIN MOTOR",
      "ALAMAT": "Jl. Arjuna Selatan No.10",
      "TELEPHONE": "(021) 53651616",
      "EMAIL": "-",
      "PIC": "Bpk. Uci",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6319"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "PADIMAS 1",
      "ALAMAT": "Jl. Hadiah No. 18 Daan Mogot",
      "TELEPHONE": "(021) 5640334",
      "EMAIL": "-",
      "PIC": "Ibu Widya",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6320"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "SUBUR OTO",
      "ALAMAT": "Jl. K. Sekretaris No. 1A",
      "TELEPHONE": "(021) 569 78850",
      "EMAIL": "-",
      "PIC": "Bpk. Victor / Ibu Ina",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6321"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "JNS",
      "ALAMAT": "Jl.Hadiah No.15 Daan Mogot",
      "TELEPHONE": "(021) 568 4962 ,560 4355",
      "EMAIL": "-",
      "PIC": "Bpk.Arman",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6322"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "ALTRINDO MOTOR (PT. ALTRINDO MOTOR GOL)",
      "ALAMAT": "JL. JOGLO RAYA  NO. 90, KEMBANGAN",
      "TELEPHONE": "021-58908728",
      "EMAIL": "altrindomotor@yahoo.com",
      "PIC": "BP. ALVIN WICAKSANA, IBU AYUN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9197"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "AUTO PRIMA MOTOR / PT. AUTO PRIMA MAKMUR (KHUSUS PREMIUM CAR / TSI\n>300 JT)",
      "ALAMAT": "JL. RAYA SRENGSENG NO.133 RT/RW : 04/06, JAKARTA BARAT",
      "TELEPHONE": "021-58900800",
      "EMAIL": "autoprimamotor33@gmail.com; autoprimamotor@gmail.com",
      "PIC": "BU IMAS, BP. AGUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9198"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "BENINDO MOTOR (PT. BENINDO ANUGRAH SENTOSA)",
      "ALAMAT": "JL. DAAN MOGOT KM. 3 NO. 90 RT/RW 010/002, KEL WIJAYA KUSUMA, KEC GROGOL PETAMBURAN",
      "TELEPHONE": "021-29200971",
      "EMAIL": "benindo.motor40@gmail.com, bensoel@yahoo.com, widi_benindo@yahoo.com, fatur_benindo@yahoo.com",
      "PIC": "BP. BENNY, BP. WIDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9199"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "LASER MOBILINDO",
      "ALAMAT": "JL. DAAN MOGOT KM.21 NO. 223 BATU CEPER",
      "TELEPHONE": "021-5518883",
      "EMAIL": "lasermobilindo@gmail.com",
      "PIC": "BP. HERYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9200"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "MITRA ASIA MOBILINDO (MAM)",
      "ALAMAT": "JL. KEDOYA RAYA NO. 31 RT/RW : 08/07 JAKARTA BARAT",
      "TELEPHONE": "021-5823719, 021-\n5824707",
      "EMAIL": "ws.mam01@gmail.com, ws.mam02@gmail.com",
      "PIC": "BU TUTIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9201"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "NISSAN POS PENGUMBEN",
      "ALAMAT": "JL. PAHLAWAN NO.81 POS PENGUMBEN, KEBON JERUK, JAKARTA BARAT",
      "TELEPHONE": "021-53662368",
      "EMAIL": "edward.budiono@nissan.indomobil.co.id; ribkha.nugrahyana@nissan.indomobil.co.id; collectioncontrol.pengumben@nissan.indomobil.co.id",
      "PIC": "BP. EDWARD, BP HAIKAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9202"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Asia Motor",
      "ALAMAT": "Jl. Raya Daan Mogot KM. 11 No. 37",
      "TELEPHONE": "T: 6198718, 021-6198667",
      "EMAIL": "asiamotor_dmg@yahoo.com",
      "PIC": "Soleh / Afif",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3318"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Benindo Motor (PT.Benindo Anugrah Sentosa)",
      "ALAMAT": "Jl. Daan Mogot Km. 3 No. 90 (Seberang Studio Indosiar) Jakarta Barat",
      "TELEPHONE": "T: 021-29200971",
      "EMAIL": "benindo.motor40@gmail.com",
      "PIC": "Andri / Mega",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3319"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Cahaya Utama",
      "ALAMAT": "Jl. Swadarma Raya No. 25 Srengseng",
      "TELEPHONE": "T: 021-25684114, 021-5861546",
      "EMAIL": "cahayautamamotor@gmail.com",
      "PIC": "Santo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3320"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Citra Pratama Motor",
      "ALAMAT": "Jl. Srengseng Raya No. 87 Kembangan",
      "TELEPHONE": "T: 021-98194498, 021-58903137",
      "EMAIL": "bengkel_cp@yahoo.com",
      "PIC": "Deny / Desi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3321"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Delapan ( 8 ) Selindo Persada",
      "ALAMAT": "Jl. Raya Jelupang, Buaran Barat, Serpong Utara, RT 15 RW 5, BSD, Tangerang",
      "TELEPHONE": "T : 021-55695665",
      "EMAIL": "bengkel.dsp@gmail.com",
      "PIC": "Dian / Endang",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3322"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Inti Raharja Motor (IRM)",
      "ALAMAT": "Jl. Anggrek Rosliana VII/17, Samping Komplek Kijang Kencana, Slipi",
      "TELEPHONE": "T: 021-5360115, 021-5357111",
      "EMAIL": "bengkelirm2@gmail.com",
      "PIC": "Bpk. Willy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3323"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Layanan Prima 1 - Jelambar (Certified Autorized Hyundai)",
      "ALAMAT": "Jl. Jelambar Barat III No 12- A",
      "TELEPHONE": "T: 021-5665930, 021-5665931",
      "EMAIL": "layprima1@yahoo.com",
      "PIC": "Bpk Mesakh",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3324"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Lido Jaya Motor",
      "ALAMAT": "Jl. Raya Meruya Selatan No. 24",
      "TELEPHONE": "T: 021-58903858, 021-58905348",
      "EMAIL": "lidojayamotor@gmail.com",
      "PIC": "Ilham",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3325"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Tripancar Jaya",
      "ALAMAT": "Jl. Meruya Utara Raya Kavling 3\nMeruya - Jakarta Barat",
      "TELEPHONE": "T : 021 - 586 1061/62",
      "EMAIL": "info@tripancarjaya.com",
      "PIC": "Christian / Jurjani",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3326"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "PT Mobilindo Putra Sentosa (B2M)",
      "ALAMAT": "Jl. Bojong Raya No. 124 Outer Ring Road, Puri Cengkareng",
      "TELEPHONE": "T: 021-58304555",
      "EMAIL": "b2m_motor@yahoo.co.id",
      "PIC": "Budiana",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3327"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Putra Wijaya Motor",
      "ALAMAT": "Jl. Daan Mogot Km 14 No 22-23 Cengkareng, Jakarta Barat",
      "TELEPHONE": "T : 021 -  54398226 / 7",
      "EMAIL": "pwm.suzuki@yahoo.com",
      "PIC": "Indra",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3328"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Padi Mas Motor - Gedong panjang",
      "ALAMAT": "Jl. Gedong Panjang No. 2 Pekojan Jakarta",
      "TELEPHONE": "T : 021 - 690 2208,  690 2209",
      "EMAIL": "cs.gp@padimasmotor.com ; cs.hd@padimasmotor.com ; cs.gp@padimasmotor.com",
      "PIC": "Fia / Hendrik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3329"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Lief's 2",
      "ALAMAT": "Jl. Patra Raya No. 82 Duri Kepa, Kebon Jeruk - Jakarta Barat",
      "TELEPHONE": "T : 021 - 5637534; 5638545",
      "EMAIL": "liefs2001@yahoo.com",
      "PIC": "Idi Setiadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3330"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "APC (Auto Platinum Care)",
      "ALAMAT": "Jl. Lapangan Bola Srengseng No. 51 B - Jakarta",
      "TELEPHONE": "T : 021-5865511;085715478098",
      "EMAIL": "apc_jaya@yahoo.co.id",
      "PIC": "Chandra / Yuli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3331"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Chung Service",
      "ALAMAT": "Jl.Duri Raya TSS No. 54-56 Jembatan Lima - Jakarta Barat 11270",
      "TELEPHONE": "T : 021-6313255 / 021-6310789",
      "EMAIL": "chung_service@yahoo.com",
      "PIC": "Wawan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3332"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Padi Mas Motor - Hadiah",
      "ALAMAT": "Jl. Hadiah No. 18, Daan Mogot",
      "TELEPHONE": "T : 021 - 566 0340 ;564 0334",
      "EMAIL": "cs.hd@padimasmotor.com",
      "PIC": "Agus / Yadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3333"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Honda Istana Kebun Jeruk",
      "ALAMAT": "Jl. Kedoya Al kamal No. 12, Jakarta Barat",
      "TELEPHONE": "T: 021 - 58356048",
      "EMAIL": "honda.ikj@gmail.com",
      "PIC": "Patricia / Yuli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3334"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Palmerah Inti Motor",
      "ALAMAT": "Jl. Meruya Selatan No. 23 � Jakarta Barat",
      "TELEPHONE": "021 � 2254 1250 - 51",
      "EMAIL": "palmerahintimotor@yahoo.co.id",
      "PIC": "Chris / Iis",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3335"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Nissan Pos Pengumben (PT. Indobuana Autoraya)",
      "ALAMAT": "Jl. Pahlawan No. 81 Pos Pengumben , Kebon ejruk , Jakarta Barat",
      "TELEPHONE": "T : 021 - 53662368",
      "EMAIL": "rachmawati@nissan.indomobil.co.id",
      "PIC": "Bpk. Budiarto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3336"
    },
    {
      "WILAYAH": "Jakarta Barat",
      "NAMA BENGKEL": "Tunas BMW (PT. Tunas Mobilindo Parama)",
      "ALAMAT": "Jl. Tomang Raya No. 19 RT.1/RW.1, Tomang, Grogol petamburan, Kota Jakarta Barat",
      "TELEPHONE": "T : 021 - 566 7509 / 021 - 563 3152",
      "EMAIL": "thomson.hasibuan@bmw-tunas.co.id",
      "PIC": "Subhan / Roman",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3337"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "Cindy Motor",
      "ALAMAT": "Jl. Tolo No.18, Srengseng Raya, Jakarta Barat",
      "TELEPHONE": "021-58905960",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310850"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "Gema Service Motor",
      "ALAMAT": "Jl. Palmerah Barat No. 38 Jakarta Barat",
      "TELEPHONE": "021-5348357 / 0818733888",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310851"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "Trans Eurokars",
      "ALAMAT": "Jl. Panjang No. 6 Kebon Jeruk",
      "TELEPHONE": "021 - 5358000",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310852"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "PT. Plaza Toyota Kyai Tapa",
      "ALAMAT": "Jl. Kyai Tapa No. 263 Grogol Jakarta Barat",
      "TELEPHONE": "021-5697 8118",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310853"
    },
    {
      "WILAYAH": "JAKARTA BARAT",
      "NAMA BENGKEL": "PT. Plaza Toyota Green Garden",
      "ALAMAT": "Jalan Panjang No. 25 Kedoya Jakarta Barat",
      "TELEPHONE": "(021) 583 02 555",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310854"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "KARYAKITA PUTRA SEJAHTERA (KIA PLAZA)",
      "ALAMAT": "Jl. Gunung Sahari Ancol No.17",
      "TELEPHONE": "(021) 6253375",
      "EMAIL": "-",
      "PIC": "Ibu Shanty / Ibu Fitri",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6323"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "GEMA SERVICE MOTOR",
      "ALAMAT": "Jl. Palmerah Barat No.38",
      "TELEPHONE": "(021) 5480338",
      "EMAIL": "-",
      "PIC": "Bpk. Kunto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6324"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "PT.HERO PALMERAH MOTOR",
      "ALAMAT": "Jl.Palmerah Utara No.76",
      "TELEPHONE": "(021) 5481737",
      "EMAIL": "-",
      "PIC": "Bpk.Didi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6325"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "FONTANA INDAH MOTOR (KHUSUS CHEVROLET)",
      "ALAMAT": "JL. GUNUNG SAHARI XI NO. 21 RT. 018 RW. 003, KEL. GUNUNG SAHARI UTARA, KEC. SAWAH BESAR, JAKARTA PUSAT",
      "TELEPHONE": "021-6281147",
      "EMAIL": "chevrolet@fontanamotor.com",
      "PIC": "BP. DIMAS, BP DENI, BP. RIFAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9203"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "JAYA BARU MOTOR",
      "ALAMAT": "JL. BANGAU IV NO.2, BLOK II 6-8",
      "TELEPHONE": "021-4256339",
      "EMAIL": "jbm.senen42@gmail.com",
      "PIC": "BP. JEFFRY, IBU SOFI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9204"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "KARYAKITA PUTRA SEJAHTERA",
      "ALAMAT": "JL GUNUNG SAHARI ANCOL NO. 17, KELURAHAN GUNUNG SAHARI UTARA, KECAMATAN SAWAH BESAR, JAKARTA PUSAT.",
      "TELEPHONE": "021-6253378",
      "EMAIL": "karyakitaputrasejahtera@gmail.com",
      "PIC": "BU SHANTY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9205"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Fontana Indah Motor (Authorized Chevrolet, CBU)",
      "ALAMAT": "Jl. Gunung Sahari XI No. 21 Gg. Lilin",
      "TELEPHONE": "T: 021-6281147",
      "EMAIL": "bodyrepair@fontanamotor.com",
      "PIC": "Warim",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3338"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Jaya Baru Motor",
      "ALAMAT": "Jl. Bangau IV No.2B Senen",
      "TELEPHONE": "T: 021-29987288",
      "EMAIL": "jbm.senen42@gmail.com",
      "PIC": "Bpk. Yos / Anton",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3339"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Kia Plaza Motor/Karyakita Putra Sejahtera (Authorized Dealer KIA)",
      "ALAMAT": "Jl. Gunung Sahari Ancol No. 17",
      "TELEPHONE": "T: 021-6253378",
      "EMAIL": "karyakitaputrasejahtera@gmail.com ;shanti1_shanti@yahoo.co.id",
      "PIC": "Edi Suryono / Daryono",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3340"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Langgeng Indah Era Fajar (LIEF'S) (Certified Autorized Suzuki )",
      "ALAMAT": "Jl. Suryopranoto No. 20",
      "TELEPHONE": "T: 021-6325240 021-6324820",
      "EMAIL": "liefs2001@yahoo.com",
      "PIC": "Cecep / Erik / Irwan / Icup",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3341"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Millenium Motor",
      "ALAMAT": "Jl. Letjend. Suprapto No. 19A Cempaka Putih",
      "TELEPHONE": "T: 021-4267888",
      "EMAIL": "milleniummotor@yahoo.co.id",
      "PIC": "Beni Sutardi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3342"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "NV MASS (PT. Mass Sarana Motorama )",
      "ALAMAT": "Jl. Jend. Sudirman No.kav.8, Karet Tengsin, Tanahabang",
      "TELEPHONE": "T : 021 - 29772600 ; F : 021 - 5736948",
      "EMAIL": "adh@nvmass.com",
      "PIC": "Eko / Yeyen",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3343"
    },
    {
      "WILAYAH": "Jakarta Pusat",
      "NAMA BENGKEL": "Astra Daihatsu Pangeran Jayakarta",
      "ALAMAT": "Jl. Pangeran Jayakarta No. 28",
      "TELEPHONE": "T: 021-6590606, 021-6590600",
      "EMAIL": "suherman@dso.astra.co.id",
      "PIC": "Edi / Ade Suheli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3344"
    },
    {
      "WILAYAH": "JAKARTA PUSAT",
      "NAMA BENGKEL": "Astrido Jaya Mobilindo",
      "ALAMAT": "Jl. Batu Tulis Raya No. 50 CDEF, Jakarta Pusat",
      "TELEPHONE": "0858-8353-2388 / (021) 3855360",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310855"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "MEKANIKA MOTOR",
      "ALAMAT": "Jl. Kapten Tendean No.9",
      "TELEPHONE": "(021) 7991079",
      "EMAIL": "-",
      "PIC": "Bpk.Budiwan / Ibu Dian",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6326"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "HATAMA MOTOR",
      "ALAMAT": "Jl. RC. Veteran No.7, Bintaro",
      "TELEPHONE": "(021) 734 1691",
      "EMAIL": "-",
      "PIC": "Ibu Aggra / Bpk.Dwi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6327"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "BAVARIA MERCINDO MOTOR",
      "ALAMAT": "Jl.Kesatria no.45 A",
      "TELEPHONE": "(021) 74714465",
      "EMAIL": "-",
      "PIC": "Bpk.Harry/Ibu Ninik",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6328"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "ARTHAS MOBILINDO SENTOSA",
      "ALAMAT": "JL. KEMANG RAYA  NO.11 BANGKA, MAMPANG PRAPATAN, JAKARTA SELATAN, 12730",
      "TELEPHONE": "021-7196755, 021-\n7183840",
      "EMAIL": "arthasmobilindo@gmail.com; ari_arthas@yahoo.com; nur_arthas@yahoo.com",
      "PIC": "IBU ARI, IBU HENNY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9206"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "BAVARIA MERCINDO MOTOR (KHUSUS PREMIUM CAR / TSI >300 JT)",
      "ALAMAT": "JL. KESATRIAN NO. 45 A, REMPOA CIPUTAT",
      "TELEPHONE": "021-74714465, 021-\n74708782",
      "EMAIL": "bmmotor_br@yahoo.com; bm_motor@yahoo.com",
      "PIC": "BP. HARRY, BP BENEDIKTUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9207"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "INDO OTO SERVICE CENTER (IOSC)",
      "ALAMAT": "JL. LET. JEND. TB. SIMATUPANG NO.7, PASAR MINGGU, JAKARTA SELATAN, 12520",
      "TELEPHONE": "021-7890229",
      "EMAIL": "iosc_iosc@yahoo.com",
      "PIC": "BP. FERRY, IBU SASHI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9208"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "ISTANA KEBAYORAN BARU MOTOR/IKFAT (KHUSUS HONDA)",
      "ALAMAT": "JL. RS. FATMAWATI NO.21,JAKARTA SELATAN, 12410",
      "TELEPHONE": "021-7508895, 021-\n7507723",
      "EMAIL": "bodypaint-ikb1@honda-ikb.com",
      "PIC": "BP. TETI, IBU DEWI, BP AGUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9209"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "ISTANA KEBAYORAN BARU MOTOR/IKMPI (KHUSUS HONDA)",
      "ALAMAT": "JL. SULTAN ISKANDAR MUDA KAV.8 RT/RW : 01/05, JAKARTA SELATAN, 12240",
      "TELEPHONE": "021-7223377",
      "EMAIL": "dea.bp@honda-ikb.com; hersu@honda-ikb.com",
      "PIC": "BP. HERSUNOTO, BP GIRI, IBU DEA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9210"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "NISSAN WARUNG BUNCIT",
      "ALAMAT": "JL. WARUNG JATI BARAT NO. 34, JATI PADANG-PSR MINGGU, JAKARTA SELATAN",
      "TELEPHONE": "021-78833788",
      "EMAIL": "prawiniko.sugistianto@nissan.indomobil.co.id, wahyu.setyawan@nissan.indomobil.co.id",
      "PIC": "BP. PRAWINIKO, BP. WAHYU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9211"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Bintang Bijaksana",
      "ALAMAT": "Jl. Raya Kebayoran Lama PAL VII No. 2",
      "TELEPHONE": "T: 021-5300220, 021-5308335",
      "EMAIL": "bintangbijaksana@yahoo.co.id",
      "PIC": "Rudy W",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3345"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Layanan Prima 2 - Ciledug (Certified Autorized Hyundai)",
      "ALAMAT": "Jl. H. Mochtar Raya No. 2 Ciledug",
      "TELEPHONE": "T: 021-7341960",
      "EMAIL": "layprima2@yahoo.com",
      "PIC": "Romi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3346"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Trias Sena Bhakti (Max Motor)",
      "ALAMAT": "Jl. Sultan Iskandar Muda 28A Arteri Pondok Indah",
      "TELEPHONE": "T: 021-7290402, 021-7238971",
      "EMAIL": "trias_sb@yahoo.co.id",
      "PIC": "Kris",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3347"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Trijaya Makmur Motor",
      "ALAMAT": "Jl. Pondok Cabe Raya No. 68A Pamulang",
      "TELEPHONE": "T: 021-7494346, 021-7492989, 081577778026;081511118026",
      "EMAIL": "trijayamakmurm@gmail.com",
      "PIC": "Totok Wahyudi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3348"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Auto 2000 Ciledug",
      "ALAMAT": "Jl. Ciledug Raya No.16 Pertukangan Selatan, Ciledug",
      "TELEPHONE": "T: 021-7352000",
      "EMAIL": "estimatorciledugbp@gmail.com",
      "PIC": "Anyan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3349"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Bumen Redja Abadi - Jakarta",
      "ALAMAT": "Jl. Dr. Saharjo No. 321 Tebet",
      "TELEPHONE": "T: 021-8309256/66 F: 021-8309282",
      "EMAIL": "demesta.gaol@bra.nag.co.id; rizka.maulida@bra.nag.co.id; \nnano@bra.nag.co.id",
      "PIC": "Melisa",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3350"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Cakrawala Automotif Rabhasa (CAR) Authorized Mercedes Benz",
      "ALAMAT": "Jl. Denpasar Raya Blok. D-2 Kav. 12",
      "TELEPHONE": "T: 021-27598999 / 089505202629",
      "EMAIL": "sri.supatmi@car-mbenz.com",
      "PIC": "Nova",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3351"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Fatmawati",
      "ALAMAT": "Jl. RS Fatmawati 21 Kebayoran",
      "TELEPHONE": "T: 021-7508895",
      "EMAIL": "hasim@honda-ikb.com",
      "PIC": "Didi / Ely",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3352"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Permata Hijau",
      "ALAMAT": "Jl. Kebayoran Lama No. 40",
      "TELEPHONE": "T: 021-53690623, 021-5326333",
      "EMAIL": "permatahijau40@yahoo.com",
      "PIC": "Cyinthia Anglina",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3353"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Pondok Indah",
      "ALAMAT": "Jl. Sultan Iskandar Muda Kav 8 Arteri Pondok indah",
      "TELEPHONE": "T: 021-7223377",
      "EMAIL": "jefri@honda-ikb.com",
      "PIC": "Ardy / Dita / Eko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3354"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Pradana",
      "ALAMAT": "Jl. Raden Saleh No.8 Karang Tengah, Ciledug",
      "TELEPHONE": "T: 021-73455888",
      "EMAIL": "hondapradana@gmail.com",
      "PIC": "Anang / Zainal",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3355"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Istana Kebun Jeruk ( IKJ PONDOK PINANG)",
      "ALAMAT": "Jl. Ciputat Raya No. 27 Pondok Pinang , Jakarta Selatan",
      "TELEPHONE": "T : 021 - 75909421 / 7691968",
      "EMAIL": "honda.ikj@gmail.com",
      "PIC": "Ria",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3356"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Istana Kebun Jeruk ( IKJ BUNCIT)",
      "ALAMAT": "Jl. Taman Margasatwa No. 27 Ragunan Pasar Minggu",
      "TELEPHONE": "T : 021 - 78845000 / 5001",
      "EMAIL": "honda.ikj@gmail.com",
      "PIC": "Joko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3357"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Honda Mitra Lenteng Agung",
      "ALAMAT": "Jl. Raya Lenteng Agung No. 55, Tanjung Barat, Jagakarsa",
      "TELEPHONE": "T : 021 - 22712288",
      "EMAIL": "winlly.wawolumaya@hondamitra.com; ahmad.fahrul@hondamitra.com; Bernardus@hondamitra.com; hery.hermanto@hondamitra.com",
      "PIC": "Siti Asiah / Rani",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3358"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Astra International - BMW",
      "ALAMAT": "Jl. RA Kartini Kav. 203 Cilandak - Jakarta Selatan",
      "TELEPHONE": "T : 021 - 7500335; 7691072",
      "EMAIL": "azis.subarna@bmw.astra.co.id",
      "PIC": "Rizal",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3359"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Porsche (PT Autorama Euroasia)",
      "ALAMAT": "Jl. Sultan Iskandar Muda No. 51, Arteri Pondok Indah, Jakarta Selatan",
      "TELEPHONE": "T :021 7258000",
      "EMAIL": "iwan.sharief@porsche-jakarta.co.id",
      "PIC": "Sugeng Riyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3360"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Nissan Warung Buncit (PT. Wahana Senjaya jakarta )",
      "ALAMAT": "Jl. Warung Jati Barat No. 34, Jatipadang Pasar Minggu, Jakarta Selatan",
      "TELEPHONE": "T : 021 - 78835441",
      "EMAIL": "prawiniko.sugistianto@nissan.indomobil.co.id",
      "PIC": "Hadi / Rizki",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3361"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Simprug Jasa Proteksi (CBU & Luxury Cars)",
      "ALAMAT": "Jl. Alteri Pondok Indah No. 1 , kebayoran lama",
      "TELEPHONE": "T: 021-72789999, 021-7204309, F: 021-7226383",
      "EMAIL": "simprugjp@gmail.com",
      "PIC": "Bayu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3362"
    },
    {
      "WILAYAH": "Jakarta Selatan",
      "NAMA BENGKEL": "Hyundai Simprug (PT Hyundai Mobil Indonesia)",
      "ALAMAT": "Gd. Hyundai Indonesia, Jl Teuku Nyak Arief No.14 Simprug - Jakarta Selatan",
      "TELEPHONE": "T: 021-7293338, 081283732986",
      "EMAIL": "iwan.kurniawan@hyundaimobil.com\nkustri.hadi@hyundaimobil.com",
      "PIC": "Julfyanty Wulansari",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3363"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "PT. Plaza Toyota Tendean",
      "ALAMAT": "Jl. Kapten Piere Tendean No. 9A Mampang Kuningan Barat Jakarta Selatan",
      "TELEPHONE": "(021) 5290 1818",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310856"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "Auto 200 BP Ciledug",
      "ALAMAT": "Jl. Haji Adam Malik Deplu Raya Cileduk, Jakarta Selatan",
      "TELEPHONE": "(021) 735 2000",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310857"
    },
    {
      "WILAYAH": "JAKARTA SELATAN",
      "NAMA BENGKEL": "PT. Bumen Redja (Authorized Mitsubishi)",
      "ALAMAT": "Jl. Dr. Saharjo no. 321 Tebet Jakarta Selatan",
      "TELEPHONE": "(021) 8309256",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310858"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "METRO77",
      "ALAMAT": "Jl. Jend. Basuki Rahmat No.7A",
      "TELEPHONE": "(021) 863 1551",
      "EMAIL": "-",
      "PIC": "Bpk. Daniel",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6329"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "ASIA SUKSES MOTOR (ASM) (KHUSUS ACCESORIES)",
      "ALAMAT": "JL. MATRAMAN RAYA NO. 67 B-C, JAKARTA TIMUR",
      "TELEPHONE": "021-85912785-88",
      "EMAIL": "ws.asm01@acains.com",
      "PIC": "BP. HARTONO, BU RIA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9212"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "LAUTAN BERLIAN UTAMA MOTOR (KHUSUS MITSUBISHI)",
      "ALAMAT": "JL. MATRAMAN RAYA NO.71-73",
      "TELEPHONE": "021-8581642",
      "EMAIL": "vathul@lautanberlian.co.id",
      "PIC": "BP. VATHUL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9213"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "MITRA BENGKEL ANDALAN PRATAMA (MBAP)",
      "ALAMAT": "JL. H. NAMAN NO.1 KALIMALANG, JAKARTA TIMUR",
      "TELEPHONE": "021-8648880-1,\n021-8647305",
      "EMAIL": "mbap.sparepart@gmail.com, mbap.estimator2@gmail.com, mbap.cso1@gmail.com",
      "PIC": "BP. PRASETYO, BU POLINA, BU YUNI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9214"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "SEJAHTERA BUANA TRADA/ISI (KHUSUS SUZUKI)",
      "ALAMAT": "JL. RAYA BEKASI KM.19 PULOGADUNG,JAKARTA TIMUR, 13260",
      "TELEPHONE": "021-4609308, 021-\n46820960",
      "EMAIL": "suzuki.pulogadung@ymail.com",
      "PIC": "BP. SLAMET, BP. IDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9215"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "INDOMOBIL MULTI TRADA/SUZUKI IMT (KHUSUS SUZUKI)",
      "ALAMAT": "JL. RAYA BEKASI KM. 18, KEL JATINEGARA, KEC. CAKUNG, JAKARTA TIMUR 13930",
      "TELEPHONE": "021-460-5858",
      "EMAIL": "walid.qurtubi@imt.indomobil.co.id; istanto@imt.indomobil.co.id",
      "PIC": "BP. WALID, BP ISTANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9216"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Auto Klinik",
      "ALAMAT": "Jl. Bagunan Barat No. 2 Kayu Putih Rawamangun",
      "TELEPHONE": "T: 021-47881363, 0811-1828567",
      "EMAIL": "auto.klinik@yahoo.com",
      "PIC": "Eko Sudaryanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3364"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Bintang Sekata Mandiri (BSM)",
      "ALAMAT": "Jl. Masjid No. 19 Susukan, Ciracas",
      "TELEPHONE": "T: 021-87783006, 021-87783007",
      "EMAIL": "bengkelbintang19@gmail.com",
      "PIC": "M. Khoirul Muna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3365"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Citra Sima",
      "ALAMAT": "Jl. Raya Cipinang Jaya No. 38",
      "TELEPHONE": "T: 021-8199862, 0819-05208008",
      "EMAIL": "citrasima@gmail.com",
      "PIC": "Mahar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3366"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Duren Sawit Mobilindo",
      "ALAMAT": "Jl. Taman Malaka Selatan No. 1-2 Duren Sawit",
      "TELEPHONE": "T: 021-8654125",
      "EMAIL": "durensawitmobilindo@gmail.com",
      "PIC": "Rino",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3367"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "M'95",
      "ALAMAT": "Jl. Raya Pondok Gede No. 29, Jakarta Timur",
      "TELEPHONE": "T : 021 - 87783601; 085214529262",
      "EMAIL": "bengkel.m95@gmail.com",
      "PIC": "Subhan Firdaus",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3368"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Tanjung Perdana",
      "ALAMAT": "Jl. Basuki Rahmat No. 171 Jakarta",
      "TELEPHONE": "T : 021 � 2208 9610",
      "EMAIL": "tanjungperdanamotor@gmail.com",
      "PIC": "Hendy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3369"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "CV Karya Jaya Baru 1",
      "ALAMAT": "Kp. Baru, Jln. Kapin No.1B RT 09/08, Pondok Kelapa Duren Sawit, Jakarta Timur",
      "TELEPHONE": "T: 021-8648035 / 08129668141",
      "EMAIL": "kjb_satu@yahoo.com",
      "PIC": "Kemal / Safrin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3370"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Honda Surya Jaya Cijantung",
      "ALAMAT": "Jl. Raya Bogor Km. 26 No. 21 Ciracas,Jakarta Timur",
      "TELEPHONE": "T : 021 - 29823888 / 087786473766",
      "EMAIL": "ajiprasetya333@gmail.com dan aji.prasetya@honda mitra.com",
      "PIC": "Faisal",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3371"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Nissan Pulo Gadung (PT Indobuana Autoraya)",
      "ALAMAT": "Pulogadung Industrial Estate, Jl. Raya Bekasi Km. 18 Jakarta Timur",
      "TELEPHONE": "T : 021 - 46831318",
      "EMAIL": "christian.ariata@nissan.indomobil.co.id",
      "PIC": "Anwar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3372"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Nissan Cibubur",
      "ALAMAT": "Jl. Raya Alternatif Cibubur KM. 7, Cibubur",
      "TELEPHONE": "T : 021 - 82494850",
      "EMAIL": "wsabodyrepair.cibubur@nissan.indomobil.co.id",
      "PIC": "Agustin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3373"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "PT Arista Group (Honda, Mitsubishi, Wuling)",
      "ALAMAT": "Jl. Raya Kalimalang No. 19 Jakarta Timur",
      "TELEPHONE": "T: 021-86608222",
      "EMAIL": "bodyrepair.apm@arista-group.co.id",
      "PIC": "Effendy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3374"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Honda Cibubur",
      "ALAMAT": "Jl. Raya Alternatif Cibubur Cileungsi No. 38, Cibubur",
      "TELEPHONE": "T : 021-84599042 - 43 / 081289092526",
      "EMAIL": "claim.bp1@hondacibubur.com",
      "PIC": "Andi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3375"
    },
    {
      "WILAYAH": "Jakarta Timur",
      "NAMA BENGKEL": "Suzuki Pulo Gadung (PT Sejahtera Buana Trada)",
      "ALAMAT": "Jl. Raya Bekasi Km. 19, Pulogadung Rawa Terare, Cakung - Jakarta Timur",
      "TELEPHONE": "T : 021 - 4611492; 4616152",
      "EMAIL": "idi.setiadi@suzuki-mobil.co.id\nsuzuki.pulogadung@ymail.com",
      "PIC": "Irwan Setiawan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3376"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "Dewi Buana Mulia",
      "ALAMAT": "Jl. Raya Pondok Gede No. 29",
      "TELEPHONE": "(021) 87783601",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310859"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "Citra Sima",
      "ALAMAT": "Jl. Raya Cipinang Jaya No. 38 Rt. 002 Rw. 006 Kel. Cipinang Muara Kec. Jati Negara Jakarta Timur",
      "TELEPHONE": 81905208008,
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310860"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "PT.  Sejahtera Buana Trada",
      "ALAMAT": "Jl. Raya Bekasi KM-18, Pulogadung Rawa Terate - Cakung, Jakarta Timur",
      "TELEPHONE": "021-4611492",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310861"
    },
    {
      "WILAYAH": "JAKARTA TIMUR",
      "NAMA BENGKEL": "PT. Plaza Toyota Pemuda",
      "ALAMAT": "Jl. Pemuda Raya 1 No 6 Pulo Gadung Jakarta Timur",
      "TELEPHONE": "(021) 4786 8686",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310862"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "SETIA MANDIRI MOTOR",
      "ALAMAT": "Jl. Danau Sunter Barat A1 No.2",
      "TELEPHONE": "(021) 65837387",
      "EMAIL": "-",
      "PIC": "Bpk. Fikri / Ibu Rahma",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6330"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "JAYA BARU SERVICE",
      "ALAMAT": "jl. Sunter Kemayoran Raya No.50 A",
      "TELEPHONE": "(021) 6515012",
      "EMAIL": "-",
      "PIC": "Bpk. Ali",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6331"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "NOVA MOTOR",
      "ALAMAT": "Komplek Gading Elok Utara III",
      "TELEPHONE": "(021) 4508718",
      "EMAIL": "-",
      "PIC": "Ibu Tuti",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6332"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "PADIMAS 2",
      "ALAMAT": "Jl. Gedong Panjang No.2",
      "TELEPHONE": "(021) 6902208",
      "EMAIL": "-",
      "PIC": "Bpk. Handoko /Ibu Karlina",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6333"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "ABADI JAYA MOTOR",
      "ALAMAT": "Jl. Semut No. 49A",
      "TELEPHONE": "(021) 6930159",
      "EMAIL": "-",
      "PIC": "Ibu Amunk",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6334"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "A Lyang Motor",
      "ALAMAT": "Jl. Pluit Mas VI/I Blok. E/IIA, Komplek Pluit Mas, Jembatan Tiga Raya",
      "TELEPHONE": "T: 021-6681414, 021-6695406",
      "EMAIL": "alyangmotor@yahoo.com",
      "PIC": "Freddy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3377"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Kencana Auto Mandiri (KAM)",
      "ALAMAT": "Jl. Kramat Jaya No.20-24, Tugu Utara, Koja, Jakarta Utara",
      "TELEPHONE": "T : 021 - 4354035",
      "EMAIL": "wan.wmkam@gmail.com ; admiin.kam741@gmail.com",
      "PIC": "Mr. Sjairodji (Oji)",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3378"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Permata Gading Autocenter ( Khusus Truck dan HE )",
      "ALAMAT": "Jl. Raya Tipar Cakung No.86, Sukapura, Cilincing, Jakarta Utara",
      "TELEPHONE": "T : 021 - 4404788, 44949523",
      "EMAIL": "pga.motor86@gmail.com; agusr.pga86@gmail.com",
      "PIC": "Mr. Daud",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3379"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Tripancar Jaya",
      "ALAMAT": "Jl. Bandengan Utara III No.37 RT.13/RW.10, Gedong Panjang\nJakarta Utara 11240",
      "TELEPHONE": "T : 021 - 2269 3534",
      "EMAIL": "info@tripancarjaya.com",
      "PIC": "Mr. Edi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3380"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "CV Victoria Jaya Motor",
      "ALAMAT": "Jln. Sunter Pulo Kecil No. 42A, Sunter Jaya, Jakarta Utara",
      "TELEPHONE": "T: 021-6509095 / 089601489653",
      "EMAIL": "victoriajayamotor@yahoo.co.id",
      "PIC": "Dodik Priambodo / Kahono",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3381"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Miki Jaya Motor",
      "ALAMAT": "Jl. Teluk Gong Raya No. 4 (Terusan Bandengan Utara)",
      "TELEPHONE": "T : 021-6678528;021-66605971",
      "EMAIL": "claim.mikijaya@gmail.com",
      "PIC": "Agus / Heru",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3382"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "PT Sahabat Sukses Bermitra (SBB)",
      "ALAMAT": "Jl. Kapuk Pulo No. 88E RT 07/10, Kapuk, Jakarta Utara",
      "TELEPHONE": "T : 021-5407674 / 083893777846",
      "EMAIL": "ss.bermitra@gmail.com",
      "PIC": "Niko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3383"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Astra Daihatsu Pluit",
      "ALAMAT": "Jl. Raya Pluit Selatan No. 4",
      "TELEPHONE": "T: 021-6690755, 021-6612341",
      "EMAIL": "warim@dso.astra.co.id",
      "PIC": "Zulfikar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3384"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Astrido Toyota (PT Astrido Jaya Mobilindo)",
      "ALAMAT": "Jalan Yos Sudarso No. 19, Jakarta Utara",
      "TELEPHONE": "T : 021 - 4300777",
      "EMAIL": "anton.wijanarko@astridogroup.com",
      "PIC": "Elly",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3385"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Auto 2000  Sunter",
      "ALAMAT": "Jl. Gaya Motor III No.3  Sunter",
      "TELEPHONE": "T: 021-6502000, 021-2602000",
      "EMAIL": "booking.bpsunter@tso.astra.co.id",
      "PIC": "Vira",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3386"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Astra International - BMW Sunter",
      "ALAMAT": "Jl. Gaya Motor Selatan No. 1, sunter II, Jakarta Utara",
      "TELEPHONE": "T : 021 - 6505959; 6509330; 6503636 ; 085697643552; 085697643556; 085697643557; 085697643558",
      "EMAIL": "achmad.sudradjat@bmw.astra.co.id",
      "PIC": "Erfan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3387"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Astra International - BMW Pluit",
      "ALAMAT": "Jl. Pluit Putra Raya No. 21, Jakarta Utara",
      "TELEPHONE": "T : 021 - 66605599 / 085811313112",
      "EMAIL": "beni.sutardi@bmw.astra.co.id",
      "PIC": "Iya / Ainun",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3388"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Ayun Jaya Motor (CBU & Luxury Cars) / PT AMSO",
      "ALAMAT": "Jl. Danau Sunter Selatan Blok. O3 Kav. 43-44",
      "TELEPHONE": "T: 021-65835555, 021-65834555, F: 021-6510149",
      "EMAIL": "bodyrepairajm@yahoo.com",
      "PIC": "Kevin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3389"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Dipo Angkasa Motor (Authorized Mercedes-Benz)",
      "ALAMAT": "Jl. Pluit Selatan No. 1C",
      "TELEPHONE": "T: 021-6611111, F: 021-6602050, 021-6600015",
      "EMAIL": "service@dipomotor.com",
      "PIC": "Muhammad Syahrul Qadri Aulia (ARUEL)",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3390"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Honda Sunter",
      "ALAMAT": "Jl. Danau Sunter Barat A 1/7",
      "TELEPHONE": "T: 021-6403740, 021-6410470",
      "EMAIL": "hondasunter.sales@gmail.com",
      "PIC": "Frienky Wijaya",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3391"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Honda Pluit (Pradana)",
      "ALAMAT": "JL. Pluit Raya Selatan NO. 2, Jakarta Utara",
      "TELEPHONE": "T: 021-6677888",
      "EMAIL": "hondapradana@gmail.com",
      "PIC": "Ayin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3392"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Suzuki Gunung Sahari (PT Handijaya Buana Trada)",
      "ALAMAT": "Jl. Gunung Sahari Ancol No. 34 , Jakarta Utara",
      "TELEPHONE": "T : 021- 6401088",
      "EMAIL": "body_repair@suzukihandijaya.com",
      "PIC": "Risa",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3393"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "Nissan Ancol (PT Indo Buana Auto Raya)",
      "ALAMAT": "Jl. Ancol Barat VIII No. 2, Ancol Barat, Jakarta Utara",
      "TELEPHONE": "T : 021 - 6919969",
      "EMAIL": "aprizal.fikri@nissan.idnomobil.co.id",
      "PIC": "Yuni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3394"
    },
    {
      "WILAYAH": "Jakarta Utara",
      "NAMA BENGKEL": "PT. Gading Prima Autoland (Honda Autoland)",
      "ALAMAT": "Jl. Raya Boulevard Barat Blok X-B No.  1 - 2 Jakarta Utara",
      "TELEPHONE": "T : 021 - 46826699",
      "EMAIL": "jhonnyatm@gmail.com",
      "PIC": "Eka",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3395"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "Auto Prima Karsa (Apik)",
      "ALAMAT": "WTC Mangga Dua, Tahap Dua, Jl. Mangga Dua Raya No. 8 Ancol Pademangan Jakarta Utara",
      "TELEPHONE": "0858-9428-0223",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310863"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "Ivan Motor",
      "ALAMAT": "Jl. Ancol Selatan II No. 42, Sunter Podomoro, Jakarta Utara",
      "TELEPHONE": "021 - 658 34408, 658 344434",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310864"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "Astra International",
      "ALAMAT": "Jl. Gaya Motor Selatan No. 1 Sunter II, Jakarta Utara",
      "TELEPHONE": "(021) 6505959",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310865"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "Astra International",
      "ALAMAT": "Jl. Yos Sudarso No.Kav. 24, RT.10/RW.6, Sungai Bambu, Tj. Priok, Kota Jkt Utara",
      "TELEPHONE": "(021) 6507000",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310866"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "PT. Toyota Astra Motor",
      "ALAMAT": "Jl. Gaya Motor III No. 3 Sunter Jakarta Utara",
      "TELEPHONE": "(021) 6502000",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310867"
    },
    {
      "WILAYAH": "JAKARTA UTARA",
      "NAMA BENGKEL": "PT. Dipo Angkasa Motor",
      "ALAMAT": "Jl. Pluit Selatan 1C, Jakarta Utara",
      "TELEPHONE": "(021) 6611111",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310868"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "ASTRIDO YOS SUDARSO (KHUSUS TOYOTA)",
      "ALAMAT": "JL. YOS SUDARSO NO. 19, JAKARTA UTARA",
      "TELEPHONE": "021-4300777",
      "EMAIL": "bptyt-yos@astridogroup.com, admsvctyt-bpyos@astridogroup.com",
      "PIC": "BP. ANTON W, BP FADHIL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9217"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "AUTO GLASS (KHUSUS PENGERJAAN KACA)",
      "ALAMAT": "JL. DANAU SUNTER UTARA BLOK J12 NO.78-79, SUNTER AGUNG, JAKARTA UTARA",
      "TELEPHONE": "021-65303300",
      "EMAIL": "autoglass-jkt1@cbn.net.id",
      "PIC": "BP. RUBY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9218"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "DIPO ANGKASA MOTOR (KHUSUS MERCY)",
      "ALAMAT": "JL. PLUIT SELATAN 1C, JAKARTA UTARA",
      "TELEPHONE": "021-6600015, 021-\n6611111",
      "EMAIL": "bnp@dipomotor.com, maya.bnp@dipomotor.com",
      "PIC": "BU MAYA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9219"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "HANDIJAYA BUANA TRADA (KHUSUS SUZUKI)",
      "ALAMAT": "JL. GUNUNG SAHARI RAYA NO. 34, ANCOL, PADEMANGAN JAKARTA UTARA",
      "TELEPHONE": "021-6401088",
      "EMAIL": "mgr.bp@suzukihandijaya.com",
      "PIC": "IBU. CIQA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9220"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "HONDA SUNTER / PT. HANDIJAYA SUKATAMA (KHUSUS HONDA)",
      "ALAMAT": "JL. DANAU SUNTER BARAT BLOK A1/7, JAKARTA UTARA",
      "TELEPHONE": "021-6403740",
      "EMAIL": "handijayabp1@gmail.com",
      "PIC": "BP. MARCEL, BU NOVA, BU CITA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9221"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "NISSAN ANCOL",
      "ALAMAT": "JL. ANCOL BARAT VIII NO.2 RT/RW : 02/01, JAKARTA UTARA",
      "TELEPHONE": "021-6919969",
      "EMAIL": "wsabodyrepair.ancol@nissan.indomobil.co.id; totok.wahyudi@nissan.indomobil.co.id; avif.santoso@nissan.indomobil.co.id",
      "PIC": "BP. TOTOK W.",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9222"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "PT. ASTRA INTERNATIONAL TBK - PEUGEOT (KHUSUS BMW DAN PEUGEOT)",
      "ALAMAT": "JL. YOS SUDARSO KAV. 24, KEL. SUNGAI BAMBU, KEC. TANJUNG PRIOK, JAKARTA UTARA",
      "TELEPHONE": "021-6512325",
      "EMAIL": "roni.agung@peugeot.astra.co.id; \nali.akbarvelayati@peugeot.astra.co.id",
      "PIC": "BP. VINSEN, BP RONI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9223"
    },
    {
      "WILAYAH": "JAKRTA UTARA",
      "NAMA BENGKEL": "PERMATA GADING AUTOCENTER",
      "ALAMAT": "JL. RAYA TIPAR CAKUNG NO. 86 SUKAPURA, CILINCING, JAKARTA UTARA, 14140",
      "TELEPHONE": "021-4404788, 021-\n44941523, 021-\n44940723",
      "EMAIL": "adm.pga02@gmail.com; adm.pga01@gmail.com, service.permata9@gmail.com",
      "PIC": "BP. AGUS RACHMAT, IBU GINA, IBU WANTI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9224"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "CV.PANCA INDAH MOTOR",
      "ALAMAT": "Jl.HM.Yusuf Nasri No.07",
      "TELEPHONE": "(0741)  32789",
      "EMAIL": "-",
      "PIC": "Ibu.Serly",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6335"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "UD.TRIJAYA",
      "ALAMAT": "Jl.Kol.Pol M.Taher No.98",
      "TELEPHONE": "(0741) 22576, 32576",
      "EMAIL": "-",
      "PIC": "Bpk Eddy Suyanto,SE",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6336"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "BENGKEL CAROLINE",
      "ALAMAT": "JL. LINGKAR BARAT RT. 05 MEKAR JAYA, SUNGAI GELAM MUARO JAMBI - 36373",
      "TELEPHONE": "0813 6609 1826",
      "EMAIL": "CV.CMS2019@Gmail.com",
      "PIC": "BAPAK MICHEL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9225"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "SUMBER TEHNIK",
      "ALAMAT": "JL. KAPTEN ZAIDI KEL. PAAL V KEC. KOTA BARU KOTA JAMBI",
      "TELEPHONE": "0741-41535",
      "EMAIL": "Claim.stj2016@gmail.com",
      "PIC": "BPK YUNUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9226"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "PANCA INDAH JAYA MOTOR",
      "ALAMAT": "JL. H.M. YUSUF NASRI NO 23. BELAKANG PERSIJAM. JAMBI,",
      "TELEPHONE": "0741-32789",
      "EMAIL": "pijm_bengkel@yahoo.co.id",
      "PIC": "IBU NOVI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9227"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "VITO JAYA",
      "ALAMAT": "JL. A. RONI NO. 82 KELURAHAN HANDIL JAYA KEC. JELUTUNG KOTA JAMBI",
      "TELEPHONE": "0741-446804",
      "EMAIL": "Vitojayamobilindo@yahoo.co.id",
      "PIC": "BPK YOYOK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9228"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "TELADAN AUTO REPAIR",
      "ALAMAT": "JL. M. YAMIN NO. 43 LRG. TELADAN SIMPANG KAWAT KOTA JAMBI",
      "TELEPHONE": "0741-670151",
      "EMAIL": "Teladanautorepair@ymail.com",
      "PIC": "BPK PARCOYO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9229"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "JUAN MOTOR",
      "ALAMAT": "JL. GAJAH MADA LRG. GAMA 2 NO. 35 RT. 52 DEPAN ASRAMA PN JELUTUNG KOTA JAMBI",
      "TELEPHONE": "0741-7554952",
      "EMAIL": "nova_juanmotor@yahoo.co.id",
      "PIC": "IBU NOVA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9230"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "TRI JAYA",
      "ALAMAT": "JL. KOL. POL. M. TAHER NO. 01 PASIR PUTIH JAMBI",
      "TELEPHONE": "0741-22576",
      "EMAIL": "trijaya_jambi@yahoo.co.id",
      "PIC": "IBU ANISA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9231"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "HONDA WILTOP",
      "ALAMAT": "JL. PROF SOEMANTRI BROJONEGORO NO. 08 SIPIN JAMBI",
      "TELEPHONE": "0741-669798",
      "EMAIL": "honda.bodicat@gmail.com",
      "PIC": "BPK ZULKARNAIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9232"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "AGUNG AUTOMALL",
      "ALAMAT": "JL. MARSDA SURYA DARMA, KOTA BARU PAL 10 JAMBI",
      "TELEPHONE": "0741-5915550`",
      "EMAIL": "Agungtoyotabp@yahoo.com",
      "PIC": "DENNY YOSSANDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9233"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "DELTA OTOMOTIF",
      "ALAMAT": "JL. JEND. SUDIRMAN NO. 88 A.B.C PAL 06 ARAH BANGKO - MUARO BUNGO",
      "TELEPHONE": "-",
      "EMAIL": "Admdeltaoto@yahoo.com",
      "PIC": "LOLI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9234"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "AUTO MANDIRI SEJAHTERA",
      "ALAMAT": "JL. DANAU RT. 1 RW. 1 KEL. DUSUN BESAR KEC. SINGARAN PATI KOTA BENGKULU",
      "TELEPHONE": "0736-5611765",
      "EMAIL": "aguswiyanto.amsbkl@gmail.com",
      "PIC": "AGUS WIYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9235"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "TOYOTA BENGKULU",
      "ALAMAT": "JL. P NATADIRJA BENGKULU",
      "TELEPHONE": "0736-21127",
      "EMAIL": "amsar_aambkl@gmail.com",
      "PIC": "AMSAR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9236"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "INTERNATIONAL BODY",
      "ALAMAT": "JL. FLAMBOYAN 23 NO. 40 RT 19 SKIP KOTA BENGKULU",
      "TELEPHONE": "0736-346540",
      "EMAIL": "internasional.body@gmail.com",
      "PIC": "LILIS SURYANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9237"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "SPEED BODY REPAIR",
      "ALAMAT": "JL. RAFLESIA SIMPANG EMPAT PANTAI",
      "TELEPHONE": "0736-21983",
      "EMAIL": "speed.bodyrepair@gmail.com",
      "PIC": "HENDRO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9238"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "PELITA MAJU MOTOR",
      "ALAMAT": "JL. EKA JAYA RT. 016 BELAKANG PUSKESMAS TALANG BAKUNG",
      "TELEPHONE": "0741-3604853",
      "EMAIL": "pelita_majumotor@yaoo.co.id",
      "PIC": "ADJIE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9239"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "PEMANCAR",
      "ALAMAT": "JL. DEPATI PURBO LRG. RT 11 KEL. BULURAN KENALI KEC. TELANAI PURA",
      "TELEPHONE": "0741 306 3007",
      "EMAIL": "cv.bengkelpemancar@gmail.com",
      "PIC": "DESSY / BUJANG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9240"
    },
    {
      "WILAYAH": "JAMBI",
      "NAMA BENGKEL": "HONDA ARISTA",
      "ALAMAT": "JL. ADAM MALIK RT. 01 RW 01 KEL PAGAR DEWA KEC. SELEBAR",
      "TELEPHONE": "0736 5511888",
      "EMAIL": "bodyrepair.bengkulu@honda-arista.co.id",
      "PIC": "HENDRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9241"
    },
    {
      "WILAYAH": "Jambi",
      "NAMA BENGKEL": "GAT Motor",
      "ALAMAT": "Jl. Fatah Laside No. 48, The Hok, Jambi Selatan",
      "TELEPHONE": "T: 0741-445795, 0822-81600488",
      "EMAIL": "gat_motor_jambi@yahoo.com\ngat_motor_lunjuk@yahoo.com",
      "PIC": "Ika",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3396"
    },
    {
      "WILAYAH": "Jambi",
      "NAMA BENGKEL": "Juan Motor",
      "ALAMAT": "Jl. Gajah Mada Lrg. Gama 02 No.35 RT.52 (Depan Asrama PM)",
      "TELEPHONE": "T: 0741-7554952, 0812-74740070",
      "EMAIL": "nova_juanmotor@yahoo.co.id",
      "PIC": "Yani",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3397"
    },
    {
      "WILAYAH": "Jambi",
      "NAMA BENGKEL": "CV Fix A Car",
      "ALAMAT": "Jl. Multatuli Lrg. Harapan Tani 1 RT 03 Kec. Alam Barajo Kel. Mayang Mangurai Jambi",
      "TELEPHONE": "T: 0741-3063477 / 08117950505",
      "EMAIL": "bengkelfixacar@gmail.com",
      "PIC": "Aina",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3398"
    },
    {
      "WILAYAH": "Jambi",
      "NAMA BENGKEL": "CV Delta Mandiri Sukses (Delta Otomotif)",
      "ALAMAT": "Jl. Jend Sudirman Lintas Sumatra Pal 6 No.88 ABC< Muara Bungo - Jambi",
      "TELEPHONE": "T: 061-559989 / 081268128555",
      "EMAIL": "angelina_cyinthia@yahoo.com",
      "PIC": "Aina",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3399"
    },
    {
      "WILAYAH": "Jambi",
      "NAMA BENGKEL": "Sumber Teknik",
      "ALAMAT": "Jl. Kapten Zaidih Paal V Kota Baru",
      "TELEPHONE": "T: 0741-41535, 0853-66288805",
      "EMAIL": "claim.stj2016@gmail.com",
      "PIC": "Freddy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3400"
    },
    {
      "WILAYAH": "JAWA BARAT",
      "NAMA BENGKEL": "Surya Jaya Motor",
      "ALAMAT": "Jl. Abdul Gani No. 6 Kalibaru, Cilodong Depok",
      "TELEPHONE": "021-8763970/71",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310869"
    },
    {
      "WILAYAH": "JAWA BARAT",
      "NAMA BENGKEL": "Paint Auto Repair",
      "ALAMAT": "Jalan Raya KSU Serab No. 1 Depok",
      "TELEPHONE": "021 - 778 35421",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310870"
    },
    {
      "WILAYAH": "JAWA BARAT",
      "NAMA BENGKEL": "PT. Ambara Karya Pradana",
      "ALAMAT": "Jl. Raya Cinangka No.9, Sarua, Sawangan, Bojongsari - Depok",
      "TELEPHONE": "021-30498889 / 30499990",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310871"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "TR. MOTOR",
      "ALAMAT": "Jl. Imam Bonjol No. 96- Jember",
      "TELEPHONE": "(0331) 338969",
      "EMAIL": "-",
      "PIC": "Bpk. Ainul Yusron",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6337"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "CV. FOCUZZ BODY REPAIR",
      "ALAMAT": "JL. YOS SUDARSO NO.56 SUKOWIDI, BANYUWANGI, 68421",
      "TELEPHONE": "(0333) 424710",
      "EMAIL": "focuzzbodyrepair@yahoo.co.id",
      "PIC": "BP. EDY PURWANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9242"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "CHECK POINT",
      "ALAMAT": "JL. MH. THAMRIN NO. 166 JEMBER, 68175",
      "TELEPHONE": 331333405,
      "EMAIL": "check_point166@yahoo.com",
      "PIC": "ICHDANIA KHOIRANI / ACH. F. RIZA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9243"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "TUNAS REMAJA MOTOR(TR MOTOR)",
      "ALAMAT": "JL. IMAM BONJOL NO. 96 JEMBER, 68132",
      "TELEPHONE": 3314355305,
      "EMAIL": "tr.motor@yahoo.co.id",
      "PIC": "ARI / LIA YUDHA P.",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9244"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "INDO SENTOSA TRADA (NISSAN JEMBER)",
      "ALAMAT": "JL. HAYAM WURUK NO. 181-183, MANGLI, KALIWATES, JEMBER",
      "TELEPHONE": 331412828,
      "EMAIL": "oky.susanto@nissan.indomobil.co.id / muchammad.sasmiko@nissan.indomobil.co.id",
      "PIC": "OKY / SASMIKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9245"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "PT. WAHANA WIRAWAN (NISSAN DATSUN BANYUWANGI)",
      "ALAMAT": "JL. S. PARMAN NO.147, SUMBERREJO, BANYUWANGI",
      "TELEPHONE": "0333-4460222/4466030",
      "EMAIL": "miftahul.huda@nissan.indomobil.co.id",
      "PIC": "HUDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9246"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "TOP MOBIL JEMBER",
      "ALAMAT": "JL.IMAM BONJOL NO 18",
      "TELEPHONE": "0331-339599",
      "EMAIL": "topmbl_jember@yahoo.co.id",
      "PIC": "IBU VIVI/BPK.ROSID",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9247"
    },
    {
      "WILAYAH": "JEMBER",
      "NAMA BENGKEL": "PT. ASTRA INTERNATIONAL TBK, TSO AUTO 2000 BANGSALSARI JEMBER",
      "ALAMAT": "JL. LUMAJANG JEMBER, DESA KRAJAN, KEL. LANGKAP KEC. BANGSALSARI, JEMBER",
      "TELEPHONE": "0331-7502000",
      "EMAIL": "khaeri.mustofa@tso.astra.co.id;roidul.imad@tso.astra.co.id",
      "PIC": "KHAERI MUSTOFA; ROIDUL IMAD",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9248"
    },
    {
      "WILAYAH": "Jember",
      "NAMA BENGKEL": "Autograha",
      "ALAMAT": "Jl. Letjen Panjaitan No. 151",
      "TELEPHONE": "T: 0812-31691730, 0823-34861826",
      "EMAIL": "autograha11@gmail.com",
      "PIC": "Rudy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3401"
    },
    {
      "WILAYAH": "Jember",
      "NAMA BENGKEL": "Auto 2000  - Jember",
      "ALAMAT": "Jl. Hayam Wuruk 34, Jember",
      "TELEPHONE": "T : 0331 - 422000 ; F : 0331-487284",
      "EMAIL": "ria.suryani@tso.astra.co.id",
      "PIC": "Novi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3402"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "HONDA ANUGRAH",
      "ALAMAT": "JL. JANTI NO. 91 KARANGJAMBE YOGYAKARTA",
      "TELEPHONE": "0274-488508",
      "EMAIL": "service.anugerah.bp@gmail.com",
      "PIC": "IBU ROOS MARYATI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9249"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "GRANDSTAR",
      "ALAMAT": "JL. PALAGAN TENTARA PELAJAR KM 7.8 YOGYAKARTA",
      "TELEPHONE": "0274-867766",
      "EMAIL": "grandstar_yk@yahoo.com",
      "PIC": "BP. JUNED",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9250"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "GETAS INDAH MOTOR",
      "ALAMAT": "JL. CEBONGAN MIATI SLEMAN DIY",
      "TELEPHONE": "0274-865202",
      "EMAIL": "gtbodyrepair@gmail.com",
      "PIC": "BP. A LEGOWO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9251"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "ATLANTA",
      "ALAMAT": "JL. KEBON AGUNG NO.08 MLATI, SLEMAN, JOGJAKARTA",
      "TELEPHONE": "0274-4530453",
      "EMAIL": "atlanta_jogjakarta@yahoo.com",
      "PIC": "BP. ANTON/SUPAD/ANDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9252"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "NASMOCO MAGELANG",
      "ALAMAT": "JL. RAYA MAGELANG KM. 5 MERTOYUDAN, MAGELANG 56172",
      "TELEPHONE": "0293-326871",
      "EMAIL": "budiaji_nasmoco@ymail.com",
      "PIC": "BP. SYAFEI/BP TRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9253"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "NASMOCO BANTUL",
      "ALAMAT": "JL. RING ROAD SELATAN, JADAN TAMAN TIRTO KASIHAN BANTUL YOGYAKARTA",
      "TELEPHONE": "0274-4469567",
      "EMAIL": "bp.bantul@yahoo.co.id",
      "PIC": "BP. TAUFIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9254"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "JOGJA DAB",
      "ALAMAT": "JL. KALIURANG KM. 16,KLEDOKAN,SLEMAN,YOGYAKARTA",
      "TELEPHONE": "0274-4547049",
      "EMAIL": "Djogdja_dab@yahoo.com",
      "PIC": "BP. FUAD/BP. SIGIT",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9255"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "HONDA PERKASA KLATEN",
      "ALAMAT": "JL. RAYA YOGYA- SOLO TANGKISAN POS JOGONALAN KLATEN",
      "TELEPHONE": "0272-3391655",
      "EMAIL": "",
      "PIC": "BP. ARIEF ANDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9256"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "AYU MANDIRA",
      "ALAMAT": "JL. YOGYA-MAGELANG KM 23 TEGALSARI,JUMOYO,SALAM,MAGELANG",
      "TELEPHONE": "0293-3286969",
      "EMAIL": "Ayumandira@yahoo.com",
      "PIC": "BP ROZIQ",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9257"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "AUTO MOBILINDO",
      "ALAMAT": "JL. KABUPATEN KM.3 RT.05/RW.14, MAYANGAN SLEMAN",
      "TELEPHONE": "0274-6412968",
      "EMAIL": "tira.automo@gmail.com",
      "PIC": "IBU TIRA/BP JOKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9258"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "HONDA TUGU YOGYAKARTA",
      "ALAMAT": "JL. HOS COKRO AMINOTO NO. 157 A, YOGYAKARTA",
      "TELEPHONE": "0274-620408",
      "EMAIL": "sriutami7675@yahoo.com",
      "PIC": "IBU UTAMI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9259"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "NEW ARMADA",
      "ALAMAT": "JL. SOEKARNO-HATTA, TIDAR SELATAN, MAGELANG SELATAN",
      "TELEPHONE": "0293-3276898",
      "EMAIL": "buditri.aimmgl@gmail.com",
      "PIC": "BP. BUDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9260"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "C MAESTRO YOGYAKARTA",
      "ALAMAT": "JL. IMOGIRI TIMUR KM.7 BANTUL YOGYAKARTA",
      "TELEPHONE": "0274-4396699",
      "EMAIL": "bengkel.cmaestro@gmail.com",
      "PIC": "BP. AMIR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9261"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "C MAESTRO MAGELANG",
      "ALAMAT": "JL.MAGELANG-SECANG KM.7(SEMPU/PAYAMAN) MAGELANG",
      "TELEPHONE": "0293-3217515",
      "EMAIL": "cmaestro.magelang@gmail.com",
      "PIC": "BP. CRISTIAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9262"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "BANDUNG BONDOWOSO",
      "ALAMAT": "JL. BULOG UKRIM NO.77 PURWOMARTANI, KALASAN SLEMAN YOGYA",
      "TELEPHONE": "0274-986623",
      "EMAIL": "admin.yk@bandungbondowoso.co.id",
      "PIC": "BP. RASITO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9263"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "GT AUTO",
      "ALAMAT": "JL.MAGELANG KM 7 NO.77, YOGYAKARTA",
      "TELEPHONE": "0274-868002",
      "EMAIL": "gt.auto.yogyakarta@gmail.com",
      "PIC": "BP. GATOT WIDJAJA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9264"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "ASTRA DAIHATSU YOGYAKARTA",
      "ALAMAT": "JL. RAYA MAGELANG KM. 7.2 DIY",
      "TELEPHONE": "0274-868075/4",
      "EMAIL": "",
      "PIC": "BP. AGA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9265"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "ASTRA ISUZU YOGYAKARTA",
      "ALAMAT": "JL. RINGROAD SELATAN NO. 100 GLUGO PANGGUNGHARJO SEWON BANTUL",
      "TELEPHONE": 274384124,
      "EMAIL": "suroyo@iso.astra.co.id",
      "PIC": "BP. SUROYO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9266"
    },
    {
      "WILAYAH": "JOGJA",
      "NAMA BENGKEL": "PT MANDIRI ZIRANG UTAMA (PENUNJUKAN)",
      "ALAMAT": "JL JENDRAL SUDIRMAN NO 182-184A SEMARANG",
      "TELEPHONE": 62247601243,
      "EMAIL": "mzu.rudi2708@gmail.com",
      "PIC": "BP RUDI/BP TIO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9267"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "SINAR SURYA MOTOR",
      "ALAMAT": "Jl.Akses Tol Karawang Barat",
      "TELEPHONE": "(0267) 8457554, 8457557",
      "EMAIL": "-",
      "PIC": "Bp. Herman / Bpk.Yusman",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6338"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "HONDA KUMALA",
      "ALAMAT": "JL. ARTERI INTERCHANGE JL. AKSES TOL KARAWANG BARAT NO. 207., SUKAMAKMUR, KEC. TELUKJAMBE TIM., KABUPATEN KARAWANG, JAWA BARAT 41361",
      "TELEPHONE": "0267 - 8453388",
      "EMAIL": "bp.kumala@gmail.com",
      "PIC": "BPK.  MUSLIH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9268"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "MITRA MANDIRI MAKMUR",
      "ALAMAT": "JL. JENEBIN NO 9 RT 10 RW 05 PURWADANA KEC. TELUKJAMBE TIMUR KARAWANG",
      "TELEPHONE": "0267 - 8450729",
      "EMAIL": "m3.painting@yahoo.co.id",
      "PIC": "BPK. ANDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9269"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "AUTO 2000 KARAWANG",
      "ALAMAT": "JL. SUROTOKUNTO NO. 80 RAWAGABUS KARAWANG",
      "TELEPHONE": "0267 - 8452000",
      "EMAIL": "ikin.sadikin@tso.astra.co.id",
      "PIC": "BPK IKIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9270"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "ZEBE TOKOROZAWA",
      "ALAMAT": "JL. IRIGASI NO. 123 CISEREUH PURWAKARTA",
      "TELEPHONE": "0264 - 8623571",
      "EMAIL": "tokorozawa_motor@yahoo.co.id",
      "PIC": "BPK. IWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9271"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "PT. HARTA ADHI PRAMATA (NEW EMERALD)",
      "ALAMAT": "JL. RAYA BUNGUR SARI DS. CIBEUNING NO. 149, PURWAKARTA",
      "TELEPHONE": "0264-205083",
      "EMAIL": "emeraldautocare.pwk@gmail.com",
      "PIC": "BPK LILI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9272"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "CEMERLANG TULUS PRATAMA",
      "ALAMAT": "JL. RAYA KOSAMBI. DESA PANCAWATI, DUSUN SATU KE. KLARI , KARAWANG - JAWA BARAT 41371",
      "TELEPHONE": 87822834001,
      "EMAIL": "ctp.bodyrepair@gmail.com",
      "PIC": "BPK TULUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9273"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "KAROTO SEIKO INDONESIA",
      "ALAMAT": "JL. WIRASABA NO. 5 ADIARSA BARAT, KARAWANG BARAT",
      "TELEPHONE": "0267-400055",
      "EMAIL": "karoto.indonesia@gmail.com",
      "PIC": "BPK DENI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9274"
    },
    {
      "WILAYAH": "Karawang",
      "NAMA BENGKEL": "Sinar Surya Motor",
      "ALAMAT": "Jl. Raya Akses Tol Karawang Barat",
      "TELEPHONE": "T : 0267-8457110 / 8457554",
      "EMAIL": "sinarsuryamotor@cbn.net.id",
      "PIC": "Emely",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3403"
    },
    {
      "WILAYAH": "Karawang",
      "NAMA BENGKEL": "Artha Gemilang Motor",
      "ALAMAT": "Jl. Suroto kunto No. 209 Rawagabus Post, Adiarsa Timur, Karawang Timur",
      "TELEPHONE": "T: 0267-8633772",
      "EMAIL": "agm.karawangmtr@gmail.com�",
      "PIC": "Alfian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3404"
    },
    {
      "WILAYAH": "Karawang",
      "NAMA BENGKEL": "Auto 2000 Karawang",
      "ALAMAT": "Jl. Surokunto No.  80, Karawang 41313",
      "TELEPHONE": "T : 0267 - 8452000 ; F : 0267 - 402570",
      "EMAIL": "rizal.faturohman@tso.astra.co.id",
      "PIC": "Charly",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3405"
    },
    {
      "WILAYAH": "KARAWANG",
      "NAMA BENGKEL": "Sinar Surya Karawang",
      "ALAMAT": "Jl. Akses Tol Karawang Barat",
      "TELEPHONE": "0267-8457554",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310872"
    },
    {
      "WILAYAH": "Kebumen",
      "NAMA BENGKEL": "Bumen Redja Abadi - Kebumen",
      "ALAMAT": "Jl. Tentara Pelajar No. 94",
      "TELEPHONE": "T: 0287-381222",
      "EMAIL": "sugeng.riyanto@bra.nag.co.id",
      "PIC": "Corry",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3406"
    },
    {
      "WILAYAH": "Kediri",
      "NAMA BENGKEL": "Surya Indah",
      "ALAMAT": "Jl. Raya Jong Biru No. 286 , Kediri",
      "TELEPHONE": "T : 0354 - 4526107;081216669726",
      "EMAIL": "suryaindahkediri@gmail.com",
      "PIC": "Hardyan / Rico",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3407"
    },
    {
      "WILAYAH": "Kediri",
      "NAMA BENGKEL": "Absolute",
      "ALAMAT": "Jl. Urip Sumoharjo No. 138 A",
      "TELEPHONE": "T : 0354- 685294, F: 0354-695978",
      "EMAIL": "absolute_kediri1@yahoo.com",
      "PIC": "Reza/Misnah Simanjuntak",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3408"
    },
    {
      "WILAYAH": "Kediri",
      "NAMA BENGKEL": "Auto 2000 Kediri",
      "ALAMAT": "Jl. Sersan Suharmadji No 198",
      "TELEPHONE": "T : 0354 - 442000�",
      "EMAIL": "julfyanty.wulansari@tso.astra.co.id",
      "PIC": "effendy Wijaya / andy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3409"
    },
    {
      "WILAYAH": "Klaten",
      "NAMA BENGKEL": "Nasmoco - Klaten",
      "ALAMAT": "Jl. Raya Solo - Yogyakarta No.29-28, Belangwetan, Belang Wetan, Kec. Klaten Utara",
      "TELEPHONE": "T : 0272 - 3359595",
      "EMAIL": "estimator.bamsolo@gmail.com",
      "PIC": "Hadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3410"
    },
    {
      "WILAYAH": "Kudus",
      "NAMA BENGKEL": "CV. Surya Indah Motor",
      "ALAMAT": "Jl. Raya Kudus - Pati KM. 4 , Ngembal Kulon , Jati - Kudus",
      "TELEPHONE": "T : 0291 - 446484 ; F : 0291 - 446484",
      "EMAIL": "mkmuna@suryaindahmotor.com",
      "PIC": "Dedi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3411"
    },
    {
      "WILAYAH": "KUPANG",
      "NAMA BENGKEL": "UD PALU GADA",
      "ALAMAT": "JL. KEJORA NO 7 KEL. OEBUFU KEC. OEBOBO",
      "TELEPHONE": "0821-4200-9000",
      "EMAIL": "palugadamotorkupang@gmail.com",
      "PIC": "BPK. SULIS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9275"
    },
    {
      "WILAYAH": "KUPANG",
      "NAMA BENGKEL": "NARDI MOTOR",
      "ALAMAT": "JL. JOHAR NO 7 KUANINO KUPANG",
      "TELEPHONE": "0813-3898-6579",
      "EMAIL": "bengkelnardikupang@gmail.com",
      "PIC": "BPK. SUNARDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9276"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "DAMAI INTI MOTOR",
      "ALAMAT": "Jl. Ratu Lengkara No.2 Kedamaian",
      "TELEPHONE": "(0721) 266223",
      "EMAIL": "-",
      "PIC": "Bapak Dedy",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6339"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "CV. CHAMPION BODY REPAIR",
      "ALAMAT": "Jl.Kayu Manis No.55",
      "TELEPHONE": "(0721) 701703",
      "EMAIL": "-",
      "PIC": "Ibu Susi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6340"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "BENGKEL AUTO CLINK",
      "ALAMAT": "Jl.Muhamad Salim No.8",
      "TELEPHONE": "(0721) 33 441",
      "EMAIL": "-",
      "PIC": "Ibu Ani",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6341"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "BENGKEL CENTRAL",
      "ALAMAT": "Jl.Urip Sumoharjo No.17/145",
      "TELEPHONE": "(0721) 709000",
      "EMAIL": "-",
      "PIC": "Bpk.Edy",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6342"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "BENGKEL NEW ABC",
      "ALAMAT": "Jl. Yos Sudarso No. 170",
      "TELEPHONE": "(0721) 482647",
      "EMAIL": "-",
      "PIC": "Bp. Mahar",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6343"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "NEW ABC",
      "ALAMAT": "JL. YOS SUDARSO NO..170 BANDAR LAMPUNG",
      "TELEPHONE": "0721 - 482647",
      "EMAIL": "newabc_lampung@yahoo.co.id",
      "PIC": "BP. MAHAR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9277"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "AUTOCLINK",
      "ALAMAT": "JL. MOH SALIM NO 8 WAY LUNIK BANDAR LAMPUNG",
      "TELEPHONE": "0721 - 33441",
      "EMAIL": "auto.clink@yahoo.co.id",
      "PIC": "IBU. ANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9278"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "AUTO DESIGN",
      "ALAMAT": "JL. HAYAM WURUK NO.46A KEDAMAIYAN BANDAR LAMPUNG",
      "TELEPHONE": "0851 0614 3699",
      "EMAIL": "autodesignlpg@yahoo.co.id",
      "PIC": "IBU. SUGI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9279"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "CHAMPION",
      "ALAMAT": "JL. KAYU MANIS WAYHALIM BANDAR LAMPUNG",
      "TELEPHONE": "0721 - 701703",
      "EMAIL": "champion_bodyrepair@yahoo.co.id",
      "PIC": "BP. FITO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9280"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "PT. PERSADA LAMPUNG RAYA",
      "ALAMAT": "JL. RAYA SERBAJADI DESA PEMANGILAN",
      "TELEPHONE": "0721 - 787884",
      "EMAIL": "plr_kwu@yahoo.co.id",
      "PIC": "BP. GUNADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9281"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "MONTECARLO",
      "ALAMAT": "JL. SULTAN AGUNG NO.21 BANDAR LAMPUNG",
      "TELEPHONE": "0721 - 770475",
      "EMAIL": "montecarlo.bodyrepair@yahoo.com",
      "PIC": "BP. SUPRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9282"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "OTO",
      "ALAMAT": "JL. JL YOS SUDARSO SUKARAJA",
      "TELEPHONE": "0721 - 475311",
      "EMAIL": "229bengkelot@gmail.com",
      "PIC": "BP. HERI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9283"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "CENTRAL",
      "ALAMAT": "JL. URIP SUMOHARJO",
      "TELEPHONE": "0721 - 709000",
      "EMAIL": "centralbp_lpg@yahoo.co.id",
      "PIC": "IBU. SITI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9284"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "MANDIRI",
      "ALAMAT": "JL. LETJEND RYACUDU NO. 8 SUKARAME",
      "TELEPHONE": "0721-773064",
      "EMAIL": "bengkel_mandiri@yahoo.co.id",
      "PIC": "BP. KUSNADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9285"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "HONDA",
      "ALAMAT": "JL. RADEN INTAN NO. 168 BANDAR LAMPUNG",
      "TELEPHONE": "0721 - 255000",
      "EMAIL": "bodyrepair@hondalampung.com",
      "PIC": "BP. NOPRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9286"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "BUDI BERLIAN MOTOR",
      "ALAMAT": "JL RAYA NATAR LAMPUNG SELATAN",
      "TELEPHONE": "0721-709999",
      "EMAIL": "oktamahkota53@gmail.com",
      "PIC": "OKTA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9287"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "LAUTAN BERLIAN",
      "ALAMAT": "JL MOH SALIM NO.8 WAY LUNIK  BANDAR LAMPUNG",
      "TELEPHONE": "0721-33341",
      "EMAIL": "bl.lbum@gmail.com",
      "PIC": "RIFAI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9288"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "HONDA ARISTA",
      "ALAMAT": "JL RAYA HAJI MENA NO.99 NATAR LAMPUNG SELATAN",
      "TELEPHONE": "0721-8013800",
      "EMAIL": "ekokeo.honda@gmail.com",
      "PIC": "EKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9289"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "TRITUNGGAL",
      "ALAMAT": "JL. SULTAN AGUNG NO.17 B WAY HALIM BANDAR LAMPUNG",
      "TELEPHONE": "0721-712188",
      "EMAIL": "sa.tritunggalbodyrepair@gmail.com",
      "PIC": "FITRIONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9290"
    },
    {
      "WILAYAH": "Lampung",
      "NAMA BENGKEL": "New ABC (Abadi Bangun Cemerlang)",
      "ALAMAT": "Jl. Yos Sudarso No.170, Sukaraja, Bumi Waras",
      "TELEPHONE": "T:  0721-482647, F: 0721-484467",
      "EMAIL": "newabc_lampung@yahoo.co.id",
      "PIC": "Farida/Bpk. Syaiful",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3412"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "Auto Clink",
      "ALAMAT": "Jl. KH Mochammad Salim No.8, Way Lunik, Telukbetung Selatan, Bandar Lampung City, Lampung 35244",
      "TELEPHONE": "(0721) 33440",
      "EMAIL": "",
      "PIC": "Ani (08127221659)",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310873"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "Honda Arista Rajabasa",
      "ALAMAT": "Jl. Raya Hajimena No. 99, Natar, Lampung Selatan, Hajimena, Kec. Natar, Lampung, 35145",
      "TELEPHONE": "(0721) 8013800",
      "EMAIL": "",
      "PIC": "Slamet (08119590313)",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310874"
    },
    {
      "WILAYAH": "LAMPUNG",
      "NAMA BENGKEL": "Honda Istana Lampung Raya",
      "ALAMAT": "Jl. Raden Intan No. 168 Bandar Lampung, Lampung 35117",
      "TELEPHONE": "(0721) 255000",
      "EMAIL": "",
      "PIC": "Nopri (081282721930)",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310875"
    },
    {
      "WILAYAH": "Madiun",
      "NAMA BENGKEL": "Surya Indah Motor",
      "ALAMAT": "Jl. Bali No. 113",
      "TELEPHONE": "T: 0351-497305, 0351-491641, 0351-7816125",
      "EMAIL": "suryaindahmadiun@gmail.com",
      "PIC": "Bp Salam (MKT Corporate)\nBp Tengku Said/Kepala Bengkel \nService Advisor : Boy/Rikki",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3413"
    },
    {
      "WILAYAH": "MAGELANG",
      "NAMA BENGKEL": "NASMOCO",
      "ALAMAT": "Jl.Raya Magelang-Yogya KM,5",
      "TELEPHONE": "(0293) 326871",
      "EMAIL": "-",
      "PIC": "Bpk.Arief K / Windarto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6344"
    },
    {
      "WILAYAH": "MAGELANG",
      "NAMA BENGKEL": "CV. TUNAS JAYA",
      "ALAMAT": "Jl. Soekarno Hatta",
      "TELEPHONE": "-",
      "EMAIL": "-",
      "PIC": "Bpk. Widhi Hastanto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6345"
    },
    {
      "WILAYAH": "Magelang",
      "NAMA BENGKEL": "Nasmoco  Magelang",
      "ALAMAT": "Jl. Raya Magelang-Yogya KM. 5, Mertoyudan, Danurejo, Mertoyudan, Magelang",
      "TELEPHONE": "T : 0293 -  325885",
      "EMAIL": "syafei.toyota@yahoo.co.id; budiaji_nasmoco@ymail.com",
      "PIC": "Muslim",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3414"
    },
    {
      "WILAYAH": "Magelang",
      "NAMA BENGKEL": "Bumen Redja Abadi - Magelang",
      "ALAMAT": "Jl. Mayjen Bambang Soegeng No. 7",
      "TELEPHONE": "T: 0293-364371",
      "EMAIL": "hendy.kristanto@bra.nag.co.id",
      "PIC": "Fardian / Nanda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3415"
    },
    {
      "WILAYAH": "MAGELANG",
      "NAMA BENGKEL": "AYU MANDIRA",
      "ALAMAT": "JL. RAYA YOGYA-MAGELANG KM 25, DADEAN, GULON, SALAM, MAGELANG",
      "TELEPHONE": "0293.3286969/79",
      "EMAIL": "",
      "PIC": "Ibu Tri / Ibu Dian",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310876"
    },
    {
      "WILAYAH": "MAGELANG",
      "NAMA BENGKEL": "ARMADA INTERNATIONAL MOTOR",
      "ALAMAT": "JL. SOEKARNO HATTA, MAGELANG",
      "TELEPHONE": "2.935.503.382",
      "EMAIL": "",
      "PIC": "Bpk. Budi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310877"
    },
    {
      "WILAYAH": "MAGELANG",
      "NAMA BENGKEL": "PT. BUMEN REDJA ABADI, Magelang",
      "ALAMAT": "JL MAYJEN BAMBANG SOEGENG NO 7",
      "TELEPHONE": "0293-364371",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310878"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Borong Jaya Motor (BJM)",
      "ALAMAT": "Jl. Borong Raya No. 18",
      "TELEPHONE": "T: 0411-4663623",
      "EMAIL": "borongjayamotor18@gmail.com",
      "PIC": "Danny / Dodi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3416"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Faisal",
      "ALAMAT": "Jl. Hasanudin No. 185 Gowa",
      "TELEPHONE": "T: 0411-860208, 0813-55699996",
      "EMAIL": "faisal.auto.service@gmail.com",
      "PIC": "Ajids / Nopi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3417"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Giswa Mandiri",
      "ALAMAT": "Jl. Abu Bakar Lambogo No. 7",
      "TELEPHONE": "T: 0411-426377, 0812-4224399",
      "EMAIL": "giswa.mandiri@yahoo.com",
      "PIC": "Ajids / Nopi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3418"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Helios Auto Service Station",
      "ALAMAT": "Jl. Toddopuli Raya Timur No. 84",
      "TELEPHONE": "T: 0411-444017, 0853-42499495, F: 0411-4664052",
      "EMAIL": "bengkelhelios@yahoo.co.id",
      "PIC": "Ranti / Hendri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3419"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Juanda Auto Raya",
      "ALAMAT": "Jl. A. Tonro, Kumala II Selatan No. 70",
      "TELEPHONE": "T: 0411-857069, F: 0411-857397",
      "EMAIL": "juandaautoraya@gmail.com; keu.jar@gmail.com",
      "PIC": "Efri / Icha",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3420"
    },
    {
      "WILAYAH": "Makasar",
      "NAMA BENGKEL": "Mulia Mandiri Mobil",
      "ALAMAT": "Jl. Sunu No. 166",
      "TELEPHONE": "T: 0411-434999, 0411-454283, F: 0411-452447",
      "EMAIL": "muliamandirimobil@yahoo.co.id",
      "PIC": "Meri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3421"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "GISWA MOTOR",
      "ALAMAT": "Jl. Abd. Dg. Sirua I No. 3",
      "TELEPHONE": "(0411)  420535",
      "EMAIL": "-",
      "PIC": "Bp. Budi Haryono/ Bp. Anwar",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6346"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. BINTANG AUTO CAT",
      "ALAMAT": "Jl. Abdul Kadir No. 15",
      "TELEPHONE": "(0411)  862821",
      "EMAIL": "-",
      "PIC": "Bp. Alfred Ismail / Haris",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6347"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "OMEGA MOTOR",
      "ALAMAT": "Jl. Hertasning Raya No. 52 B",
      "TELEPHONE": "(0411)  880333",
      "EMAIL": "-",
      "PIC": "Bp.  Chiwank/Bp. Ricco",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6348"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "MULIA MANDIRI MOBIL",
      "ALAMAT": "Jl. Sunu No. 166",
      "TELEPHONE": "(0411) 434999",
      "EMAIL": "-",
      "PIC": "Bp. Andy Ligianto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6349"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. STADIA REKA",
      "ALAMAT": "Jl. Sungai Saddang Baru no. 12",
      "TELEPHONE": "(0411) 455246",
      "EMAIL": "-",
      "PIC": "Bp. Aso",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6350"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "ADIPURA CAR SERVICE ST.",
      "ALAMAT": "Jl.Adipura No.39",
      "TELEPHONE": "(0411) 442885",
      "EMAIL": "-",
      "PIC": "Ibu Tina",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6351"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. ASTRA INTERNASIONAL TBK - DAIHATSU",
      "ALAMAT": "JL. URIP SUMIHARJO NO. 64 MAKASSAR",
      "TELEPHONE": "0411-449911",
      "EMAIL": "m.helmy@dso.astra.co.id",
      "PIC": "KAHONO/SAHAR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9291"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. HONDA MAKASSAR INDAH",
      "ALAMAT": "JL. ARIEF RAHMAN HAKIM NO. 125 MAKASSAR",
      "TELEPHONE": "0411-4666126",
      "EMAIL": "bengkelmim@yahoo.com",
      "PIC": "BPK. EDI / EMIL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9292"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. REMAJA JAYA MOBILINDO= > MASIH DALAM PROSES PKS",
      "ALAMAT": "JL. URIP SUMIHARJO KM. 6 MAKASSAR",
      "TELEPHONE": "0411-442328",
      "EMAIL": "bphondarjm@gmail.com",
      "PIC": "LAWA/HERU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9293"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. HONDA SANGGAR LAUT SELATAN",
      "ALAMAT": "JL. TENTARA PELAJAR NO. 37 - 43 MAKASSAR",
      "TELEPHONE": "0411-3610462",
      "EMAIL": "klaim.sanggarlautselatan@gmail.com",
      "PIC": "IBU JANE / IRFAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9294"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. SINAR LIMA SAMUDRA ( HONDA SANGGAR LAUT SELATAN 2 )",
      "ALAMAT": "JL. GATOT SUBROTO BARU NO. 57 MAKASSAR",
      "TELEPHONE": "0411-8943888",
      "EMAIL": "klaim.hondasanggarlautselatan2@gmail.com",
      "PIC": "IBU RINI / PIAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9295"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. SUZUKI MEGAHPUTRA SEJAHTERA",
      "ALAMAT": "JL. GUNUNG LATIMOJONG NO. 96 MAKASSAR",
      "TELEPHONE": "0411-3623374",
      "EMAIL": "service_suzukilatimojong@yahoo.co.id",
      "PIC": "IBU NENG / JUMARDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9296"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. WAHANA MEGAHPUTRA - NISSAN LATIMOJONG",
      "ALAMAT": "JL. GUNUNG LATIMOJONG NO. 81 MAKASSAR",
      "TELEPHONE": "0411-5023104",
      "EMAIL": "wiwin.ardian@nissan.indomobil.co.id",
      "PIC": "WIWIN/SUPRIADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9297"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. WAHANA MEGAHPUTRA - NISSAN PETTARANI",
      "ALAMAT": "JL. A.P PETTARANI  MAKASSAR",
      "TELEPHONE": "0411-425777",
      "EMAIL": "arga.pamungkas@nissan.indomobil.co.id",
      "PIC": "SUDIRMAN / ARGA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9298"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. VISITAMA PUTRA RAJA",
      "ALAMAT": "JL. KUMALA 2 NO. 70 MAKASSAR",
      "TELEPHONE": "0411-857069",
      "EMAIL": "juandaautoraya@gmail.com",
      "PIC": "HASMA / SAMBO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9299"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. GISWA MANDIRI",
      "ALAMAT": "JL. ABU BAKAR LAMBOGO NO. 7 MAKASSAR",
      "TELEPHONE": "0411-426377",
      "EMAIL": "giswa.mandiri@yahoo.com",
      "PIC": "IDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9300"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. FAISAL MOTOR",
      "ALAMAT": "JL. SULTAN HASANUDDIN SUNGGUMINASA GOWA",
      "TELEPHONE": "0411-860208",
      "EMAIL": "faisal.auto.service@gmail.com",
      "PIC": "YANTI / NINGSIH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9301"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. BINTANG AUTO CAT",
      "ALAMAT": "JL. ABDUL KADIR MAKASSAR",
      "TELEPHONE": "0411-862821",
      "EMAIL": "bintangautocat@yahoo.co.id",
      "PIC": "NINING",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9302"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. MUSTIKA RAYA",
      "ALAMAT": "JL. TEUKU UMAR 13 MAKASSAR",
      "TELEPHONE": "0411-430011",
      "EMAIL": "mustikaraya_mks@yahoo.com",
      "PIC": "VERA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9303"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. WAHYU MOTOR=",
      "ALAMAT": "JL. TUNGGALA DALAM WUA-WUA KENDARI",
      "TELEPHONE": "0401-3138532",
      "EMAIL": "kdiwahyu@gmail.com",
      "PIC": "WAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9304"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. SUZUKI MEGHAPUTRA KENDARI",
      "ALAMAT": "JL. LA ODE HADI / BY PASS, BONGGOEYA KEC. WUA-WUA KENDARI",
      "TELEPHONE": 82346481522,
      "EMAIL": "fadhillahzaldy06@gmail.com",
      "PIC": "DWI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9305"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. KARYA JAYA=",
      "ALAMAT": "JL. INDUSTRI KECIL NO. 17 PARE-PARE",
      "TELEPHONE": "0421-2915088",
      "EMAIL": "karyajaya.autopaint@gmail.com",
      "PIC": "EFENDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9306"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. ANUGRAH",
      "ALAMAT": "JL. KIJANG RAYA NO. 41 PALU",
      "TELEPHONE": "0822-4564-444",
      "EMAIL": "anugrahbengkelpalu@gmail.com",
      "PIC": "BENYAMIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9307"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. RAHMAT JAYA=",
      "ALAMAT": "JL. MALEO NO. 94 PALU",
      "TELEPHONE": "0813-4104-0467",
      "EMAIL": "newveteranpalu@gmail.com",
      "PIC": "YOSEP PAKAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9308"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. SINAR KARYA",
      "ALAMAT": "JL. SUNGAI SA'DAN LR. II NO. 07 PALU",
      "TELEPHONE": "0851-0070-9709",
      "EMAIL": "sinar_kry@yahoo.com",
      "PIC": "BARAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9309"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. WAHANA MEGAHPUTRA - NISSAN PALU",
      "ALAMAT": "JL. MOH. YAMIN NO. 85 PALU",
      "TELEPHONE": "0821-9197-1410",
      "EMAIL": "jumardi@nissan.indomobil.co.id",
      "PIC": "AAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9310"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. HARDY MANDIRI MAMUJU",
      "ALAMAT": "JL. ABDUL SYUKUR MAMUJU, SUL- BAR",
      "TELEPHONE": 82292828079,
      "EMAIL": "bengkel.hardypalopo@gmail.com",
      "PIC": "PRESDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9311"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. HARDY MANDIRI PALOPO",
      "ALAMAT": "JL. RATULANGI KM 12 PADANG LIPAN PALOPO",
      "TELEPHONE": 85340656847,
      "EMAIL": "hardy.riman@gmail.com",
      "PIC": "RUDI PATIKU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9312"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. SURYA KENCANA=",
      "ALAMAT": "JL. S. MARUNI KM 10 SORONG  PAPUA",
      "TELEPHONE": "0951-3170260",
      "EMAIL": "gun.berkarya@gmail.com",
      "PIC": "GUNTUR WIBISONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9313"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "CV. MOLINDO INTI PERKASA",
      "ALAMAT": "JL. GATOT SUBROTO BARU NO 51",
      "TELEPHONE": "0411-4666061",
      "EMAIL": "'molindo.inti.perkasa@gmail.com'",
      "PIC": "HERLINA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9314"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Bosowa Berlian Motor (Authorized Mitsubishi)",
      "ALAMAT": "Jl. Perintis Kemerdekaan",
      "TELEPHONE": "T:  0411-4772722 ; 085242244022",
      "EMAIL": "bbm.bodycat.mks@bosowaberlian.com",
      "PIC": "Lia",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3422"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Astra International - BMW",
      "ALAMAT": "Jl. Andi Pangeran Pettarani No. 98 A, Makassar",
      "TELEPHONE": "T : 0411 - 4669495; 0411 - 5403132",
      "EMAIL": "freddy@bmw.astra.co.id",
      "PIC": "Heri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3423"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "CV. Megahputra Perkasa (Nissan,Datsun, Suzuki)",
      "ALAMAT": "Jl. G. Latimojong No. 131, Makassar",
      "TELEPHONE": "T :   0411-3617327;087826488885;",
      "EMAIL": "megahputraperkasa@gmail.com",
      "PIC": "Weni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3424"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Honda Sanggar Laut Selatan",
      "ALAMAT": "Jl. Tentara Pelajar No. 37 - 434 Makassar / 00002883",
      "TELEPHONE": "T : 0411-3610462",
      "EMAIL": "daudnurkil_sls@yahoo.com",
      "PIC": "Sandi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3425"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Makassar Indah Motor (Honda Makassar)",
      "ALAMAT": "Jl. Arif Rahman Hakim 125",
      "TELEPHONE": "T : 0411 - 4666126",
      "EMAIL": "edi.nur@hondaindah.co.id",
      "PIC": "Arif",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3426"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Astra Daihatsu Makassar",
      "ALAMAT": "Jl. Sultan Alauddin No. 242 - 244, Kel Mangasa, Kec Tamalate, Makassar",
      "TELEPHONE": "T : 0411-459992 ; 08111871186",
      "EMAIL": "dodik.priambodo@dso.astra.co.id ;kahono@dso.astra.co.id",
      "PIC": "Eka / Bpk. Anca",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3427"
    },
    {
      "WILAYAH": "Makassar",
      "NAMA BENGKEL": "Honda Remaja Jaya",
      "ALAMAT": "Jl. Urip Sumoharjo Km. 6 Tello Baru Panakkukang, Makassar",
      "TELEPHONE": "T : 0411-442328,  0411 � 467 7071",
      "EMAIL": "agussafwanrjm@gmail.com; \nbphondarjm@gmail.com;\nherru.saputro@remajajaya.co.id",
      "PIC": "Ricky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3428"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Faisal Rachman",
      "ALAMAT": "Jl. Sultan Hasanuddin No. 185, Gowa, Makassar",
      "TELEPHONE": "0411 - 860208",
      "EMAIL": "faisal.auto.service@gmail.com",
      "PIC": "Ningsih/Yanti",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310879"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Giswa Mandiri",
      "ALAMAT": "Jl. Abu Bakar Lambogo No. 7, Makassar",
      "TELEPHONE": "0411 - 426377",
      "EMAIL": "giswa.mandiri@yahoo.com",
      "PIC": "Muh. Anwar",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310880"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Juanda Autoraya",
      "ALAMAT": "Jl. Andi Tonro, Kumala II Selatan No. 70, Makassar",
      "TELEPHONE": "0411 - 857069",
      "EMAIL": "juandaautoraya@gmail.com",
      "PIC": "Hamzah",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310881"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PBM AUTO SERVICE",
      "ALAMAT": "JL. HM ARSYAD NO. 15 SOREANG PARE-PARE",
      "TELEPHONE": "0411-447109",
      "EMAIL": "pbmautoserviceparepare@gmail.com",
      "PIC": "Sri Rahayu",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310882"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Smile Autooservice",
      "ALAMAT": "Jl. Veteran Selatan No. 253",
      "TELEPHONE": "0411-870575",
      "EMAIL": "smileautoservice.co.id",
      "PIC": "Sohra",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310883"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Honda Sanggar Laut Selatan",
      "ALAMAT": "Jl. Tentara Pelajar No. 37 - 43, Makassar",
      "TELEPHONE": "0411 - 3610462",
      "EMAIL": "klaimsanggarlautselatan@gmail.com",
      "PIC": "Jane",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310884"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Mitsubishi Bosowa Berlian",
      "ALAMAT": "JL. URIP SUMOHARJO NO. 266",
      "TELEPHONE": "0411 - 444444",
      "EMAIL": "bbm.bodicat.mks@bosowaberlian.com",
      "PIC": "Nursal",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310885"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Honda Makassar Indah",
      "ALAMAT": "JL. ARIF RAHMAN HAKIM NO. 125",
      "TELEPHONE": "4666-126",
      "EMAIL": "bengkelmim@yahoo.com",
      "PIC": "Taufan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310886"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Honda Sannggar Laut Selatan 2",
      "ALAMAT": "JL. Gatot Subroto No. 57",
      "TELEPHONE": 8114619783,
      "EMAIL": "klaimsanggarlautselatan2@gmail.com",
      "PIC": "Daud",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310887"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "PT. Astra International, Tbk",
      "ALAMAT": "Jl. Urip Sumoharjo No. 64 Makassar",
      "TELEPHONE": "0411-449911",
      "EMAIL": "Muhammad.hamzah@dso.astra.co.id",
      "PIC": "Hamzah",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310888"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Honda Remaja jaya Makassar",
      "ALAMAT": "Jl. Urip Sumoharjo KM. 6 Makassar",
      "TELEPHONE": "0411-391597",
      "EMAIL": "bphondarjm@gmail.com / mbasri@remajajaya.co.id",
      "PIC": "Basri",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310889"
    },
    {
      "WILAYAH": "MAKASSAR",
      "NAMA BENGKEL": "Honda Internusa Makassar",
      "ALAMAT": "Jl. Metro Tanjung Bunga No. 1573 - 1575",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310890"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "NASIONAL MOTOR",
      "ALAMAT": "Jl.Tenaga No.5A",
      "TELEPHONE": "(0341) 491021",
      "EMAIL": "-",
      "PIC": "Bpk.Priyono",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6352"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "JM. PAINTING",
      "ALAMAT": "Jl.Panglima Sudirman 9B",
      "TELEPHONE": "(0341) 473726",
      "EMAIL": "-",
      "PIC": "-",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6353"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "TOP MOBIL",
      "ALAMAT": "JL.A.YANI 92-94",
      "TELEPHONE": "0341-486799",
      "EMAIL": "top.mobilmlg@yahoo.com",
      "PIC": "IBU.VIVI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9315"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "WAHANA WIRAWAN",
      "ALAMAT": "JL. S. PARMAN NO 53-55, BLIMBING, MALANG",
      "TELEPHONE": 85729225442,
      "EMAIL": "ismail.mlg@nissan.indomobil.co.id",
      "PIC": "ISMAIL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9316"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "KOKO",
      "ALAMAT": "JL. KI AGENG GRIBIG NO 18. LESANPURO KEDUNGKANDANG KOTA MALANG",
      "TELEPHONE": "(0341) 710116",
      "EMAIL": "bengkel.koko@yahoo.co.id",
      "PIC": "HANDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9317"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "HONDA SUKUM MALANG",
      "ALAMAT": "JL S. SUPRIADI NO 19 -22 MALANG",
      "TELEPHONE": 341363488,
      "EMAIL": "bodypaint@hondasukunmalang.co.id",
      "PIC": "IBU AYU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9318"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "WAHANA WIRAWAN DATSUN MALANG",
      "ALAMAT": "JL.  S. PARMAN 95",
      "TELEPHONE": "491101 (ext 103)",
      "EMAIL": "dodi.wanoko@datsun.indomobil.co.id",
      "PIC": "DODI WANOKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9319"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "PT. ASTRA INTERNATIONAL TBK. TSO AUTO2000",
      "ALAMAT": "JL. SUDANCO SUPRIYADI NO.35 KEC. SUKUN KOTA MALANG",
      "TELEPHONE": "0341-352000",
      "EMAIL": "eko.herysaputro@tso.astra.co.id",
      "PIC": "EKO HERY SAPUTRO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9320"
    },
    {
      "WILAYAH": "Malang",
      "NAMA BENGKEL": "Annico",
      "ALAMAT": "Jl. Tenaga Juanda VI/9",
      "TELEPHONE": "T: 0341-417818, F: 0341-417818",
      "EMAIL": "annico.mlg@gmail.com",
      "PIC": "Imam",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3429"
    },
    {
      "WILAYAH": "Malang",
      "NAMA BENGKEL": "Auto 99",
      "ALAMAT": "Jl. Soekarno Hatta No. 31",
      "TELEPHONE": "T: 0341-491099, F: 0341-495099",
      "EMAIL": "auto99plus@yahoo.com",
      "PIC": "Aprian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3430"
    },
    {
      "WILAYAH": "Malang",
      "NAMA BENGKEL": "Nasional Motor",
      "ALAMAT": "Jl. Panglima Sudirman No. 9 B - Malang",
      "TELEPHONE": "T: 0341 - 361280",
      "EMAIL": "nasional.mtr@gmail.com",
      "PIC": "Ade Ismail Wijaya",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3431"
    },
    {
      "WILAYAH": "Malang",
      "NAMA BENGKEL": "Top Mobil Malang",
      "ALAMAT": "Jl. Ahmad. Yani No.92-94, Purwodadi, Blimbing",
      "TELEPHONE": "T: 0341-486799, F: 0341-407123",
      "EMAIL": "top.mobilmlg@yahoo.com",
      "PIC": "Agung / Afriansyah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3432"
    },
    {
      "WILAYAH": "Malang",
      "NAMA BENGKEL": "Auto 2000 Malang",
      "ALAMAT": "Jl. S.Supriadi 35-37, Sukun, Malang",
      "TELEPHONE": "T : 0341 - 352000; F : 0341 - 335658",
      "EMAIL": "erfan.fanani@tso.astra.co.id",
      "PIC": "Rulli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3433"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Best Auto Paint",
      "ALAMAT": "Jl. Raya Sekarpuro 30 Malang",
      "TELEPHONE": "0341 - 3021158",
      "EMAIL": "bestautopaint88@gmail.com",
      "PIC": "Ibu Sri",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310891"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Nasional Motor",
      "ALAMAT": "Jl. Panglima Sudirman No.9B Malang",
      "TELEPHONE": "0341-361280",
      "EMAIL": "nasional.bodyrepair@gmail.com",
      "PIC": "Bpk. Pri / Ibu Ely",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310892"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Top Mobil Malang",
      "ALAMAT": "Jl. Ahmad Yani 92-94 Malang",
      "TELEPHONE": "0341-486799",
      "EMAIL": "topmobil.documen@yahoo.com",
      "PIC": "Bpk. Jono",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310893"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Top Mobil Probolinggo",
      "ALAMAT": "Jl. Brantas no. 248 Probolinggo",
      "TELEPHONE": "0335-425399",
      "EMAIL": "top.mblprob@yahoo.co.id",
      "PIC": "Bpk. Hendra / Bpk. Joko",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310894"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Top Mobil Jember",
      "ALAMAT": "Jl. Imam Bonjol 18 Jember",
      "TELEPHONE": "0331-339599",
      "EMAIL": "top.mbl_jember@yahoo.co.id",
      "PIC": "Bpk. Rosyid",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310895"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Koko",
      "ALAMAT": "Jl. Ki Ageng Gribig - lesanpuro  18, Malang",
      "TELEPHONE": "0341-710116",
      "EMAIL": "bengkel.koko@yahoo.co.id",
      "PIC": "Ibu Reta / Ibu Vita",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310896"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Auto 99",
      "ALAMAT": "Jl. Soekarno Hatta No.31, Malang",
      "TELEPHONE": "0341 - 491099",
      "EMAIL": "auto99plus@yahoo.com",
      "PIC": "Bpk. Budi / Bpk. Zulfikar",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310897"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Absolute Kediri",
      "ALAMAT": "Jl. Urip Sumoharjo No. 138 A Kediri",
      "TELEPHONE": "0354-685294",
      "EMAIL": "absolute_kediri@yahoo.com",
      "PIC": "Bpk. Bayu / Bpk. Fajar",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310898"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Indo Kreatif",
      "ALAMAT": "Jl. Supersemar 48, Kediri",
      "TELEPHONE": "0354-7415507",
      "EMAIL": "indokreatif.kediri@gmail.com",
      "PIC": "Bpk. Fernando / Ibu Eka",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310899"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Oto Kemuning Care",
      "ALAMAT": "Jl. Soekarno Hatta RT.01/RW.10, Kuto Anyar, Tulungagung",
      "TELEPHONE": "0355-5235172",
      "EMAIL": "okc.tulungagung@gmail.com",
      "PIC": "Bpk. Wildan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310900"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "Top Mobil Kediri",
      "ALAMAT": "Jl. Supersemar 12, Kediri",
      "TELEPHONE": "0354-7011099",
      "EMAIL": "top.mbl_kdr@yahoo.com",
      "PIC": "Bpk. Puji",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310901"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "HONDA MANDALASENA PERKASA MOTOR",
      "ALAMAT": "Jl. Sunandar Priyo Sudarmo No.50 Malang",
      "TELEPHONE": "0341-492929",
      "EMAIL": "bodypaint.mandalasena@gmail.com",
      "PIC": "Bpk. Gian / Bpk. Lukman",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310902"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "HONDA SUKUN MALANG",
      "ALAMAT": "Jl. S.Supriadi No.19-22 Sukun Malang",
      "TELEPHONE": "'0341-363477/0341-363488",
      "EMAIL": "bodypaint@hondasukunmalang.co.id",
      "PIC": "Ibu Ayu",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310903"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "HONDA PASIFIK KEDIRI",
      "ALAMAT": "Jl. Mayor Bismo No.90 Kediri",
      "TELEPHONE": "'0354-687868/0354-682479",
      "EMAIL": "body_paint@hondapacific.com",
      "PIC": "Bpk. Joko / Ibu Anis",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310904"
    },
    {
      "WILAYAH": "MALANG",
      "NAMA BENGKEL": "TOYOTA KARTIKA SARI",
      "ALAMAT": "Jl. Puncak Borobudur No. 1 Malang",
      "TELEPHONE": "0341-479000 / 480280",
      "EMAIL": "kabeng.kartikasari@yahoo.com",
      "PIC": "Bpk. Ahmad / Bpk. Husein Ali",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310905"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "FOCUS AUTO",
      "ALAMAT": "Jl. Raya Tateli Lr.Komplek Pemukiman",
      "TELEPHONE": "(0431) 833868",
      "EMAIL": "-",
      "PIC": "Bp. Ferry",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6354"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "SURYA WINANGUN",
      "ALAMAT": "Jl. Harapan No.52 Winangun",
      "TELEPHONE": "(0431)  836103",
      "EMAIL": "-",
      "PIC": "Bp.Benny",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6355"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KAWANUA SEJATI",
      "ALAMAT": "Jl. Santo Joseph No. 32 - 33",
      "TELEPHONE": "(0431) 833693",
      "EMAIL": "-",
      "PIC": "Bp. Ferdy Louw/ Ibu Anie",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6356"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "BENGKEL SURABAYA",
      "ALAMAT": "Jl. Pumorouw No. 68",
      "TELEPHONE": "()431) 875280",
      "EMAIL": "-",
      "PIC": "Mas Santoso / Ibu Jane",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6357"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "CV. GAMELI AUTO",
      "ALAMAT": "Jl. Pingkan Matindas (Dendengan Dalam)",
      "TELEPHONE": "(0431) 851699",
      "EMAIL": "-",
      "PIC": "Bp. Feky",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6358"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "MATUARI WAYA EXECUTIVE",
      "ALAMAT": "JL. MANADO-BITUNG LING 1 RT 001 RW 001 MANEMBO-NEMBO TENGAH.",
      "TELEPHONE": "",
      "EMAIL": "mwbengkelexecutive@gmail.com",
      "PIC": "BP. RANNY R / IBU SANDY / FRESTY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9321"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "ARMADA SIAGA",
      "ALAMAT": "KEL. BUMI NYIUR, KEC. WANEA MANADO",
      "TELEPHONE": "",
      "EMAIL": "armadasiaga86@yahoo.com",
      "PIC": "NOVI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9322"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "DODOK HALUS GORONTALO",
      "ALAMAT": "JL. POIGAR, MOLOSIPAT U, KEC. SIPATANA",
      "TELEPHONE": "",
      "EMAIL": "cv.dodokhalusgorontalo@yahoo.com",
      "PIC": "EGHA /ANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9323"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KAWANUA SEJATI",
      "ALAMAT": "JL. SANTO YOSEPH, RANOTANA, SARIO",
      "TELEPHONE": "",
      "EMAIL": "kawanua.sejati@yahoo.co.id",
      "PIC": "SOFIE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9324"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "ROMAS MOTOR",
      "ALAMAT": "JL. MARTHADINATA, DENDENGAN LUAR",
      "TELEPHONE": "",
      "EMAIL": "romasmotor@yahoo.com",
      "PIC": "DEYSI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9325"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KOMBOS MANADO 1 (TOYOTA)",
      "ALAMAT": "JL. ARIE LASUT, KOMBOS TIMUR, KEC. SINGKIL",
      "TELEPHONE": "",
      "EMAIL": "sekretariatcvkm@yahoo.co.id",
      "PIC": "DEFRY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9326"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KOMBOS TENDEAN (TOYOTA)",
      "ALAMAT": "JL. PIERE TENDEAN BOULEVARD, MANADO",
      "TELEPHONE": "",
      "EMAIL": "cvk_tendean@yahoo.co.id",
      "PIC": "MERRY /",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9327"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KOMBOS GORONTALO (TOYOTA)",
      "ALAMAT": "JL. CUT NYAK DIEN, KEC, KOTA TIMUR",
      "TELEPHONE": "",
      "EMAIL": "sa.bpkasuari@gmail.com",
      "PIC": "ISWAN / DEA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9328"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "BOSOWA BERLIAN MOTOR (MITSUBISHI)",
      "ALAMAT": "JL. JOS SUDARSO. KAIRAGI WERU",
      "TELEPHONE": "",
      "EMAIL": "bbm.bodyrepair.mdo@bosowaberlian.com",
      "PIC": "MOSES",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9329"
    },
    {
      "WILAYAH": "MANADO",
      "NAMA BENGKEL": "KING AUTO",
      "ALAMAT": "JL. PINGKAN MATINDAS LING VII",
      "TELEPHONE": "",
      "EMAIL": "cvkingauto@gmail.com",
      "PIC": "JEFRY RONDONUWU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9330"
    },
    {
      "WILAYAH": "Manado",
      "NAMA BENGKEL": "Armada Siaga",
      "ALAMAT": "Jl. Ring Road - Kel. Bumi Nyiur",
      "TELEPHONE": "T: 0431-8803826, F: 0431-864082;082296611235",
      "EMAIL": "armadasiaga86@yahoo.com",
      "PIC": "Tikad",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3434"
    },
    {
      "WILAYAH": "Manado",
      "NAMA BENGKEL": "Kawanua Sejati",
      "ALAMAT": "Jl. Santo Joseph No.17, Ranotana, Sario",
      "TELEPHONE": "T: 0431 - 823694;823693;081527076846",
      "EMAIL": "kawanua.sejati@yahoo.co.id",
      "PIC": "Deni Sofiyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3435"
    },
    {
      "WILAYAH": "MATARAM",
      "NAMA BENGKEL": "KRIDA DINAMIK AUTONUSA",
      "ALAMAT": "JL PEKJANGGIK NO 12-14 CAKRANEGARA",
      "TELEPHONE": "0370-672000",
      "EMAIL": "kabengbp.kridamtr@gmail.com",
      "PIC": "BP AGUNG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9331"
    },
    {
      "WILAYAH": "MATARAM",
      "NAMA BENGKEL": "LIGHT AUTO LOMBOK",
      "ALAMAT": "JL. BUNG KARNO NO 25 CAKRANEGARA",
      "TELEPHONE": 81364783888,
      "EMAIL": "lightautolombok@gmail.com",
      "PIC": "BP FRENGKY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9332"
    },
    {
      "WILAYAH": "MATARAM",
      "NAMA BENGKEL": "HI-PRO MOTOR",
      "ALAMAT": "JL. SANDUBAYA NO 01 SWETA CAKRANEGARA",
      "TELEPHONE": 87865850810,
      "EMAIL": "hipro_motor@yahoo.co.id",
      "PIC": "BP CHANDRA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9333"
    },
    {
      "WILAYAH": "MATARAM",
      "NAMA BENGKEL": "CAKRA MOBILINDO",
      "ALAMAT": "JALAN SANDUBAYA NO. 8 SWETA BERTAIS MATARAM",
      "TELEPHONE": "0370-672441",
      "EMAIL": "andrewswat.aas@gmail.com",
      "PIC": "BP ANDRE SURYADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9334"
    },
    {
      "WILAYAH": "MATARAM",
      "NAMA BENGKEL": "PRIMA PARAMA MOBILINDO",
      "ALAMAT": "JL. A.A. GEDE NGURAH NO 68 CAKRANEGARA, MATARAM",
      "TELEPHONE": "0370-622337, 0370-671000",
      "EMAIL": "primalombok.bodyrepair@gmail.com",
      "PIC": "BP INDRA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9335"
    },
    {
      "WILAYAH": "Mataram",
      "NAMA BENGKEL": "CV. Putra Kapuas",
      "ALAMAT": "Jl. Sriwijaya No. 3A, Cakaranegara, Mataram",
      "TELEPHONE": "T : 0370 -  7852794",
      "EMAIL": "putrakapuas@outlook.com",
      "PIC": "Deni Sofiyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3436"
    },
    {
      "WILAYAH": "Mataram",
      "NAMA BENGKEL": "Light Auto",
      "ALAMAT": "Jl Bungkarno No 25 Cilinaya, Kota Mataram, Kec. Cakranegara, Lombok, NTB",
      "TELEPHONE": "T : 0370 - 7842004; 081919100888",
      "EMAIL": "lightauto888@gmail.com",
      "PIC": "Nico / Desy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3437"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "EKA MAGIC",
      "ALAMAT": "Jl.Bambu II No.95",
      "TELEPHONE": "(061) 6618781",
      "EMAIL": "-",
      "PIC": "Bpk. Franky",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6359"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "NEW TOP SPEED",
      "ALAMAT": "Kl. Krakatau No. 182",
      "TELEPHONE": "(061) 6611762",
      "EMAIL": "-",
      "PIC": "Ibu Tuti",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6360"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "U.D GURNING",
      "ALAMAT": "Jl.Cemara No.220",
      "TELEPHONE": "(061) 6610492 - 6613162",
      "EMAIL": "-",
      "PIC": "Ibu Linda",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6361"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "K2 MOBIL",
      "ALAMAT": "Jl.Pabrik Tenun No.124",
      "TELEPHONE": "(061) 4560723",
      "EMAIL": "-",
      "PIC": "Ibu Aling / Bpk Misno",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6362"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "26 MOBIL",
      "ALAMAT": "Jl. Setia Jadi No. 16-18",
      "TELEPHONE": "(061) 4146808- 4142704",
      "EMAIL": "-",
      "PIC": "Ibu Ayin",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6363"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "AUTO CARE",
      "ALAMAT": "Jl.Sisingamangaraja No.344",
      "TELEPHONE": "(061) 736 5950",
      "EMAIL": "-",
      "PIC": "Ibu Cory",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6364"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "MAJU OTOMOTIF",
      "ALAMAT": "Jl. Restu No.8",
      "TELEPHONE": "0851-0000-5147",
      "EMAIL": "-",
      "PIC": "Bpk. Saputra",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6365"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "MITRA JAYA SERVICE",
      "ALAMAT": "Jl. Makmur No.12-14",
      "TELEPHONE": "(061) 6620621",
      "EMAIL": "-",
      "PIC": "Bpk. Ahui / Bpk. Randa",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6366"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "HARMAN SERVICE",
      "ALAMAT": "Jl. Rahmadsyah no.478",
      "TELEPHONE": "(061) 7364851",
      "EMAIL": "-",
      "PIC": "Bp.Darwis",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6367"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "ALFA AUTOMOTIF",
      "ALAMAT": "JL. KERTAS NO. 46, MEDAN",
      "TELEPHONE": "061 4153382",
      "EMAIL": "'bengkel.alfa.automotive@gmail.com'",
      "PIC": "BP. JELSEN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9336"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "AUTOCARE",
      "ALAMAT": "JL. SISINGAMANGARAJA NO. 344 MEDAN",
      "TELEPHONE": "061 7881001",
      "EMAIL": "aut0car3@yahoo.com",
      "PIC": "IBU CORRY / IBU IDA / IBU FITRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9337"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "BARISTO SERVICE STATION",
      "ALAMAT": "JL.STM NO.75 MEDAN",
      "TELEPHONE": "061 7880180",
      "EMAIL": "baristostation@yahoo.co.id",
      "PIC": "BP.SUKIRNO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9338"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "BENGKEL 87 / CV. KARYA MAKMUR",
      "ALAMAT": "JL. SEI BATANG HARI NO. 124, MEDAN",
      "TELEPHONE": 81286379879,
      "EMAIL": "87express@gmail.com",
      "PIC": "BP. PETER SONY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9339"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CAHAYA AUTO SERVICE (PRINTIS GROUP)",
      "ALAMAT": "JL. METAL SIMP PERBATASAN, MEDAN",
      "TELEPHONE": 81269750951,
      "EMAIL": "cahaya.autoservice2019@gmail.com",
      "PIC": "BP. JELSEN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9340"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CAPELLA BODY CENTRE QQ PT. ISUINDOMAS PUTRA",
      "ALAMAT": "JL. SISINGAMANGARAJA KM 6,5 MEDAN AMPLAS",
      "TELEPHONE": "061-7872942",
      "EMAIL": "capellabodycentre@gmail.com",
      "PIC": "BP. ISKANDAR / BP ROLLYS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9341"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CV. SURYA OTOMOTIF",
      "ALAMAT": "JL. FLAMBOYAN TAYA NO. 30, MEDAN",
      "TELEPHONE": "061 8843276",
      "EMAIL": "surya.auto@yahoo.com",
      "PIC": "BP. ADITYA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9342"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "IMPERIAL",
      "ALAMAT": "JL. SEI BATANGHARI NO. 14 MEDAN",
      "TELEPHONE": "061 4153873",
      "EMAIL": "cv.imperial@hotmail.com",
      "PIC": "BPK NELTON / IBU TITI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9343"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "KARYA PRIMA",
      "ALAMAT": "JL. GUNUNG KRAKATAU NO. 10 (SIMPANG CEMARA)",
      "TELEPHONE": "061 6619583",
      "EMAIL": "karya_prima10@yahoo.com",
      "PIC": "IBU NENENG/ IBU YUNI/ BPK. RANDY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9344"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "LEO OTOMOTIF",
      "ALAMAT": "JL. BILAL NO. 45/229 MEDAN",
      "TELEPHONE": "061 6641188",
      "EMAIL": "leootomotif.bilal@gmail.com",
      "PIC": "IBU EKA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9345"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "MAJU OTOMOTIF",
      "ALAMAT": "JL. NEGARA NO.71/84",
      "TELEPHONE": 85100005147,
      "EMAIL": "claim.maju@gmail.com",
      "PIC": "IBU HETTY / IBU YANNY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9346"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. AUTO TEGUH MANDIRI (D/H 26 MOBIL)",
      "ALAMAT": "JL. SETIA JADI NO 16-18 MEDAN",
      "TELEPHONE": "061 80032928",
      "EMAIL": "automobil_26@yahoo.com",
      "PIC": "BPK ALBERT NGADIMAN / IBU SELLY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9347"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. MEGA CENTRAL AUTO NIAGA",
      "ALAMAT": "JL LETDA SUJONO NO. 111 A",
      "TELEPHONE": "061 7344555",
      "EMAIL": "vionahutabarat6@gmail.com",
      "PIC": "PAK INDRA / YONA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9348"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. ASTRA INTERNASIONAL, TBK",
      "ALAMAT": "JL. SISINGAMANGARAJA KM. 9,8 NO. 8 AMPLAS",
      "TELEPHONE": "061-7868000",
      "EMAIL": "ahmad.wirajaya@tso.astra.co.id",
      "PIC": "BP. AHMAD WIRAJAYA / IBU MISNAH SIMANJUNTAK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9349"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "ARISTA GROUP (WULLING, ERTIGA, HONDA)",
      "ALAMAT": "JL. GUMBAN SURBAKTI NO. 34 RING ROAD, MEDAN",
      "TELEPHONE": "061-42081888",
      "EMAIL": "rudiantohisar1209@gmail.com",
      "PIC": "YULIAN/ ZULFAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9350"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT.ISTANA DELI KENCANA",
      "ALAMAT": "JLN. H. ADAM MALIK NO.85 MEDAN",
      "TELEPHONE": "061 6610610",
      "EMAIL": "maya_vevi@yahoo.co.id",
      "PIC": "PT. ISTANA DELI KENCANA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9351"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. DIPO PAHALA (D/H PT.SUMATERA BERLIAN MOTOR)",
      "ALAMAT": "JL. SISINGAMANGARAJA NO.34 KM 7 MEDAN AMPLAS",
      "TELEPHONE": "061-7866868",
      "EMAIL": "sbm_bodyrepair@yahoo.com",
      "PIC": "ANDY, GALIH , RESTU , SUWANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9352"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. DELTAMAS SURYA INDAH MULIA",
      "ALAMAT": "JL. SISINGAMANGARAJA KM 5.5 NO. 8 A,  MEDAN AMPLAS",
      "TELEPHONE": "061-7872188",
      "EMAIL": "deltamas.adm@gmail.com",
      "PIC": "SAIAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9353"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CV. AUTO SEJAHTERA",
      "ALAMAT": "JLN. DR. MR. T. MOHD. HASAN. NO. 294 BATOH - BANDA ACEH",
      "TELEPHONE": "0651-6300560",
      "EMAIL": "auto.sejahtera@yahoo.co.id",
      "PIC": "BPK MUHAMMAD SABIRIN / BPK RISFANDY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9354"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PRO MOTOR",
      "ALAMAT": "JL. T. P. NYAK MAKAM NO. 88 DESA DOY, ULEE KARENG - BANDA ACEH",
      "TELEPHONE": "0651-7555551",
      "EMAIL": "fendi@promotor.co.id",
      "PIC": "BP. FENDI/ BP. ZULFIKRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9355"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "DUNIA BARUSA",
      "ALAMAT": "JL. DR. MR. T.H MUHAMMAD HASAN NO. 8, BATOH, LUENG BATA",
      "TELEPHONE": "0651-28999",
      "EMAIL": "tadha16duniabarusa@gmail.com",
      "PIC": "BP. MURTADHA / BP. FEBRIANSYAH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9356"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CV.ISTANA MOBIL",
      "ALAMAT": "JL. SISINGAMANGARAJA NO.40 BANDARSONO TEBING TINGGI",
      "TELEPHONE": "0621-327969",
      "EMAIL": "istanamobil2000@yahoo.com",
      "PIC": "BP SALIMIN/NANDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9357"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. WAHANA TRANS LESTARI MEDAN",
      "ALAMAT": "JL. H. ADAM MALIK NO. 193 C KEL. SEI AGUL, KEC. MEDAN BARAT MEDAN, SUMATERA UTARA",
      "TELEPHONE": "061-6640000",
      "EMAIL": "eko.sukoco@datsun.indomobil.co.id",
      "PIC": "EKO SUKOCO / SYAHRIAL M N LUBIS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9358"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. WAHANA TRANS LESTARI MEDAN",
      "ALAMAT": "JL.T.AMIR HAMZAH NO.16 A - MEDAN",
      "TELEPHONE": "061-80032407",
      "EMAIL": "daniel.sipayung@nissan.indomobil.co.id",
      "PIC": "DANIEL/CHRISTIAN/DODIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9359"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. WAHANA TRANS LESTARI MEDAN",
      "ALAMAT": "JL. JEND. GATOT SUBROTO NO. 148, KEL. SEI PUTIH BARAT KEC. MEDAN PETISAH",
      "TELEPHONE": "061-4558988",
      "EMAIL": "rijal.simanjutak@nissan.indomobil.co.id",
      "PIC": "RIJAL / AGUS TRIONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9360"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "ANTO JAYA",
      "ALAMAT": "JL. SISINGAMANGARAJA NO. 146 B SIMPANG MANGGA BAWAH RANTAUPRAPAT",
      "TELEPHONE": "0624-2600096",
      "EMAIL": "anto.jaya19@yahoo.com",
      "PIC": "IBU INDAH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9361"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "KINGS AUTO SERVICE",
      "ALAMAT": "JLN.BATU BARA NO 19 TEBING TINGGI",
      "TELEPHONE": 62122436,
      "EMAIL": "ELSAMIRKINGSAUTO@GMAIL.COM",
      "PIC": "BPK EDY SYAPUTRA / IBU ELSA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9362"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "KELANA",
      "ALAMAT": "JL. NAGA TERBANG NO. 02 SIANTAR TIMUR, PEMATANGSIANTAR, SUMATERA UTARA",
      "TELEPHONE": "0624-7552758",
      "EMAIL": "kelana7552758@gmail.com",
      "PIC": "BP.TJIAM KIM TENG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9363"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CV. HAR MOTOR (D/H TATA RAYA AUTO SHOP)",
      "ALAMAT": "JL. SM. RAJA NO 178 RANTAUPRAPAT",
      "TELEPHONE": "0624-7671788",
      "EMAIL": "tras1788@gmail.com",
      "PIC": "BP. KHAIRUL AHMAD DALIMUNTHE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9364"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. ARISTA AUTO PRIMA HONDA, WULING, MITSUBISHI",
      "ALAMAT": "JL. DR.MR. M. HASAN NO. 100, LAMPEUNEURUT - B. ACEH",
      "TELEPHONE": 82370504273,
      "EMAIL": "arista servicebandaaceh <arista.service.bandaaceh@gmail.com>",
      "PIC": "IBU WULAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9365"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "CV. AUTO HASS",
      "ALAMAT": "JL. SOEKARNO HATTA DS. LAMREUG, ACEH BESAR",
      "TELEPHONE": "0651-8071788",
      "EMAIL": "autohass2017@gmail.com",
      "PIC": "BP. BENI /BP. AZIMUL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9366"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. RATU MOBIL SEJAGAT",
      "ALAMAT": "JL. SM. RAJA KM. 6,5 HARJO SARI II, MEDAN",
      "TELEPHONE": "061-7862000",
      "EMAIL": "dinaoliviasalam@gmail.com",
      "PIC": "BP. NELTON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9367"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "PT. TRANS SUMATRA ANDALAN",
      "ALAMAT": "JL. H. ADAM MALIK NO. 103 A, MEDAN",
      "TELEPHONE": "061-6618777",
      "EMAIL": "bodyrepairer.tsa@gmail.com",
      "PIC": "BP. EFRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9368"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "EFFENDI ANWAR",
      "ALAMAT": "JL. SAMUDRA NO. 24/22-D, MEDAN",
      "TELEPHONE": "",
      "EMAIL": "'kokowhite53@gmail.com'",
      "PIC": "BP. EFFENDY ANWAR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9369"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "26 Mobil",
      "ALAMAT": "Jl. Setia Jadi No. 16 - 18 , Medan",
      "TELEPHONE": "T: 061-80032928, 061-80032526, F: 061-80032927",
      "EMAIL": "automobil_26@yahoo.com",
      "PIC": "Erick / Riky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3438"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "87 Express (Karya Makmur)",
      "ALAMAT": "Jl.Sei Batang Hari No. 124",
      "TELEPHONE": "T: 061- 4563565, F : 061- 4534865",
      "EMAIL": "87express@gmail.com",
      "PIC": "Aliong",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3439"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Karya Prima",
      "ALAMAT": "Jl. Gunung Krakatau No. 10",
      "TELEPHONE": "T: 061-6619583, 061-6630875, F: 061-6630860",
      "EMAIL": "karya_prima10@yahoo.com",
      "PIC": "Hasan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3440"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Leo Otomotif Abadi 1",
      "ALAMAT": "Jl. Karantina No. 8 A",
      "TELEPHONE": "T: 061-6633188, F: 061-6639392",
      "EMAIL": "leootomotif.bilal@gmail.com",
      "PIC": "Samsul/Hendri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3441"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Leo Otomotif Abadi 2",
      "ALAMAT": "Jl. Bilal No. 45/229",
      "TELEPHONE": "T: 061-6641188, F: 061-6633158",
      "EMAIL": "leootomotif.bilal@gmail.com",
      "PIC": "Sari",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3442"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Maju Otomotif",
      "ALAMAT": "Jl. Negara No. 71/84",
      "TELEPHONE": "T: 0812-6590170",
      "EMAIL": "claim.maju@gmail.com; maju_otomotif01@yahoo.co.id",
      "PIC": "Bp Salam (MKT Corporate)\nBp Kodrianto/Kepala Bengkel \nService Advisor : Bona/Habib/Darman/Wahid",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3443"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Mitra Jaya Service 1",
      "ALAMAT": "Jl. Makmur No. 12 - 14",
      "TELEPHONE": "T: 061 - 6620621/4534614 F : 061 - 6628540",
      "EMAIL": "mjs.bersama@yahoo.com",
      "PIC": "Syamsuardi /Swasta",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3444"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Mitra Jaya Service 2",
      "ALAMAT": "Jl. Wahidin No. P14 - P16",
      "TELEPHONE": "T: 061-6620621/4534614 F : 061 - 6628540",
      "EMAIL": "mjs.bersama@yahoo.com",
      "PIC": "Daniel",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3445"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Mitra Setia Service",
      "ALAMAT": "Jl. Gatot Subroto No. 145",
      "TELEPHONE": "T: 061-8445829, 061-8454210",
      "EMAIL": "mitrasetia_mdn@yahoo.co.id",
      "PIC": "Michael",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3446"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "New Pelangi Service",
      "ALAMAT": "Jl.Perjuangan no.102 (seberang pergudangan MMTC jl.willem iskandar/jl.pancing), Kel. Tegalrejo, Kec. Medan Perjuangan, Medan",
      "TELEPHONE": "T: 061-4527083",
      "EMAIL": "newpelangiservice@gmail.com",
      "PIC": "Darmanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3447"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Sejahtera Service Station",
      "ALAMAT": "Jl. Gereja No. 18 Medan & Jl. Setia Baru No. 30",
      "TELEPHONE": "T: 061-6631063, F: 061-6615705",
      "EMAIL": "sejahteraservice888@gmail.com",
      "PIC": "Bp Salam (MKT Corporate)\nBp Salik/Kepala Bengkel \nService Advisor : Surrahman/Jentar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3448"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Bengkel Unity Automobile",
      "ALAMAT": "Jl.Bambu I No 10/69 Medan",
      "TELEPHONE": "061-6612029 / 0853 7233 6699",
      "EMAIL": "unity_automobile@yahoo.com",
      "PIC": "Novita",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3449"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Speedline Auto",
      "ALAMAT": "Jl. Adam Malik No. 68",
      "TELEPHONE": "T: 061-6842276, 061-6641999",
      "EMAIL": "deni_demara@speedlineauto.co.id",
      "PIC": "Riska",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3450"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "PT Mega Central Autoniaga",
      "ALAMAT": "Jl. Letda Sujono No. 111 A, Bandar Selamat, Medan Tambung - Sumut",
      "TELEPHONE": "T: 061-7344555, 061-7368555",
      "EMAIL": "megacentral.bodypaint@gmail.com",
      "PIC": "Julius Lontoh",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3451"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "CV Auto Care",
      "ALAMAT": "Jl. Sisingamangaraja No. 344, Siti Rejo I, Medan Kota",
      "TELEPHONE": "T : 061-7881001 / 061-7876400",
      "EMAIL": "autocar3@yahoo.co.id",
      "PIC": "Santi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3452"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Astra International Isuzu Medan",
      "ALAMAT": "Jl. Sisingamangaraja No. 243",
      "TELEPHONE": "T : 061-7349000",
      "EMAIL": "hardyan.syah@iso.astra.co.id",
      "PIC": "Bp Salam (MKT Corporate)\nBp Choky/Kepala Bengkel \nService Advisor : \nBp Dedek/Bp James",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3453"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Auto 2000 - Medan Amplas",
      "ALAMAT": "Jl. Sisingamangaraja Km. 9,8  No. 204, Amplas � Medan 20148",
      "TELEPHONE": "T : 061-7868000 ; F : 061-7860517",
      "EMAIL": "syahreza@tso.astra.co.id ; misnah.simanjuntak@tso.astra.co.id",
      "PIC": "Udaka / Nita",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3454"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "PT.  Dipo Internasional Pahala Otomotif",
      "ALAMAT": "Jl. Sisingamangaraja Km. 7 No. 34 Medan",
      "TELEPHONE": "T : 061 - 7866868",
      "EMAIL": "sbm_bodyrepair@yahoo.com ; andy.medan@sbmgrp.com",
      "PIC": "Virca",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3455"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "Bintang Cosmos (Authorized Mercedes-Benz)",
      "ALAMAT": "Jl. Sisingamangaraja Km. 7",
      "TELEPHONE": "T : 061 -  786.6111; 085311033636",
      "EMAIL": "workshop@ptbintangcosmos.com",
      "PIC": "Esti / Dian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3456"
    },
    {
      "WILAYAH": "Medan",
      "NAMA BENGKEL": "PT Trans Sumatera Agung",
      "ALAMAT": "Jl. H Adam Malik No.103 A, Kel. Petisah Tengah, Medan",
      "TELEPHONE": "T : 061-4522619 / 061-6636250",
      "EMAIL": "maya0188@yahoo.co.id",
      "PIC": "Trisula / Winarto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3457"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Anto Jaya",
      "ALAMAT": "Jl. SM Raja No. 146B, Rantau Prapat",
      "TELEPHONE": "0624-2600096",
      "EMAIL": "anto.jaya19@yahoo.com",
      "PIC": "Bpk. Sarpin",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310906"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Istana Mobil",
      "ALAMAT": "Jl. SM Raja No. 40, Bandarsono, Tebing Tinggi",
      "TELEPHONE": "061-327969",
      "EMAIL": "istanamobil2000@yahoo.com",
      "PIC": "Bpk. Sutrisno",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310907"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Auto Care",
      "ALAMAT": "Jl. SM Raja No. 344, Medan",
      "TELEPHONE": "61-7881001",
      "EMAIL": "aut0car3@yahoo.com",
      "PIC": "Ibu Ida dan Ibu Fitri",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310908"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "JW Automotif",
      "ALAMAT": "Jl. Kapten muslim No. 281 Helvetia � Medan",
      "TELEPHONE": "061-80030613",
      "EMAIL": "jw_automotif@yahoo.com",
      "PIC": "Ibu Ani, Ibu Eka / Bpk. Jonny",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310909"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "New Pelangi  Service",
      "ALAMAT": "Jl. Perjuangan No. 102 Depan Comp MMTC, Medan",
      "TELEPHONE": "061-4526083",
      "EMAIL": "newpelangi_service@yahoo.com",
      "PIC": "Bpk. Rudi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310910"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Mitra Jaya Service",
      "ALAMAT": "Jl. Makmur No. 12/14, Medan",
      "TELEPHONE": "061-662062",
      "EMAIL": "",
      "PIC": "Bpk. Rusli",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310911"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Auto Sejahtera Banda Aceh",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310912"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Honda Arista Ringroad Medan",
      "ALAMAT": "Ringroad Komplek OCBC No. 96 Blok B1-B4, Medan Selayang, Medan",
      "TELEPHONE": "061-42081888",
      "EMAIL": "",
      "PIC": "Bpk. Yohanes /0813-6238-3463 & Bpk. Budiono",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310913"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "",
      "ALAMAT": "SM Raja km 5,5 No.2, Medan",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310914"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Honda Istana Deli Kencana",
      "ALAMAT": "Jl. Adam Malik No. 85, Medan",
      "TELEPHONE": "061-6610610",
      "EMAIL": "alfarisilubis@yahoo.com",
      "PIC": "Bpk. Alfarisi Lubis",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310915"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "Astra Daihatsu",
      "ALAMAT": "Jl. SM Raja No. 170, Medan",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "Yudha / 0812-6844-7333",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310916"
    },
    {
      "WILAYAH": "MEDAN",
      "NAMA BENGKEL": "",
      "ALAMAT": "Jl. Gunung Krakatau Ujung No. 238, Medan",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310917"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "OTO BODI & CAT",
      "ALAMAT": "JL. S. PARMAN NO.91 BELAKANG (SEBELAH PANGERAN BEACH HOTEL)",
      "TELEPHONE": 7057276,
      "EMAIL": "oto.bodicat@gmail.com",
      "PIC": "BP. FREDY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9370"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "BENTENG UTAMA MOTOR",
      "ALAMAT": "JL. AR. HAKIM NO. 60-66 KEL. RANAH PARAK RUMBIO KEC. PADANG SELATAN KOTA PADANG",
      "TELEPHONE": "0751-25271",
      "EMAIL": "new_makna.motor@yahoo.com",
      "PIC": "ARDILES UTAMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9371"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "PADANG AUTO",
      "ALAMAT": "JLN.RAYA JONDUL NO.1 RAWANG PADANG - SELATAN",
      "TELEPHONE": "0751-765364",
      "EMAIL": "padang.auto@yahoo.com",
      "PIC": "BP. HENDRI ARGOSURIO / SINDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9372"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "ANUGRAH BODY PAINT",
      "ALAMAT": "JL. SAWAH LIKE",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "RUSLI ANUGRAH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9373"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "PT. TOSHIO AGUNG ABADI",
      "ALAMAT": "JL. PURUS III NO. 8 PADANG SUMATERA BARAT 25115",
      "TELEPHONE": 811665422,
      "EMAIL": "toshioautobody@ymail.com",
      "PIC": "BP. DARWIS SUZUKI / JIMMY GOZALI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9374"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "STELLINDO MOTOR",
      "ALAMAT": "JL.BY PASS KM 8 KAYU GADANG KEL.PASAR AMBACANG ,KEC.KURANI 25152 PADANG",
      "TELEPHONE": "0751 891130",
      "EMAIL": "stel_motor@yahoo.co.id",
      "PIC": "BP AULIA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9375"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "TOYOTA INTERCOM BODY PAINT",
      "ALAMAT": "JL. VETERAN",
      "TELEPHONE": "",
      "EMAIL": "",
      "PIC": "BP. NOVRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9376"
    },
    {
      "WILAYAH": "PADANG",
      "NAMA BENGKEL": "WAHANA ANDALAS PRIMA",
      "ALAMAT": "JL. KELAPA GADING VIII ULAK KARANG SELATAN PADANG",
      "TELEPHONE": "0751 891130",
      "EMAIL": "stel_motor@yahoo.co.id",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9377"
    },
    {
      "WILAYAH": "Padang",
      "NAMA BENGKEL": "Asokatama Motor",
      "ALAMAT": "Jl. Ujung Gurun Dalam No. 67A",
      "TELEPHONE": "T: 0751-891512, F: 0751-892823",
      "EMAIL": "asokatamamotor@gmail.com",
      "PIC": "Dwi / Yuliana",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3458"
    },
    {
      "WILAYAH": "Padang",
      "NAMA BENGKEL": "PT Capella Medan - Padang",
      "ALAMAT": "Jl. Prof. Dr. Hamka No. 123, Padang",
      "TELEPHONE": "T: 0751-7051777 / 7059894",
      "EMAIL": "bangun.salam@yahoo.co.id ; cm.tengku_said@capellagraoup.com ; cm.boyjekson@yahoo.co.id ; rikky_fd@ymail.com",
      "PIC": "Dony/Ibu Ajeng",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3459"
    },
    {
      "WILAYAH": "Padang",
      "NAMA BENGKEL": "Parsariran",
      "ALAMAT": "Jl. Sudirman No. 111",
      "TELEPHONE": "T: 0634-25439, 0813-97555808",
      "EMAIL": "muslimparsariranmotor@yahoo.co.id",
      "PIC": "Alfon / Santo/fany",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3460"
    },
    {
      "WILAYAH": "Padang",
      "NAMA BENGKEL": "Auto 2000 - padang",
      "ALAMAT": "Jl.Raya Padang  Bypass KM-12   Air Pacah, Padang Sumatera Barat",
      "TELEPHONE": "T : 0751-498800 ;  F : 0751-496464",
      "EMAIL": "savitri.nanda@tso.astra.co.id;fardyan@tso.astra.co.id",
      "PIC": "David",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3461"
    },
    {
      "WILAYAH": "Padang",
      "NAMA BENGKEL": "Honda Gajah Mada",
      "ALAMAT": "Jl. Bagindo Aziz Chan No.9 KM.15 Bypass Kel.Aie pacah Kec.Koto Tangah, Padang, Sumatera Barat",
      "TELEPHONE": "T : 0751-4640568",
      "EMAIL": "hondagajahmotorbypass.bp@gmail.com; \ndodi.gm85@yahoo.co.id",
      "PIC": "Deni",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3462"
    },
    {
      "WILAYAH": "PALANGKARAYA",
      "NAMA BENGKEL": "AUTO SERVICE DELTA NIAGA",
      "ALAMAT": "Jl. RTA Milono Km 4 No.52",
      "TELEPHONE": "(0536) 3239995",
      "EMAIL": "-",
      "PIC": "Bpk. Abdul Halim (Adul)",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6368"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "AMAN",
      "ALAMAT": "Jl.Sutan Syahrir No.57",
      "TELEPHONE": "(0711) 7154621",
      "EMAIL": "-",
      "PIC": "Bpk Aman / Aguan",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6369"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL JERNIH",
      "ALAMAT": "Jl.Jend.A.Yani No.58",
      "TELEPHONE": "(0711) 511140, 512310",
      "EMAIL": "-",
      "PIC": "Bpk.Edi Purwanto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6370"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "CITRA JAYA",
      "ALAMAT": "Jl.Basuki Rahmat No.2141",
      "TELEPHONE": "(0711) 373323",
      "EMAIL": "-",
      "PIC": "Bpk.Thamrin",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6371"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "CV.UNION MOTOR",
      "ALAMAT": "Jl.Jend.A.Yani No,1",
      "TELEPHONE": "(0711) 513700",
      "EMAIL": "-",
      "PIC": "Bpk.Rudi Loembaghi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6372"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL EVO",
      "ALAMAT": "Jl.Sapta Marga No.021",
      "TELEPHONE": "0812-7122601",
      "EMAIL": "-",
      "PIC": "Bpk.Heri",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6373"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "CITRA TEKNIK",
      "ALAMAT": "Jl, Letda A. Rozak No. 64/949",
      "TELEPHONE": "(0711) 710658",
      "EMAIL": "-",
      "PIC": "Bpk. A Chai",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6374"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "TIARA PERSADA",
      "ALAMAT": "Jl.Bambang Utoyo No.355",
      "TELEPHONE": "(0711)-8840800",
      "EMAIL": "-",
      "PIC": "Bp.Hasan",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6375"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL MOBIL \"AMAN ",
      "ALAMAT": "JL. SUTAN SYAHRIL NO. 57 PALEMBANG 30115",
      "TELEPHONE": 711715462,
      "EMAIL": "bengkel_aman@yahoo.com",
      "PIC": "ANDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9378"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "LAKSHMI MOTOR",
      "ALAMAT": "JL. MAYOR ZEN NO. 62 (DEPAN PT. PUSRI), PALEMBANG, 30119",
      "TELEPHONE": 711717669,
      "EMAIL": "lakshmimotor@yahoo.co.id",
      "PIC": "RICKY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9379"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL JERNIH",
      "ALAMAT": "JL. JEND A .YANI NO.58 - 14 ULU PALEMBANG, SUMATRA SELATAN",
      "TELEPHONE": 711511140,
      "EMAIL": "bengkeljernih@yahoo.co.id",
      "PIC": "EDI PURWANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9380"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BODY REPAIR MOBIL CITRA JAYA",
      "ALAMAT": "JL. HUSNI THAMRIN NO.1366 RT. 024 RW. 04 SUKABANGUN 1 PALEMBANG",
      "TELEPHONE": 7115610259,
      "EMAIL": "citrajaya_plg@yahoo.co.id",
      "PIC": "ASTI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9381"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "EMERALD MANDIRI PERSADA",
      "ALAMAT": "JL.RESIDEN A.ROZAK NO.100 A, PALEMBANG",
      "TELEPHONE": 7115615115,
      "EMAIL": "emeraldbengkelmobil@gmail.com",
      "PIC": "AAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9382"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL MOBIL EVO",
      "ALAMAT": "JALAN SAPTA MARGA NO. 0021 RT. 05 KEL. BUKI SANGKAL KEC. KALIDONI PALEMBANG",
      "TELEPHONE": 711819656,
      "EMAIL": "bengkelevo@yahoo.co.id",
      "PIC": "HENDRY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9383"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL PASS / WIRASWASTA SERBANYAMAN",
      "ALAMAT": "JL. DEMANG LEBAR DAUN NO. 26, PALEMBANG, 30137",
      "TELEPHONE": 711445553,
      "EMAIL": "pass_ws@yahoo.co.id",
      "PIC": "DADED ROCHADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9384"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "HONDA MAJU MOBILINDO",
      "ALAMAT": "JL. LETJEN HARUN SOHAR NO.999 PALEMBANG 30252",
      "TELEPHONE": 7115715111,
      "EMAIL": "hmmtaa.bp@gmail.com",
      "PIC": "RONI WIJAYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9385"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "WAHANA  WIRAWAN PALEMBANG ( NISSAN )",
      "ALAMAT": "JL. DEMANG LEBAR DAUN NO. 24",
      "TELEPHONE": "0852-7355-5665",
      "EMAIL": "nurhadi@nissan.indomobil.co.id",
      "PIC": "NURHADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9386"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "SINAR TERANG",
      "ALAMAT": "JL. RESIDEN ABDUL ROZAK (TEMBUSAN PATAL- PUSRI)",
      "TELEPHONE": "0812-7819-857",
      "EMAIL": "",
      "PIC": "NURDIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9387"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "PT LAUTAN BERLIAN UTAMA MOTOR",
      "ALAMAT": "JL.SOEKARNO HATTA PALEMBANG",
      "TELEPHONE": "0711-5560888",
      "EMAIL": "",
      "PIC": "RENDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9388"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "PT ENCARTHA INDONESIA",
      "ALAMAT": "JL. ANGKATAN 45 NO. 02 PALEMBANG",
      "TELEPHONE": "0852 688 31555",
      "EMAIL": "",
      "PIC": "BENNY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9389"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL SAHABAT (BATURAJA)",
      "ALAMAT": "JL. GARUDA LINTAS SUMATERA RT 01 DUSUN IV DESA TANJUNG BARU KEC. BATURAJA TIMUR KAB. OKU",
      "TELEPHONE": "0812 1812 9585",
      "EMAIL": "",
      "PIC": "IBU ATIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9390"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL SAHABAT (LUBUKLINGGAU)",
      "ALAMAT": "JL. SULTAN MAHMUD BADARUDIN II  NO. 98 RT. 05 KEL. TANAJ PRIUK KEC. LUBUK LINGGAU SELATAN II",
      "TELEPHONE": "0812 7877 5057",
      "EMAIL": "",
      "PIC": "BP. RIZA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9391"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "BENGKEL SAHABAT (LAHAT)",
      "ALAMAT": "JL. RAYA DESA MANGGUL KEC. LAHAT KAB LAHAT",
      "TELEPHONE": "0813 6803 5042",
      "EMAIL": "",
      "PIC": "BP. RIO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9392"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Aman",
      "ALAMAT": "Jl. Residen H. Abdul Rozak No. 11 Kalidoni",
      "TELEPHONE": "T: 0711-715462, F: 0711-717465 ;",
      "EMAIL": "bengkel_aman@yahoo.com",
      "PIC": "Suwignyo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3463"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Aman 2",
      "ALAMAT": "Jl. Sultan Syahril No. 57",
      "TELEPHONE": "T: 0711-715462, F: 0711-717465",
      "EMAIL": "bengkel_aman@yahoo.com",
      "PIC": "Yayat Suryatna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3464"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Deva Samudra",
      "ALAMAT": "Jl. Rama Raya No. 30 RT.04 RW.01 KM 10,5 Alang-Alang Lebar",
      "TELEPHONE": "T: 0711-384616, 0711-384616",
      "EMAIL": "devasm010@gmail.com",
      "PIC": "M. Khomaidi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3465"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Gat Motor",
      "ALAMAT": "Jl. Lunjuk Jaya Lr. Anggrek No. 2B� Lorok Pakjo, Kec. Ilir Barat I",
      "TELEPHONE": "T: 0711-443675, 0711-445667, F: 0711- 443675",
      "EMAIL": "gat_motor_lunjuk@yahoo.com",
      "PIC": "Subhan, Kusnadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3466"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Jiron",
      "ALAMAT": "JL. Sukarela Rt.06 Rw.02 Kec.Sukarami Palembang",
      "TELEPHONE": "T:   0711-5716511 ; 0821-8229-8266",
      "EMAIL": "jironmotor@yahoo.com",
      "PIC": "Musta'in",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3467"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Kemuning",
      "ALAMAT": "Jl.Rimba kemuning Lrg.Kemuning bakti No.380 Palembang",
      "TELEPHONE": "T: 0711-5611197/98",
      "EMAIL": "kemuningjayabengkel@gmail.com",
      "PIC": "Andi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3468"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Mobil Evo",
      "ALAMAT": "Jl. Sapta Marga No. 21",
      "TELEPHONE": "T: 0711-7794220, 0812-7122601, F: 0711-8888560",
      "EMAIL": "bengkelevo@yahoo.co.id",
      "PIC": "Fasa Yogi Riyanda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3469"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Sahabat",
      "ALAMAT": "Jl. Kol. H. Burlian Talang kapuk No. 147 Ps. Lama, Lahat",
      "TELEPHONE": "T: 0822-8219-9690",
      "EMAIL": "admsahabatlahat@gmail.com",
      "PIC": "Shinta",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3470"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Sinar Body Repair",
      "ALAMAT": "Jln. Soak Permai  No. 88 Rt.063/009 Kel. Sukajaya\nKec. Sukarami Palembang",
      "TELEPHONE": "T: 0813-6935-9833",
      "EMAIL": "sinarbodyrepair@yahoo.com",
      "PIC": "Fuad Akbari",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3471"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Arif",
      "ALAMAT": "Jl. Brigjend Hasan kasim  LR. Patas",
      "TELEPHONE": "T : 082182881618",
      "EMAIL": "arif_mobil@yahoo.co.id",
      "PIC": "Didi / Imam",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3472"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Tiara Persada",
      "ALAMAT": "Jl. Bambang Utoyo No. 355",
      "TELEPHONE": "T: 0711 - 5625666",
      "EMAIL": "tiarapersada523@yahoo.com",
      "PIC": "Siti Rochmawati",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3473"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "CV Megalestari Bahana Wicaksono",
      "ALAMAT": "Jl. Kolonel H. Sulaiman Amin E/5 RT 28/08 Kel. Karya Baru, Palembang",
      "TELEPHONE": "T: 0897-8858885",
      "EMAIL": "bahanabodyrepair@gmail.com",
      "PIC": "Supriyanto / Agus",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3474"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Astra Daihatsu Palembang",
      "ALAMAT": "Jl. Jendral Ahmad Yani No. 100,14 Ulu",
      "TELEPHONE": "T: 0711-511889, 0711-519600, 0852-89611165",
      "EMAIL": "imam.pambudi@dso.astra.co.id",
      "PIC": "Haris",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3475"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Astra International Isuzu Palembang",
      "ALAMAT": "Jl. Soekarno Hatta No. 200",
      "TELEPHONE": "T: 0711-5611500",
      "EMAIL": "aprian.rizkiromaidi@iso.astra.co.id",
      "PIC": "Banu / Adji",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3476"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Auto 2000  - Palembang",
      "ALAMAT": "Jl. Jend. A. Yani No.5502, Palembang",
      "TELEPHONE": "T : 0711-512000 ; F : 0711-512943",
      "EMAIL": "ade.ismailwijaya@tso.astra.co.id",
      "PIC": "Kuncoro / Yoga",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3477"
    },
    {
      "WILAYAH": "Palembang",
      "NAMA BENGKEL": "Auto 2000 Tanjung Api Api",
      "ALAMAT": "Jl. Letjend Harun Sohar No. 168 Kebun Bunga Sukarami",
      "TELEPHONE": "T: 0711-5612000",
      "EMAIL": "kgs.afriansyah@tso.astra.co.id",
      "PIC": "Helmi A Priantoro",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3478"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Aman",
      "ALAMAT": "Jl. Sutan Syahril No. 57 Palembang",
      "TELEPHONE": "0711-715462",
      "EMAIL": "bengkel_aman@yahoo.com",
      "PIC": "Aman",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310918"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Jernih",
      "ALAMAT": "Jl. Jend. A. Yani No. 58 Palembang",
      "TELEPHONE": "0711-511140/5620062",
      "EMAIL": "bengkeljernih@yahoo.co.id",
      "PIC": "Teresia Tanuwijaya",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310919"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel JPW Auto",
      "ALAMAT": "Jl. Kol. H. Burlian No. 45 Palembang",
      "TELEPHONE": "0711-419888",
      "EMAIL": "jpw.autodivision@yahoo.com",
      "PIC": "Ghofar Suparma / Teddy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310920"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Emerald Mandiri Persada",
      "ALAMAT": "Jl. Residen A. Rozak No. 100 A Simpang Patal Pusri Palembang",
      "TELEPHONE": "0711-5615115",
      "EMAIL": "emeraldbengkelmobil@gmail.com",
      "PIC": "Nurma / Tommy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310921"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Evo",
      "ALAMAT": "Jl. Sapta Marga Mo. 0021 Palembang",
      "TELEPHONE": "0711-819656",
      "EMAIL": "bengkelevo@yahoo.co.id",
      "PIC": "Heri Gunawan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310922"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Gat Motor",
      "ALAMAT": "Jl. Lunjuk Jaya Lrg. Anggrek 2B Palembang",
      "TELEPHONE": "0711-445667 / 445787",
      "EMAIL": "gat_motor@yahoo.com",
      "PIC": "Husni Thamrin",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310923"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Sinar Terang Jaya",
      "ALAMAT": "Jl. Residen A. Rozak Palembang",
      "TELEPHONE": "0711-7924400",
      "EMAIL": "sinarterang_jaya@yahoo.com",
      "PIC": "Nurdin",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310924"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Laksmi Motor",
      "ALAMAT": "Jl. Mayor Zen No. 62 Palembang",
      "TELEPHONE": "0711-718413",
      "EMAIL": "laksmimotor@yahoo.co.id",
      "PIC": "Artaman",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310925"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Cheris",
      "ALAMAT": "Jl. Jalan Bambang Utoyo No. 128 A",
      "TELEPHONE": "0711-5625637",
      "EMAIL": "bengkelcherischeris@yahoo.co.id",
      "PIC": "Suwandi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310926"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Jiron",
      "ALAMAT": "Jl. Sukarela, RT.06/RW.02, Sukarami",
      "TELEPHONE": "0711-5710559",
      "EMAIL": "jironmotor@yahoo.com",
      "PIC": "Wiwik",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310927"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Kana Jaya",
      "ALAMAT": "Jl. Tanjung Api-Api Belakang Auto 2000 No. 4 Palembang",
      "TELEPHONE": "0711-5610743",
      "EMAIL": "kanajayabengkel@gmail.com",
      "PIC": "Stefanus",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310928"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Honda Union",
      "ALAMAT": "Jl. Jend. A. Yani No. 1 Palembang",
      "TELEPHONE": "0711-513700",
      "EMAIL": "service.hondaunion@gmail.com",
      "PIC": "Romi / Devi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310929"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Honda Maju Mobilindo TAA",
      "ALAMAT": "Jl. Letjen Harun Sohar Tanjung Api-Api No. 999",
      "TELEPHONE": "0711-5715111",
      "EMAIL": "hmmtaa.bp@gmail.com",
      "PIC": "Roni",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310930"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Mitsubishi Lautan Berlian",
      "ALAMAT": "Jl. Sekarno Hatta Demang Lebar Daun",
      "TELEPHONE": "0711-5560021",
      "EMAIL": "kondi_suhendi@lautanberlian.co.id",
      "PIC": "Kondi / Dwiky",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310931"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Honda Wiltop Jambi",
      "ALAMAT": "Jl. Sumantri Brojonegoro No.8, Solok Sipin, Telanaipura, Kota Jambi",
      "TELEPHONE": "0741-669798",
      "EMAIL": "info@hondajambi.com",
      "PIC": "Edy Sucipto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310932"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Fix A Car",
      "ALAMAT": "Jl. Multatuli Lr. Harapan Tani 1 Jambi",
      "TELEPHONE": "0811-7950505",
      "EMAIL": "bengkelfixacar@gmail.com",
      "PIC": "Didi Coa",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310933"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Gat Motor Jambi",
      "ALAMAT": "Jl. Yunus Sanis No. 39 A Kebon Handil Simpang Surya Jambi",
      "TELEPHONE": "0741-715799/7139799",
      "EMAIL": "gat_motor_jambi@yahoo.com",
      "PIC": "Usman / Melisa",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310934"
    },
    {
      "WILAYAH": "PALEMBANG",
      "NAMA BENGKEL": "Bengkel Pelita Maju Motor",
      "ALAMAT": "Jl. Abdul Khatab RT. 18 Kel. Pasir Putih Jambi",
      "TELEPHONE": "0741-570426",
      "EMAIL": "pelita_majumotor@yahoo.co.id",
      "PIC": "Tarmisi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310935"
    },
    {
      "WILAYAH": "Palu",
      "NAMA BENGKEL": "Amanda Jaya",
      "ALAMAT": "Jl. Sungai Susu No. 13, Palu Barat",
      "TELEPHONE": "T: 0451-429955, 0813-41040467",
      "EMAIL": "amanda_jaya@yahoo.com",
      "PIC": "Yanuar / Nita",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3479"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "SINAR WIRA KARYA",
      "ALAMAT": "JL. SOEKARNO HATTA GG. SATAM NO. 8 PANGKALPINANG",
      "TELEPHONE": "62717 4256527",
      "EMAIL": "bengkelsinarwirakarya@yahoo.co.id",
      "PIC": "BP. LIOE SIN SIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9393"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "MC MOTOR",
      "ALAMAT": "DSN SAMHIN NO.28 RT.009 DESA PADANG KEC. BARU PANGKALANBARU KAB. BANGKA TENGAH",
      "TELEPHONE": "62717 44261964",
      "EMAIL": "bengkelmcmotor@gmail.com",
      "PIC": "BP. MEN CHEN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9394"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "VW STEEL BODY REPAIR",
      "ALAMAT": "DSN SAMHIN NO.28 RT.009 PADANG BARU PANGKALANBARU",
      "TELEPHONE": "62717 4261964",
      "EMAIL": "vwsteelbodyrepair@yahoo.com",
      "PIC": "BP. FRANDY PRAYOGA EKA PUTRA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9395"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "SUNDA MOTOR",
      "ALAMAT": "JL. AHMAD YANI RT.005 RW.003 TANJUNGPANDAN",
      "TELEPHONE": "62719 21157",
      "EMAIL": "sundamotors@yahoo.com",
      "PIC": "BP. M. FURKON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9396"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "NIAGA BANGKA AUTOMOBILE",
      "ALAMAT": "JL. SOEKARNO HATTA NO. 82 PANGKALPINANG",
      "TELEPHONE": "62717 433402",
      "EMAIL": "hondaniaga.bodypaint17@gmail.com",
      "PIC": "BP.  FAISAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9397"
    },
    {
      "WILAYAH": "PANGKALPINANG",
      "NAMA BENGKEL": "JAGORAWI MOTOR",
      "ALAMAT": "JL. SOEKARNO HATTA KELURAHAN DUL KECAMATAN PANGKALANBARU KABUPATEN BANGKA TENGAH",
      "TELEPHONE": "62717 9111563",
      "EMAIL": "erdo.jagorawi@gmail.com",
      "PIC": "BP. ERDORADY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9398"
    },
    {
      "WILAYAH": "Pasuruan",
      "NAMA BENGKEL": "Ahmad Yani Mobil",
      "ALAMAT": "Jl. Kartini No. 27 Bangil",
      "TELEPHONE": "T: 0343-748888, 0819-37010168, 0811-311103",
      "EMAIL": "aym.mobil@gmail.com",
      "PIC": "derys",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3480"
    },
    {
      "WILAYAH": "Pati",
      "NAMA BENGKEL": "Nasmoco  Pati",
      "ALAMAT": "Jl. Pati - Juwana Km.2,7 Sarirejo Pati",
      "TELEPHONE": "T : 0295 - 4199111",
      "EMAIL": "nasmocobppati@gmail.com",
      "PIC": "Dewi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3481"
    },
    {
      "WILAYAH": "Pekalongan",
      "NAMA BENGKEL": "Nasmoco Pekalongan",
      "ALAMAT": "Jl. Raya Kalibanger KM. 3, Sokorejo, Pekalongan Timur, Sokorejo,  Pekalongan",
      "TELEPHONE": "T : 0285 -  420020",
      "EMAIL": "nasmocobppati@gmail.com",
      "PIC": "Hwie-Hwie",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3482"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "NEW EKA MAGIC",
      "ALAMAT": "Jl.Garuda No.24, Labuh Baru",
      "TELEPHONE": "(0761) 705 4538",
      "EMAIL": "-",
      "PIC": "Ibu Ellys",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6376"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "AUTO BLITZ",
      "ALAMAT": "Jl.Kuantan 7 No.72 C",
      "TELEPHONE": "(0761) 853101",
      "EMAIL": "-",
      "PIC": "Ibu Shelly",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6377"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "VINSA",
      "ALAMAT": "Jl.Soekarno Hatta / Arengka No.15",
      "TELEPHONE": "(0761) 848027, 44183",
      "EMAIL": "-",
      "PIC": "Ibu Aling",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6378"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "AGUNG AUTOMALL-TOYOTA",
      "ALAMAT": "JL.SM AMIN NO. 13,PEKANBARU",
      "TELEPHONE": 761585888,
      "EMAIL": "agungtoyotabp.pk3@gmail.com",
      "PIC": "BAPAK BIMA & IBU QIQY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9399"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "HONDA ARISTA PEKANBARU",
      "ALAMAT": "JL.JEND.SUDIRMAN NO. 228, PEKANBARU",
      "TELEPHONE": 76145999,
      "EMAIL": "yudi_arista21@yahoo.com",
      "PIC": "BAPAK YUDI & BAPAK HADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9400"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "ASTRA DAIHATSU",
      "ALAMAT": "JL.JEND.SUDIRMAN NO. 202A, PEKANBARU",
      "TELEPHONE": 7617891000,
      "EMAIL": "subhan.sinarudin@dso.astra.co.id",
      "PIC": "BAPAK SUBHAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9401"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "NEW EKA MAGIC",
      "ALAMAT": "JL.GARUDA LABUH BARU NO.24, PEKANBARU",
      "TELEPHONE": 82389834108,
      "EMAIL": "neweka_magic@yahoo.com",
      "PIC": "IBU DESI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9402"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "EKA PRIMA",
      "ALAMAT": "JL.JEND.SUDIRMAN NO.203, PEKANBARU",
      "TELEPHONE": 76129605,
      "EMAIL": "adm.ekaprima@hotmail.com",
      "PIC": "IBU DEDEK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9403"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "TRIPLE-R",
      "ALAMAT": "JL.SM AMIN NO. 77, PEKANBARU",
      "TELEPHONE": 7617777778,
      "EMAIL": "sa_ramamotor@yahoo.co.id",
      "PIC": "IBU MALA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9404"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "REGENT JAYA",
      "ALAMAT": "JL.KULIM RIAU NO. 213, PEKANBARU",
      "TELEPHONE": 761839009,
      "EMAIL": "regent_jaya@yahoo.com",
      "PIC": "IBU FITRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9405"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "CAPELA BODY CENTER",
      "ALAMAT": "JL.TERUBUK NO.1-2, PEKANBARU",
      "TELEPHONE": 76147799,
      "EMAIL": "cbcpekanbaru2@gmail.com",
      "PIC": "IBU SHINTA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9406"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "ARENGKA PRATAMA",
      "ALAMAT": "JL.SUKA JAYA ARENGKA 1 NO.28 B, PEKANBARU",
      "TELEPHONE": 7617766000,
      "EMAIL": "arengkapratama@ymail.com",
      "PIC": "IBU EMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9407"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "CIPTA KARYA MOBIL",
      "ALAMAT": "JL.GARUDA LABUH BARU NO.44, PEKANBARU",
      "TELEPHONE": 76161494,
      "EMAIL": "ckm.bengkel@yahoo.com",
      "PIC": "IBU RINA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9408"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "VINSA BODY REPAIR",
      "ALAMAT": "JL.SOEKARNO-HATTA/ARENGKA 1 NO. 15, PEKANBARU",
      "TELEPHONE": 761431111,
      "EMAIL": "vinsabodyrepair@yahoo.com",
      "PIC": "IBU LAKSMI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9409"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "LEO OTOMOTIF PERKASA",
      "ALAMAT": "JL.NANGKA UJUNG NO.168, PEKANBARU",
      "TELEPHONE": 761859030,
      "EMAIL": "pekanbaruleo_otomotif@yahoo.com",
      "PIC": "IBU DESI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9410"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "HONDA SM AMIN",
      "ALAMAT": "JL.SM AMIN NO. 168, PEKANBARU",
      "TELEPHONE": 7618418517,
      "EMAIL": "bodypaint.hsa@gmail.com",
      "PIC": "BAPAK JAY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9411"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "DELTA DURI",
      "ALAMAT": "JL.HANGTUAN NO.309-311, DURI RIAU",
      "TELEPHONE": 765598389,
      "EMAIL": "delta_duri@yahoo.co.id",
      "PIC": "IBU NYTA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9412"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "DUMAI AUTO PERKASA",
      "ALAMAT": "JL. INPRES NO.9A/B DUMAI BARAT",
      "TELEPHONE": 81267405005,
      "EMAIL": "dumai_autoperkasa@yahoo.com",
      "PIC": "BAPAK MICKEL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9413"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "WAHANA WIRAWAN RIAU",
      "ALAMAT": "JL.SM AMIN-RINGROAD PEKANBARU",
      "TELEPHONE": 81268766224,
      "EMAIL": "collectioncontrol.smamin@nissan.indomobil.co.id",
      "PIC": "IBU FEBRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9414"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "PT. SEJAHTERA BUANA TRADA",
      "ALAMAT": "JL. SM AMIN NO. 89",
      "TELEPHONE": "0761-8415989",
      "EMAIL": "sbtpkubodypaint@gmail.com",
      "PIC": "BPK. SUDARSONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9415"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "CAPELA BODY CENTRE DURI",
      "ALAMAT": "JL. LINTAS DURI-DUMAI KM 3.5 DURI",
      "TELEPHONE": "",
      "EMAIL": "cbcduri@gmail.com",
      "PIC": "IBU RAHMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9416"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "MITSUBISHI DIPO INTERNASIONAL PAHALA OTOMOTIF",
      "ALAMAT": "JL. JEND. SUDIRMAN NO. 230",
      "TELEPHONE": 82390390585,
      "EMAIL": "pekanbaru.br@sbmgrp.com",
      "PIC": "BAPAK FAISAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9417"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Eka Prima",
      "ALAMAT": "Jl. Jend. Sudirman No. 203",
      "TELEPHONE": "T: 0761-29605, 0761-33293, F: 0761-33192",
      "EMAIL": "adm.ekaprima@hotmail.com",
      "PIC": "Teddy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3483"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Jasa Barutama Perkasa",
      "ALAMAT": "Jl. Tuanku Tambusai d/h Nangka No. 42",
      "TELEPHONE": "T: 0761-61649, F: 0761-61647",
      "EMAIL": "jasabarutama@yahoo.com",
      "PIC": "Ita / Ivan/maya",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3484"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Leo Otomotif Perkasa",
      "ALAMAT": "Jl. Nangka Ujung No. 168",
      "TELEPHONE": "T: 0761-4877800, 0761-859030, F: 0761-4877889",
      "EMAIL": "pekanbaru@leo-otomotif.com",
      "PIC": "Badrul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3485"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Regent Jaya",
      "ALAMAT": "Jl. Kulim No. 213",
      "TELEPHONE": "T: 0761-839009, 0853-56630698, F: 0761-857618",
      "EMAIL": "regent_jaya@yahoo.com",
      "PIC": "Tasya / Linda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3486"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Vinsa Indo Sejahtera",
      "ALAMAT": "Jl. Soekarno Hatta No. 39",
      "TELEPHONE": "T: 0761-848027, 082386445452,085360447766 F: 0761-839621",
      "EMAIL": "vinsabodyrepair@yahoo.com",
      "PIC": "Agus",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3487"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Prima Jaya Motor",
      "ALAMAT": "Jl. Tekam Gg. Abdullah No. 006 Perumnas III Tanjung Hulu",
      "TELEPHONE": "T: 0561-749238, 085845672985, F: 0561-749238",
      "EMAIL": "pjm_mobil@yahoo.com",
      "PIC": "Ibu Riri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3488"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "PT Capella Medan - Pekanbaru",
      "ALAMAT": "Jl. Soekarno Hatta No. 53 Pekanbaru",
      "TELEPHONE": "T : 0761-571900",
      "EMAIL": "bangun.salam@yahoo.co.id; wilson.kodrianto@gmail.com ; cm.ws7.bonapardamean@gmail.com ; cm.ws7.habiburrahman@gmail.com ; cm.ws7.darman@gmail.com ; cm.ws7.wahidtambunan@gmail.com",
      "PIC": "Novi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3489"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Agung Automall - Pekanbaru",
      "ALAMAT": "Jl. SM. Amin No. 13",
      "TELEPHONE": "T: 0761-565888, F: 0761-565999",
      "EMAIL": "syam_aam@yahoo.com",
      "PIC": "Iren / Soe",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3490"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Honda Arista",
      "ALAMAT": "Jl. Jend. Sudirman No. 228",
      "TELEPHONE": "T: 0761-45699",
      "EMAIL": "daniel_barus98@yahoo.com",
      "PIC": "Eko",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3491"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Honda SM. Amin (PT. Kerta Jaya Utama)",
      "ALAMAT": "Jalan SM Amin No.168, Air Hitam, Payung Sekaki, Simpang Baru, Tampan - Pekanbaru",
      "TELEPHONE": "T:   : 0761-8418897 ; 081372357573",
      "EMAIL": "bodypaint.hsa@gmail.com;sam_mike77@yahoo.com",
      "PIC": "Indro / David",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3492"
    },
    {
      "WILAYAH": "Pekanbaru",
      "NAMA BENGKEL": "Suzuki Pekanbaru (PT. Sejahtera Buana Trada)",
      "ALAMAT": "Jl. SM Amin No. 89 Rt.05 Rw. 01 ,Kel. Simpang Baru, Kec. Tampan - Pekan Baru",
      "TELEPHONE": "0822-8535-8090",
      "EMAIL": "darmanto.suzuki@gmail.com",
      "PIC": "Eddy / Sri/Ibu Farida",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3493"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Auto Blitz",
      "ALAMAT": "Jl. Harapan Utama No. 1 Srikandi, Pekanbaru",
      "TELEPHONE": "0761 - 8416888",
      "EMAIL": "auto.blitzz@gmail.com",
      "PIC": "Shelly",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310936"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Abadi Jaya Prima",
      "ALAMAT": "Jl. Yos Sudarso KM. 6, Rumbai, Pekanbaru",
      "TELEPHONE": "0853 55510877",
      "EMAIL": "ajp.autocare@gmail.com",
      "PIC": "Deddy Tandra",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310937"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Leo Otomotif",
      "ALAMAT": "Jl. Nangka Ujung No.168, Pekanbaru",
      "TELEPHONE": "0761 - 853030",
      "EMAIL": "pekanbaru@leo_otomotif.com",
      "PIC": "Aliong",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310938"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Regent Jaya",
      "ALAMAT": "Jalan Kulim No 213, Pekanbaru",
      "TELEPHONE": "(0761) 8403731",
      "EMAIL": "regent_jaya@yahoo.com",
      "PIC": "Hasan Susanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310939"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Honda SM Amin",
      "ALAMAT": "Jl.  SM Amin No. 168, Pekanbaru",
      "TELEPHONE": "0761 - 8418517",
      "EMAIL": "alfarisilubis@yahoo.com",
      "PIC": "Alfarisi Lubis",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310940"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Honda Arista Sudirman",
      "ALAMAT": "Jl. Jend. Sudirman No. 228, Pekanbaru",
      "TELEPHONE": "0761 - 45 999",
      "EMAIL": "info@arista-group.co.id",
      "PIC": "Service Advisor",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310941"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Suzuki Sejahtera Buana trada",
      "ALAMAT": "JL. SM.AMIN NO. 89 RT.05 RW.01 KEL. SIMPANG BARU KEC. TAMPAN PEKANBARU",
      "TELEPHONE": "(0761) 8415989, 8415985",
      "EMAIL": "sbtpkubodypaint@gmail.com",
      "PIC": "Sudarsono",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310942"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Dumai Auto Perkasa",
      "ALAMAT": "Jl. Inpres No. 9A/B, Dumai Barat, Riau",
      "TELEPHONE": "0765 - 4370220",
      "EMAIL": "dumai_autoperkasa@yahoo.com",
      "PIC": "Dedi/ Susanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310943"
    },
    {
      "WILAYAH": "PEKANBARU",
      "NAMA BENGKEL": "Primatama",
      "ALAMAT": "Jl. By Pass KM 6 Parak Karakah, Padang",
      "TELEPHONE": "0751 - 779701",
      "EMAIL": "primatama.bodypaint@yahoo.com",
      "PIC": "Herman Sutanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310944"
    },
    {
      "WILAYAH": "Pematang Siantar",
      "NAMA BENGKEL": "PT Capella Medan - Pematang Siantar",
      "ALAMAT": "Jl. Raya Medan Km. 6 Simpang Karang Sari, Pematang Siantar",
      "TELEPHONE": "T : 0622-23765",
      "EMAIL": "bangun.salam@yahoo.co.id ; cm.salikgunarto@capellagroup.com ; surahman.widodo@yahoo.com ; jentar_p@yahoo.com",
      "PIC": "Mulyadi/Ibu Lily/Bida",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3494"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "PRIMA JAYA MOTOR",
      "ALAMAT": "Jl. Tekam Gg.Abdullah No.006",
      "TELEPHONE": "HP. 08125611986",
      "EMAIL": "-",
      "PIC": "Bpk.Djoni",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6379"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "WILTRA BODIY REPAIR",
      "ALAMAT": "Jl. M. Sohor Gg.H. Siradj No.37",
      "TELEPHONE": "(0561) 744517",
      "EMAIL": "-",
      "PIC": "Bpk. Ryandi Lo / Asong",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6380"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "BENGKEL CERIA/ANEN",
      "ALAMAT": "Jl. Husein Hamzah no.3,",
      "TELEPHONE": "HP. 08125616766",
      "EMAIL": "-",
      "PIC": "Bpk. Anen",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6381"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "SINAR SURYA JAYA",
      "ALAMAT": "Jl. Tani Gg.Padi No.111",
      "TELEPHONE": "HP. 081256027333",
      "EMAIL": "-",
      "PIC": "-",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6382"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "BENGKEL WAHANA SERVICE",
      "ALAMAT": "Jl. GM. Situt no. 43,",
      "TELEPHONE": "HP. 081256222228",
      "EMAIL": "-",
      "PIC": "Bpk. Benny",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6383"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "KARYA ANUGRAH GEMILANG",
      "ALAMAT": "Jl. H. Agus Salim - Gg. Rasi 2",
      "TELEPHONE": "HP. 0852 4563 3210",
      "EMAIL": "-",
      "PIC": "Bpk. Setiadi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6384"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "AUTO BONG",
      "ALAMAT": "JL. AMPERA NO 1-2 PONTIANAK",
      "TELEPHONE": "0561-8100888",
      "EMAIL": "cv_autobong@yahoo.com",
      "PIC": "HERIANTO BONG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9418"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "SINAR BERKAT",
      "ALAMAT": "JL. SUNGAI RAYA DALAM, PONTIANAK",
      "TELEPHONE": "0561-6714877",
      "EMAIL": "sinar_berkat12@yahoo.com",
      "PIC": "SETIAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9419"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "WAHANA SERVIS",
      "ALAMAT": "JL. GM. SITUT NO. 43 KELURAHAN PASIRAN KAB. SINGKAWANG, KALIMANTAN BARAT",
      "TELEPHONE": "",
      "EMAIL": "wahana.tc@gmail.com",
      "PIC": "TYAZ",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9420"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "SUKSES MANDIRI MOBIL",
      "ALAMAT": "JL. MT HARYONO KM 3 KAB. SINTANG, KALIMANTAN BARAT",
      "TELEPHONE": "0565-2022628",
      "EMAIL": "sintangsuksesmandirimobil@gmail.com",
      "PIC": "HENDRA BONARDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9421"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "SUMBER JADI",
      "ALAMAT": "JL. MAYJEND SUTOYO NO 89, DELTA PAWAN KAB. KETAPANG, KALIMANTAN BARAT",
      "TELEPHONE": "",
      "EMAIL": "sumberjadibengkel@yahoo.com",
      "PIC": "HENDRA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9422"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "MITRA SUZUKI SAMPIT",
      "ALAMAT": "JL. JENDRAL SUDIRMAN KM 2,7 SAMPIT, KALIMANTAN TENGAH",
      "TELEPHONE": "0531-2060067",
      "EMAIL": "helda.sarita@mitrasuzuki.com",
      "PIC": "HELDA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9423"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "MURSITO BENGKEL",
      "ALAMAT": "JL. MATNOR RT 19, PANGKALAN BUN KALIMANTAN TENGAH",
      "TELEPHONE": "",
      "EMAIL": "mursitobengkel@gmail.com",
      "PIC": "MURSITO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9424"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "IKI BENGKEL",
      "ALAMAT": "JL. KAWITAN NO. 07 PANGKALAN BUN KALIMANTAN TENGAH",
      "TELEPHONE": "",
      "EMAIL": "ikibengkel_pbun@yahoo.co.id",
      "PIC": "ARIFIN BUDI RUSWANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9425"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "DAYA MOTOR",
      "ALAMAT": "JL. A. YANI NO. 115 PONTIANAK",
      "TELEPHONE": "(0561)762072",
      "EMAIL": "srv.part.dayamotorhonda@gmail.com",
      "PIC": "HANY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9426"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "KARYA ANUGRAH GEMILANG",
      "ALAMAT": "JL. AGUS SALIM GG.RASI 2 KAB KETAPANG - KALIMANTAN BARAT",
      "TELEPHONE": "",
      "EMAIL": "karyaanugrahgemilang@gmail.com",
      "PIC": "BAPAK RIKI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9427"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "SUMBER JAYA MOTOR",
      "ALAMAT": "JL. KENAN SANDAN RT.40/RW07 SAMPIT KALIMANTAN TENGAH",
      "TELEPHONE": "",
      "EMAIL": "sjmsampit@yahoo.com",
      "PIC": "BP. IRIZZA NUZULIRAHMAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9428"
    },
    {
      "WILAYAH": "PONTIANAK",
      "NAMA BENGKEL": "KARYA WARNA",
      "ALAMAT": "JL. PUTRI DARA HITAM NO. 17 B PONTIANAK, KALIMANTAN BARAT",
      "TELEPHONE": "0561-8103953",
      "EMAIL": "karya.warna.ptk@gmail.com",
      "PIC": "MULYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9429"
    },
    {
      "WILAYAH": "Pontianak",
      "NAMA BENGKEL": "Auto Mobil Jaya",
      "ALAMAT": "JL. Purnama 2",
      "TELEPHONE": "T: 0561-731382",
      "EMAIL": "automobiljaya@gmail.com",
      "PIC": "Ferrid",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3495"
    },
    {
      "WILAYAH": "Purwakarta",
      "NAMA BENGKEL": "Emerald Body Repair & Auto Detailing",
      "ALAMAT": "Jl. Raya Bungur sari No. 149 , Cibening Purwakarta",
      "TELEPHONE": "T : 0264 - 205083",
      "EMAIL": "emeraldautocare.pwk@gmail.com",
      "PIC": "Pak Ryan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3496"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "NASMOCO PURBALINGGA (TOYOTA)",
      "ALAMAT": "JL. SOEKARNO HATTA NO. 17, PURBALINGGA",
      "TELEPHONE": "(0281) 68901888",
      "EMAIL": "kabengpurwo@yahoo.com",
      "PIC": "BP. DIDIK / BP. RICKI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9430"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "NASMOCO CILACAP (TOYOTA)",
      "ALAMAT": "JL. MT. HARYONO NO. 81, CILACAP",
      "TELEPHONE": "(0282) 533200",
      "EMAIL": "bpcilacap@yahoo.co.id",
      "PIC": "BP. GAYUH / BP. ZAIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9431"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "ARMADA INTERNATIONAL MOTOR DAIHATSU (DAIHATSU)",
      "ALAMAT": "JL. GERILYA TIMUR NO. 28, PURWOKERTO",
      "TELEPHONE": "(0281) 625111",
      "EMAIL": "satiman.aim@gmail.com",
      "PIC": "BP. SATIMAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9432"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "ARMADA INTERNATIONAL MOTOR ISUZU (UMUM)",
      "ALAMAT": "JL. GERILYA TIMUR NO. 28, PURWOKERTO",
      "TELEPHONE": "(0281) 625333",
      "EMAIL": "nunung.sukarno@gmail.com",
      "PIC": "BP. NUNUNG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9433"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "C-MAESTRO PUTRA PURWOKERTO (UMUM)",
      "ALAMAT": "JL. KH. WAHID HASYIM NO 88 PURWOKERTO",
      "TELEPHONE": "(0281) 7771636",
      "EMAIL": "cmaestro.purwokerto@gmail.com",
      "PIC": "BP. AGUNG",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9434"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "LAPINA (UMUM)",
      "ALAMAT": "JL. PRAMUKA RT 7 RW 2, PURWOKERTO",
      "TELEPHONE": "",
      "EMAIL": "bengkel_lapina@ymail.com",
      "PIC": "BP. RAHMAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9435"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "BERDIKARI (UMUM)",
      "ALAMAT": "JL. JEND. GATOT SUBROTO NO 113 BANYUMAS",
      "TELEPHONE": "(0281) 796500",
      "EMAIL": "wahyup@berdikaribodyshop.com",
      "PIC": "BP. WAHYU",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9436"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "AUTO 168 (UMUM)",
      "ALAMAT": "JL. JEND. GATOT SUBROTO NO. 27 SOKARAJA, BANYUMAS",
      "TELEPHONE": "(0281) 6441688",
      "EMAIL": "auto168_cs@yahoo.com",
      "PIC": "BP. KHOLIK",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9437"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "SUMBER BARU GLOBAL (HONDA)",
      "ALAMAT": "JL. SUPARJO RUSTAM NO 109, SOKARAJA BANYUMAS",
      "TELEPHONE": "(0281) 6512667",
      "EMAIL": "bp.hondasumberglobal@gmail.com",
      "PIC": "BP. SYAEFUL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9438"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "HONDA ISTANA CARINDO (HONDA)",
      "ALAMAT": "JL. S. PARMAN NO 1551, PURWOKERTO",
      "TELEPHONE": "(0281) 632966",
      "EMAIL": "honda_istanacarindo@yahoo.com",
      "PIC": "BP. JOKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9439"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "LARAS MOTOR CILACAP",
      "ALAMAT": "JL. PERINTIS KEMERDEKAAN NO 35 CILACAP",
      "TELEPHONE": 8164286921,
      "EMAIL": "larasmotorcilacap@gmail.com",
      "PIC": "BPK. WALUYO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9440"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "NEW MAGELANG PUTRA",
      "ALAMAT": "BOJONG RT 001 RW 003, PURBALINGGA",
      "TELEPHONE": "",
      "EMAIL": "bengkelnewmagelangputra@yahoo.co.id",
      "PIC": "BPK. SUTIKNO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9441"
    },
    {
      "WILAYAH": "Purwokerto",
      "NAMA BENGKEL": "Nasmoco Purwokerto",
      "ALAMAT": "Jalan Gerilya No. 56, Tanjung, Purwokerto Selatan, Purwokerto Kidul,  Banyumas",
      "TELEPHONE": "T : 0281 -  634436",
      "EMAIL": "kabengpurwo@yahoo.com",
      "PIC": "Galuh",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3497"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "New Magelang Putra",
      "ALAMAT": "JL Raya Bojong, Km 5 RT 2 RW 3, Bojong, Kec. Purbalingga, Kabupaten Purbalingga, Jawa Tengah 53319",
      "TELEPHONE": "�085747860222",
      "EMAIL": "bengkelnewmagelangputra@yahoo.co.id",
      "PIC": "Puji 085726270910",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310945"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "C Maestro",
      "ALAMAT": "Jl. KH Wahid Hasyim No.88 Purwokerto Selatan",
      "TELEPHONE": "",
      "EMAIL": "cmaestro.purwokerto@gmail.com",
      "PIC": "Agung 085227577755",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310946"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Auto 168",
      "ALAMAT": "Jl. Jend. Gatot Subroto No.27, Karangjengkol, Sokanegara, Kec. Purwokerto Tim., Kabupaten Banyumas, Jawa Tengah 53116",
      "TELEPHONE": "(0281) 6441688",
      "EMAIL": "auto168_cs@yahoo.com",
      "PIC": "Retno 085727144254 Kholiq 08562608168",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310947"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Nasmoco Purwokerto",
      "ALAMAT": "Jalan Gerilya Timur No.56 berkoh, Karanganyar, Purwokerto Kidul, South Purwokerto, Banyumas Regency, Central Java 53146",
      "TELEPHONE": "�081226600555",
      "EMAIL": "kabengpurwo@yahoo.com",
      "PIC": "Karso 081548845990",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310948"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Nasmoco Purbalingga",
      "ALAMAT": "Jl. Soekarno Hatta No.KM 2, Karangsambang, Kalikabong, Kalimanah, Kabupaten Purbalingga, Jawa Tengah 53321",
      "TELEPHONE": "�081326242300",
      "EMAIL": "kabengpurwo@yahoo.com ; bodyrepairnaspbg@gmail.com",
      "PIC": "Didik 085642401211",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310949"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Nasmoco Cilacap",
      "ALAMAT": "Jl. MT. Haryono No.81, Cidapur, Donan, Cilacap Tengah, Kabupaten Cilacap, Jawa Tengah 53222",
      "TELEPHONE": "(0282) 533200",
      "EMAIL": "zakinafsiyah@yahoo.com ; bpcilacap@yahoo.co.id ; zain_nasmoco@yahoo.com",
      "PIC": "Zakiyatun Nafsiyah 085647744220 / Zainuri 08995535418",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310950"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Honda Istana Carindo",
      "ALAMAT": "Jalan S. Parman No.1551, RT/RW. 04/07, Karangbawang, Purwokerto Kulon, Kec. Purwokerto Sel., Kabupaten Banyumas, Jawa Tengah 53141",
      "TELEPHONE": "�(0281) 632966",
      "EMAIL": "istanacarindobp@gmail.com",
      "PIC": "Joko 08112590206",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310951"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "Sumber Baru Global",
      "ALAMAT": "Jl. Suparjo Rustam No.109, Dusun III, Sokaraja Kulon, Kec. Sokaraja, Kabupaten Banyumas, Jawa Tengah 53181",
      "TELEPHONE": "(0281) 6512666",
      "EMAIL": "bp.hondasumberglobal@gmail.com",
      "PIC": "Kris 08157669972",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310952"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "NASMOCO",
      "ALAMAT": "Jl.Gerilya Timur No.56",
      "TELEPHONE": "(0281) 634436",
      "EMAIL": "-",
      "PIC": "Bpk.Abrori / Bpk.Tarsono",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6385"
    },
    {
      "WILAYAH": "PURWOKERTO",
      "NAMA BENGKEL": "AUTO 168",
      "ALAMAT": "Jl. Gatot Subroto Ruko No. 27",
      "TELEPHONE": "(0281) - 7633178",
      "EMAIL": "-",
      "PIC": "Bpk.Tarsono,Abrori",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6386"
    },
    {
      "WILAYAH": "Rantau Prapat",
      "NAMA BENGKEL": "Tata Raya Auto Shop",
      "ALAMAT": "Jl. SM. Raja No. 178",
      "TELEPHONE": "T: 0624-7671788",
      "EMAIL": "tras1788@gmail.com",
      "PIC": "Tandiyo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3498"
    },
    {
      "WILAYAH": "Riau",
      "NAMA BENGKEL": "PT Capella Medan - Duri",
      "ALAMAT": "Jl. Raya Duri � Dumai Km. 3,5, Depan Gate 125 DSF, Duri, Riau",
      "TELEPHONE": "T : 0765-92784",
      "EMAIL": "bangun.salam@yahoo.co.id ; \nchokysimorangkir@yahoo.co.id ; \ncm.duri.sa2@capellagroup.com ; \ncm.duri.karu2@capellagroup.com",
      "PIC": "Eddy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3499"
    },
    {
      "WILAYAH": "SALATIGA",
      "NAMA BENGKEL": "NASMOCO",
      "ALAMAT": "Jl.Diponegoro 171",
      "TELEPHONE": "(0298) - 327388",
      "EMAIL": "-",
      "PIC": "Bpk.Bambang/Bpk.M.Muin",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6387"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "BENGKEL SAB",
      "ALAMAT": "Jl. KH. Wahid Hasyim II No.34 A",
      "TELEPHONE": "(0541) 6252358",
      "EMAIL": "-",
      "PIC": "Bpk.Larry",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6388"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "TRINITY AUTO",
      "ALAMAT": "Jl. Bung Tomo No.49 Rt.11",
      "TELEPHONE": "(0541) 262800",
      "EMAIL": "-",
      "PIC": "Ibu Risma",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6389"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "SUMBER 168",
      "ALAMAT": "JL. DI PANJAITAN NO 49, RT 001 MUGIREJO, SAMARINDA",
      "TELEPHONE": "0541-280770",
      "EMAIL": "bengkel.sumber168@yahoo.co.id",
      "PIC": "PUJI / HERMAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9442"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "MGT MOTOR (KHUSUS TRUCK)",
      "ALAMAT": "JL. PANGERAN SURYANATA NO 99, SAMARINDA",
      "TELEPHONE": "0541-290333",
      "EMAIL": "mgtmotor_smrd@yahoo.co.id",
      "PIC": "SUKISMAN/ MARNO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9443"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "TOMMY BODY WORKS",
      "ALAMAT": "JL. JUANDA KOMPLEX WIJAYA KUSUMA 2 NO. 6, SAMARINDA",
      "TELEPHONE": "0541-7773888",
      "EMAIL": "tommybodyworks@gmail.com",
      "PIC": "TINA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9444"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "TRINITY AUTO",
      "ALAMAT": "JL. BUNG TOMO NO. 49 RT.11, SAMARINDA SEBERANG",
      "TELEPHONE": "0541-262800",
      "EMAIL": "trinity_auto1@yahoo.com",
      "PIC": "SHANLIJAYA/ WATI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9445"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "PT. SERBA MULIA AUTO- DAIHATSU",
      "ALAMAT": "JL. PM NOOR NO. 8 SEMPAJA, SAMARINDA",
      "TELEPHONE": "0541-221000",
      "EMAIL": "bodyrepair.smd@serbamulia.com",
      "PIC": "ARMANSYAH/ NOORHARYANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9446"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "BENGKEL  AUTO SEJAHTERA",
      "ALAMAT": "JL. DI PANJAITAN NO 36 RT 17, SAMARINDA",
      "TELEPHONE": "0541-281813",
      "EMAIL": "autosejahtera.smrd@gmail.com",
      "PIC": "WINARDI TANDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9447"
    },
    {
      "WILAYAH": "SAMARINDA",
      "NAMA BENGKEL": "SINAR BORNEO SAMARINDA",
      "ALAMAT": "JL. K.H. WAHID HASYIM, GG. SALAM NO. 99 A",
      "TELEPHONE": "0541-4115027",
      "EMAIL": "sbs.bodyshop@gmail.com",
      "PIC": "AHMAD/ AZIZ/ NISA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9448"
    },
    {
      "WILAYAH": "Samarinda",
      "NAMA BENGKEL": "Putra Kaltim",
      "ALAMAT": "Jl.Pangeran M. Noor No. 09 Rt. 039 Sempaja",
      "TELEPHONE": "T: 0541-220512, 0811551273",
      "EMAIL": "general@putrakaltim.co.id",
      "PIC": "Nurhadi / Yayan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3500"
    },
    {
      "WILAYAH": "Samarinda",
      "NAMA BENGKEL": "Auto 2000 - Samarinda",
      "ALAMAT": "Jl. KH. Wahid Hasyim No 99 Sempaja, Samarinda",
      "TELEPHONE": "T : 0541-742000",
      "EMAIL": "virca.destiasari@tso.astra.co.id",
      "PIC": "Eddy / Sahrul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3501"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "TEJO MOTORS",
      "ALAMAT": "Jl. Pedurungan Kidul No.82",
      "TELEPHONE": "(024) 6711001",
      "EMAIL": "-",
      "PIC": "Bpk. Sutejo / Fauzan",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6390"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "UTAMA SAKTI",
      "ALAMAT": "Jl. Majapahit No. 254",
      "TELEPHONE": "(024) - 6719539",
      "EMAIL": "-",
      "PIC": "Bpk. Yudhi Heryanto / Ibu Ratmi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6391"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "EKA AUTO",
      "ALAMAT": "Jl. Arteri Sukarno Hatta No.18",
      "TELEPHONE": "(024) - 76581111",
      "EMAIL": "-",
      "PIC": "Bpk. Sakimo / Bobby / Suryani",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6392"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DODDY JAYA",
      "ALAMAT": "Jl. Palebon Raya No. 2A",
      "TELEPHONE": "(024) - 6723565",
      "EMAIL": "-",
      "PIC": "Bpk. Doddy Judo Handoko",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6393"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SERODJA",
      "ALAMAT": "Jl. Bandarharjo Selatan No. 12.A",
      "TELEPHONE": "(024) - 3546567",
      "EMAIL": "-",
      "PIC": "Bpk. Agus Waskito / Kumaidi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6394"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SURYA INDAH MOTOR, CV",
      "ALAMAT": "JL RAYA KUDUS - PATI KM 4 NGEMBALREJO KUDUS",
      "TELEPHONE": 62291433215,
      "EMAIL": "badrus.toyotakudus@yahoo.com",
      "PIC": "BP MUNA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9449"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "PRATAMA JAYA MANDIRI",
      "ALAMAT": "JL LINGKAR KUDUS-PATI KM 2 KUDUS",
      "TELEPHONE": "6,29E+12",
      "EMAIL": "pjm.pratama@gmail.com",
      "PIC": "GIRI WICAKSONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9450"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SETARA",
      "ALAMAT": "JL PENDOWO GANG PANJANG NO 414, MLATI KUDUS",
      "TELEPHONE": 6291430229,
      "EMAIL": "setara_mdk@yahoo.co.id",
      "PIC": "MOCH MUDHAKIR",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9451"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "HONDA KUDUS MOTOR",
      "ALAMAT": "JL LINGKAR SELATAN NO. 168 TIMUR TERMINAL KUDUS",
      "TELEPHONE": 62291445666,
      "EMAIL": "bodyrepair01.hondakudusjya@gmail.com",
      "PIC": "FINDRY",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9452"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BEST AUTO",
      "ALAMAT": "JL HOS COKROAMINOTO NO 1A KUDUS",
      "TELEPHONE": 62291432427,
      "EMAIL": "bestauto427@yahho.co.id",
      "PIC": "TRISNO MULJONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9453"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "NISSAN JATI KUDUS",
      "ALAMAT": "JL AKBP AGIL KUSUMADYA KM2, JATI WETAN JATI KUDUS",
      "TELEPHONE": 62291430588,
      "EMAIL": "hadimunarko@nissanindomobil.com",
      "PIC": "HADI MUNARKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9454"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "NASMOCO KALIGAWE",
      "ALAMAT": "JL. RAYA KALIGAWE KM 5,",
      "TELEPHONE": 6224658100,
      "EMAIL": "kebengbpg3w@yahoo.com",
      "PIC": "BUDI PRAMONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9455"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "NASMOCO KARANGJATI",
      "ALAMAT": "JL. SOEKARNO HATTA KM 26",
      "TELEPHONE": "6,23E+11",
      "EMAIL": "nasmocomuin@gmail.com",
      "PIC": "MUH MUIN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9456"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "PT ISTANA KUSUMA INDAH MOTOR",
      "ALAMAT": "JL SEBANDARAN 51",
      "TELEPHONE": 62243540728,
      "EMAIL": "honda.kusuma@yahoo.com",
      "PIC": "FRANSISCO KUSUMA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9457"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "MANDALATAMA ARMADA MOTOR",
      "ALAMAT": "JL. SETIABUDI  124",
      "TELEPHONE": 62247498777,
      "EMAIL": "body_repair@hondasemarangcenter.com",
      "PIC": "SUMANTRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9458"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SALATIGA JAYA MOTOR",
      "ALAMAT": "JL. DIPONEGORO 112",
      "TELEPHONE": 62298329222,
      "EMAIL": "servicehondasl3@telkom.net",
      "PIC": "KRISNA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9459"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "ASTRA INTERNASIONAL - ISUZU",
      "ALAMAT": "JL. MAJAPAHIT 117, SEMARANG",
      "TELEPHONE": 62246722000,
      "EMAIL": "khomaidi@iso.astra.co.id",
      "PIC": "KHOMAIDI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9460"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SIDODADI BERLIAN MOTOR",
      "ALAMAT": "JL. SILIWANGI 287A",
      "TELEPHONE": 62247603957,
      "EMAIL": "heru_rubiyanto@yahoo.com",
      "PIC": "WIDODO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9461"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "WAHANA SUN MOTOR SEMARANG - SETIABUDI",
      "ALAMAT": "JL. SETIABUDI 144",
      "TELEPHONE": "6,22E+11",
      "EMAIL": "andika.permana@nissan.indomobil.co.id",
      "PIC": "ANDIKA PERMANA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9462"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "WAHANA SUN MOTOR SALATIGA",
      "ALAMAT": "JL. SEMARANG-SURAKARTA KM 10, CEBONGAN, ARGOMULYA, SALATIGA",
      "TELEPHONE": "6,23E+11",
      "EMAIL": "ludi.zaki@nissan.indomobil.co.id",
      "PIC": "LUDI ZAKI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9463"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "KARYA ZIRANG UTAMA - SUDIRMAN",
      "ALAMAT": "JL. JEND SUDIRMAN 182, SEMARANG",
      "TELEPHONE": 62247601243,
      "EMAIL": "kzu.agoessl@gmail.com",
      "PIC": "AHMAD SUKRON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9464"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "KARYA ZIRANG UTAMA - DR CIPTO",
      "ALAMAT": "JL. DR CIPTO 154-156, SEMARANG",
      "TELEPHONE": 62243583961,
      "EMAIL": "kzu.agoessl@gmail.com",
      "PIC": "AHMAD SUKRON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9465"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "KARYA ZIRANG UTAMA - SALATIGA",
      "ALAMAT": "JL OSAMALIKI 17, SALATIGA",
      "TELEPHONE": "6,23E+11",
      "EMAIL": "kzu.agoessl@gmail.com",
      "PIC": "AHMAD SUKRON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9466"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "KARYA ZIRANG UTAMA - KUDUS",
      "ALAMAT": "JL. AKBP R AGIL KUSUMADYA 22, KUDUS",
      "TELEPHONE": 62291444188,
      "EMAIL": "kzu.agoessl@gmail.com",
      "PIC": "AHMAD SUKRON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9467"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "KARYA ZIRANG UTAMA -ISUZU",
      "ALAMAT": "JL. AHMAD YANI, SEMARANG",
      "TELEPHONE": "",
      "EMAIL": "kzu.agoessl@gmail.com",
      "PIC": "AHMAD SUKRON",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9468"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DODDY JAYA",
      "ALAMAT": "JL. PALEBON 2A, SEMARANG",
      "TELEPHONE": "6,22E+11",
      "EMAIL": "doddyjaya.bklsmg@gmail.com",
      "PIC": "RADEN MAS DODDY JUDO HANDOKO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9469"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "TEJO MOTOR",
      "ALAMAT": "JL. FATMAWATI 82, SEMARANG",
      "TELEPHONE": 62246711001,
      "EMAIL": "tejo.motors@gmail.com",
      "PIC": "SUTEJO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9470"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "OTO ONDERDIL",
      "ALAMAT": "JL. LINGKAR SELATAN, KUDUS",
      "TELEPHONE": "6,23E+11",
      "EMAIL": "alifah@yahoo.co.id",
      "PIC": "ALIFAH",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9471"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DTM PROJECT",
      "ALAMAT": "JL. PAMULARSIH RAYA 18 SEMARANG",
      "TELEPHONE": 62247626228,
      "EMAIL": "admin@dtmproject.co.id",
      "PIC": "IBNU SAPTO ADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9472"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "MOTOREKO MOBILINDO (EKAUTO SEMARANG)",
      "ALAMAT": "JL. ARTERI SOEKARNO-HATTA NO. 18, SEMARANG",
      "TELEPHONE": "6,22E+11",
      "EMAIL": "ekautosmg@yahoo.co.id",
      "PIC": "SUYANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9473"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "GEMILANG",
      "ALAMAT": "JL. SETIABUDI 152, SEMARANG",
      "TELEPHONE": "6,28E+12",
      "EMAIL": "",
      "PIC": "ESTI SWAJATI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9474"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BUMENREDJA ABADI",
      "ALAMAT": "JL. RE MARTADINATA F1, SEMARANG",
      "TELEPHONE": "(024) 7605506",
      "EMAIL": "",
      "PIC": "YUSUF",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9475"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "RAPI GLASS",
      "ALAMAT": "JL. DR. ISMANGIL - KAV. 9 - SEMARANG",
      "TELEPHONE": "(024) 7613687 - 7613689 - (fax: 7612714)",
      "EMAIL": "",
      "PIC": "EKO HANDOYO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9476"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "NASMOCO PATI",
      "ALAMAT": "JL. RAYA PATI JUWANA KM 2.7, PATI",
      "TELEPHONE": 81217778610,
      "EMAIL": "",
      "PIC": "DENI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9477"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "MULTI SARANA MEKANIK (NUSA AUTO PAINT)",
      "ALAMAT": "JL. WALISONGO KM11 NO. 203, SEMARANG",
      "TELEPHONE": 62248662886,
      "EMAIL": "yudi.npn2@yahoo.com",
      "PIC": "YUDHI KURNIAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9478"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Autofix",
      "ALAMAT": "Jl. Majapahit No. 129 B",
      "TELEPHONE": "T: 024-6701701, F: 024-67200300",
      "EMAIL": "autofix.bp@gmail.com",
      "PIC": "Bambang Sisno/Evelyn/Riza",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3502"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Bintang Utama",
      "ALAMAT": "Jl. Wolter Monginsidi No. 45",
      "TELEPHONE": "T: 024-6710415, F: 024-6733629",
      "EMAIL": "bintang_utama45@yahoo.co.id",
      "PIC": "Anas / Yohan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3503"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "DTM Project",
      "ALAMAT": "Jl. Pamularsih Raya No. 18",
      "TELEPHONE": "T: 024-7626228, 024-76632522, F: 024-7626228",
      "EMAIL": "dtm_project@yahoo.com",
      "PIC": "Kuswanto / Imam Hambali",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3504"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Santoso",
      "ALAMAT": "Jl. Pedalangan No. 29 Pengapon",
      "TELEPHONE": "T: 024-3519739, 024-3519339, F: 024-3545592",
      "EMAIL": "bkl2san@yahoo.com",
      "PIC": "Bakri / Cahyo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3505"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Satria Jaya",
      "ALAMAT": "Jl. Raya Citarum No. 83",
      "TELEPHONE": "T: 024-3544283, F: 024-3544283",
      "EMAIL": "satria_peter@hotmail.com",
      "PIC": "Bekti / Supriyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3506"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Astra Daihatsu Semarang",
      "ALAMAT": "Jl. Majapahit 111-117",
      "TELEPHONE": "T: 024-6717111, F: 024-6704688",
      "EMAIL": "david.christian@dso.astra.co.id",
      "PIC": "Sari (Admin)",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3507"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Bumen Redja Abadi - Semarang",
      "ALAMAT": "Jl. R.E. Martadinata Kav. F1",
      "TELEPHONE": "T: 024-7605506",
      "EMAIL": "deni.setiapurnama@bra.nag.co.id",
      "PIC": "Bustanul Arifin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3508"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Nasmoco Karangjati",
      "ALAMAT": "Jl. Soekarno - Hatta KM. 26, Karangjati, Bergas, Bergas Lor, Bergas, Semarang",
      "TELEPHONE": "T : 0298 -  5200020",
      "EMAIL": "nasmocomuin@gmail.com; naskara12345@gmail.com",
      "PIC": "Bpk. Novril Kristian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3509"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Astra International - BMW",
      "ALAMAT": "Jl. Jend. Sudirman No. 295, Semarang",
      "TELEPHONE": "T : 024 - 7604666",
      "EMAIL": "yayat.suryatna@bmw.astra.co.id",
      "PIC": "wawan / Ibu Emi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3510"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Astra International - Isuzu Sales Operation",
      "ALAMAT": "Jl. Majapahit No. 117 - Semarang",
      "TELEPHONE": "T : 024 - 6722000 ; 081294121171;0821-3559-0912",
      "EMAIL": "khomaidi@iso.astra.co.id",
      "PIC": "Suparlan / Bandi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3511"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "PT. Sejahtera Sunindo Trada",
      "ALAMAT": "Jl. Pemuda No. 65 Semarang",
      "TELEPHONE": "T : 024 - 3565000; 024 � 3569333",
      "EMAIL": "subhan.sbtbp@gmail.com ; Kus.kancil@gmail.com",
      "PIC": "Mimi/Riri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3512"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "PT Kudus Jaya Motor (Honda Kudus Jaya Semarang)",
      "ALAMAT": "Jl. Lingkar Selatan No. 168 Kudus Jawa Tengah",
      "TELEPHONE": "T: 0291-445666",
      "EMAIL": "bodyrepair2020.hondakudusjaya@gmail.com",
      "PIC": "Hendra / Putri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3513"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Honda Mandalatama Semarang",
      "ALAMAT": "Jl. Setiabudi No. 124, Srondol Semarang",
      "TELEPHONE": "T: 024-7498777 / 081326666517",
      "EMAIL": "teguh_adi@hondasemarangcenter.com",
      "PIC": "Yanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3514"
    },
    {
      "WILAYAH": "Semarang",
      "NAMA BENGKEL": "Nasmoco  Kaligawe",
      "ALAMAT": "Jl. Raya Kaligawe Km. 5 Semarang",
      "TELEPHONE": "T : 024 -  76450689 / 6580100",
      "EMAIL": "riyandafasa@gmail.com",
      "PIC": "Fitri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3515"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "OTO ONDERDIL - SEMARANG",
      "ALAMAT": "JL. BERUANG RAYA NO. 23 SEMARANG",
      "TELEPHONE": "024 - 6704244",
      "EMAIL": "alifah_oto@yahoo.com",
      "PIC": "IBU ALIFAH",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310953"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "OTO ONDERDIL - KUDUS",
      "ALAMAT": "JL. LINGKAR SELATAN KUDUS - PATI",
      "TELEPHONE": "0291 - 4247323",
      "EMAIL": "otoonderdil.kudus@yahoo.com",
      "PIC": "BP. DARWINTO",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310954"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DODDY JAYA",
      "ALAMAT": "JL. PALEBON RAYA NO. 2A SEMARANG",
      "TELEPHONE": "024 - 6723565",
      "EMAIL": "doddyjaya.bklsmg@gmail.com",
      "PIC": "BP. MADTORI",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310955"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DION PRATAMA",
      "ALAMAT": "JL. SUPRIYADI NO. 58A SEMARANG",
      "TELEPHONE": "024 - 6732034",
      "EMAIL": "dionpratama_smg@yahoo.com",
      "PIC": "IBU NINA",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310956"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "AUTOFIX",
      "ALAMAT": "JL. BRIGJEN SUDIARTO NO. 129B SEMARANG",
      "TELEPHONE": "024 - 6701701",
      "EMAIL": "autofix.bp@gmail.com",
      "PIC": "BP. SUNARTO",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310957"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "MAESTRO",
      "ALAMAT": "JL. FATMAWATI NO. 270 KETILENG SEMARANG",
      "TELEPHONE": "024 - 6714107",
      "EMAIL": "bengkel_maestro@yahoo.co.id",
      "PIC": "IBU WIWIN",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310958"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "DTM PROJECT",
      "ALAMAT": "JL. PAMULARSIH NO. 18 SEMARANG",
      "TELEPHONE": "024 - 7626228",
      "EMAIL": "dtm_project@yahoo.com",
      "PIC": "BP. LILIK",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310959"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "GEMILANG BODY & PAINT",
      "ALAMAT": "JL. SETIABUDI NO. 152 SRONDOL SEMARANG",
      "TELEPHONE": "0812 8227 1836",
      "EMAIL": "gemilangsetiabudi.bp@gmail.com",
      "PIC": "IBU ESTI",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310960"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "ISTANA MOBIL",
      "ALAMAT": "JL. INDRAPRASTA NO. 86 SEMARANG",
      "TELEPHONE": "024 - 3511906",
      "EMAIL": "istana.mobil@yahoo.com",
      "PIC": "BP. JOKO",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310961"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "SATRIA JAYA",
      "ALAMAT": "JL. CITARUM RAYA NO. 83-85 SEMARANG",
      "TELEPHONE": "024 - 3544283",
      "EMAIL": "satria_peter@hotmail.com",
      "PIC": "IBU FANI",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310962"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL HONDA MANDALATAMA",
      "ALAMAT": "JL. SETIABUDI NO. 124 SEMARANG",
      "TELEPHONE": "024 - 7498777",
      "EMAIL": "suprihadi@hondasemarangcenter.com",
      "PIC": "BP. SUPRIHADI / BP. KELIK",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310963"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "rokhman_basuki@hondasemarangcenter.com",
      "PIC": "BP. BASUKI",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310964"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "teguh_adi@hondasemarangcenter.com",
      "PIC": "BP. TEGUH",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310965"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "",
      "ALAMAT": "",
      "TELEPHONE": "",
      "EMAIL": "body_paint@hondamandalatama.co.id",
      "PIC": "BP. ALIF",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310966"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL HONDA KUSUMA",
      "ALAMAT": "JL. SEBANDARAN NO. 51 SEMARANG",
      "TELEPHONE": "024 - 3540728 / 3543354",
      "EMAIL": "honda.kusuma@yahoo.com",
      "PIC": "BP. IWAN, BP. AGUS, BP. IRUL",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310967"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL ISTANA CENDRAWASIH",
      "ALAMAT": "JL. RADEN PATAH NO. 207 - 209 SEMARANG",
      "TELEPHONE": "024 - 3511433",
      "EMAIL": "bodyrepair.icm@istanacendrawasih.com",
      "PIC": "BP. WAGIONO / BP. NUGROHO",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310968"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL HONDA SALATIGA JAYA",
      "ALAMAT": "JL. DIPONEGORO NO. 112 SALATIGA",
      "TELEPHONE": "0298 - 328222",
      "EMAIL": "bphondasalatiga@yahoo.com",
      "PIC": "BP. KRISNA",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310969"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL HONDA KUDUS JAYA",
      "ALAMAT": "JL. LINGKAR SELATAN TIMUR TERMINAL NO. 168 KUDUS",
      "TELEPHONE": "0291 - 445666",
      "EMAIL": "bodyrepair2019.hondakudusjaya@gmail.com",
      "PIC": "BP. FINDRY",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310970"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL NASMOCO KALIGAWE",
      "ALAMAT": "JL. KALIGAWE KM. 5 SEMARANG",
      "TELEPHONE": "024 - 6580100",
      "EMAIL": "kabengbpx3w@yahoo.com",
      "PIC": "BP. MARGONO, BP. SUMARMO",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310971"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "BENGKEL NASMOCO KARANGJATI",
      "ALAMAT": "JL. SOEKARNO HATTA KM. 26 BERGAS",
      "TELEPHONE": "0298 - 5200020",
      "EMAIL": "nasmocomuin@gmail.com",
      "PIC": "BP. MU'IN",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310972"
    },
    {
      "WILAYAH": "SEMARANG",
      "NAMA BENGKEL": "PT BUMEN REDJA ABADI, Semarang",
      "ALAMAT": "JL. R.E MARTADINATA KAV. F1",
      "TELEPHONE": "024-7605506",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310973"
    },
    {
      "WILAYAH": "Serang",
      "NAMA BENGKEL": "Kim Serang",
      "ALAMAT": "JL. Raya Kagungan No. 18 Lontar Baru Serang Barat",
      "TELEPHONE": "T : 0254-7915333, 0254-7915948",
      "EMAIL": "kimtangerang@gmail.com",
      "PIC": "Husen / Estu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3516"
    },
    {
      "WILAYAH": "Serang",
      "NAMA BENGKEL": "Auto 2000 Serang",
      "ALAMAT": "Jl. Raya Pandeglang Km. 7 Tangerang",
      "TELEPHONE": "T: 0254-8242000",
      "EMAIL": "fuad.akbari@tso.astra.co.id",
      "PIC": "Siu Ing / Sugi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3517"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Anugrah Motor",
      "ALAMAT": "Jl. Temu Putih No. 55 Kav. Blok C Cilegon",
      "TELEPHONE": "(0254) 391941",
      "EMAIL": "",
      "PIC": "Dewi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310974"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Haluan Baru",
      "ALAMAT": "Jl. Raya Banten Lama No. 3A",
      "TELEPHONE": "(0254) 209828",
      "EMAIL": "",
      "PIC": "Ignatius Widjaya",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310975"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Karya Agung Otomotif Cilegon",
      "ALAMAT": "Jl. KH. Ishak Link. Seneja No. 45 Cilegon Banten 42411",
      "TELEPHONE": "(0254) 389255",
      "EMAIL": "",
      "PIC": "Mei Lan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310976"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Karya Agung Otomotif Serang",
      "ALAMAT": "Jl. Raya Serang jakarta KM. 06 Ciruas",
      "TELEPHONE": "(0254) 280396",
      "EMAIL": "",
      "PIC": "Tri / Irwan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310977"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Serang Indah Motor",
      "ALAMAT": "Jl. Raya Kagungan No. 18",
      "TELEPHONE": "(0254) 7915333",
      "EMAIL": "",
      "PIC": "Abdul / Sinta",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310978"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Sun Motor",
      "ALAMAT": "Jl. Raya Anyer Cilegon",
      "TELEPHONE": "(0254) 315147",
      "EMAIL": "",
      "PIC": "Hera / Erland",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310979"
    },
    {
      "WILAYAH": "SERANG",
      "NAMA BENGKEL": "Honda Pradana",
      "ALAMAT": "Jl. Raden Shaleh No. 8 Karang tengah, Karang Mulya Tanggerang",
      "TELEPHONE": "(021)73455888",
      "EMAIL": "",
      "PIC": "Fadli",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310980"
    },
    {
      "WILAYAH": "Sidoarjo",
      "NAMA BENGKEL": "Astra International Isuzu Sidoarjo",
      "ALAMAT": "Jl. Raya Waru KM. 15",
      "TELEPHONE": "T: 031-8541000",
      "EMAIL": "imam.hambali@iso.astra.co.id",
      "PIC": "Sugi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3518"
    },
    {
      "WILAYAH": "Sidoarjo",
      "NAMA BENGKEL": "CV. Asri",
      "ALAMAT": "Jl Raya Stadion No 146 RT04/RW02 Desa Kemiri Sidoarjo",
      "TELEPHONE": "T : 031-99715737",
      "EMAIL": "Rochmawati.23@gmail.com",
      "PIC": "Ibu Rindi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3519"
    },
    {
      "WILAYAH": "Sidoarjo",
      "NAMA BENGKEL": "Bumen Redja Abadi - Sidoarjo",
      "ALAMAT": "Jl. Raya Larangan 2, Candi",
      "TELEPHONE": "T: 031-8950467",
      "EMAIL": "supriyanto@bra.nag.co.id",
      "PIC": "Sigit / Ruby",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3520"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "WAHANA SUN SOLO",
      "ALAMAT": "JL. PALEM RAYA SOLO BARU, KEL. LANGENHARJO, KEC. GROGOL, SUKOHARJO, JAWA TENGAH",
      "TELEPHONE": "0271 - 6727906 (Ext 108 / 109)",
      "EMAIL": "teguh nugroho <teguh.nugroho@nissan.indomobil.co.id>",
      "PIC": "TEGUH HARDIANA NUGROHO / ARIS SETYA WIDODO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9479"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "MANDIRI ZIRANG UTAMA",
      "ALAMAT": "JL. IR. SUTAMI NO. 14 JEBRES SURAKARTA",
      "TELEPHONE": "0271-7889101",
      "EMAIL": "muhamadjuliariawan@gmail.com",
      "PIC": "MUHAMAD JULIAWAN",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9480"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "MITRA KERJA MOBILINDO",
      "ALAMAT": "JL. ADI SUCIPTO NO. 6  RT 01 RW 05, BLULUKAN, COLOMADU",
      "TELEPHONE": "0271 - 7653555",
      "EMAIL": "mkm_solo@yahoo.com",
      "PIC": "BP. DJOKO PURWONO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9481"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "NITRO1",
      "ALAMAT": "JL. DR. RAJIMAN 452 LAWEYAAN",
      "TELEPHONE": "(0271) 724940",
      "EMAIL": "cvnitro1@yahoo.com",
      "PIC": "ANDRY WIBOWO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9482"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "DUI PAINT SPECIALIST",
      "ALAMAT": "JL. AHMAD YANI NO. 74 RT.06/15 GILINGAN, BANJARSARI",
      "TELEPHONE": "0271 - 632322",
      "EMAIL": "duipaintspecialist@ymail.com",
      "PIC": "BP. HANDAJA LAKSANA TRI HOOPUTRA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9483"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "ANUGRAH ARTHA GUNA",
      "ALAMAT": "JL. SRIKANDI NO. 2 DAWUNG TENGAH RT. 02 RW. 15 SERENGAN SOLO",
      "TELEPHONE": "0271-651452",
      "EMAIL": "anugrah.artha.guna@gmail.com",
      "PIC": "SUPION DAN RAHMADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9484"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "MONTECARLO",
      "ALAMAT": "JL. DR. SUPOMO 6 A SOLO",
      "TELEPHONE": "0271-713244",
      "EMAIL": "brc.montecarlosolo@gmail.com",
      "PIC": "BP. ARIS SUHARTANTO, SE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9485"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "ASTRA INTERNATIONAL TBK - ISUZU",
      "ALAMAT": "JL ADI SUCIPTO NO.45 COLOMADU SOLO",
      "TELEPHONE": "0271-716486",
      "EMAIL": "wiharsanto@iso.astra.co.id",
      "PIC": "BP. WIHARSANTO/BP BUDI RIANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9486"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "BENGKEL KARYA JASA",
      "ALAMAT": "JL. SETYABUDI NO. 47, SOLO",
      "TELEPHONE": "0271 - 736206",
      "EMAIL": "karyajasa5010@gmail.com",
      "PIC": "BP. SIE TEDJO RACHMANTO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9487"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "NASMOCO ABADI MOTOR",
      "ALAMAT": "JL. RINGROAD UTARA MJOSONGO KM.9 SROYO JATEN KARANGANYAR",
      "TELEPHONE": "0271-8202303",
      "EMAIL": "bp_bamsolo@yahoo.com",
      "PIC": "DEWI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9488"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "HYUNDAI",
      "ALAMAT": "JL. IR. SOEKARNO RT. 05 RW,04 KAWASAN THE PARK MADEGONDO GROGOL SUKOHARJO",
      "TELEPHONE": "0271-7880845",
      "EMAIL": "triendri1988@gmail.com'",
      "PIC": "BP ENDRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9489"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "BANDUNG BONDO WOSO",
      "ALAMAT": "JL. TANJUNG ANOM NO. 7 KWARASAN GROGOL SUKOHARJO",
      "TELEPHONE": "0271-6727299",
      "EMAIL": "admin@bandungbondowoso.co.id",
      "PIC": "PUTRI MAHARANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9490"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "AUTO 168",
      "ALAMAT": "JL ADI SUMARMO RT 02 RW 06 KEL TOHUDAN KEC COLOMADU KARANGANYAR",
      "TELEPHONE": "0271- 7462377",
      "EMAIL": "auto168_admsl@yahoo.co.id",
      "PIC": "BP. HENDRI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9491"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "BINTANG PUTRA MOBILINDO",
      "ALAMAT": "JALAN . IR SOEKARNO 168 SOLO BARU SUKOHARJO",
      "TELEPHONE": "0271-626058",
      "EMAIL": "honda_solobaru@yahoo.com",
      "PIC": "BP RUDI SUJARWO",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9492"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "SUZUKI",
      "ALAMAT": "JL. RAYA KARTASURA KM.8 PABELAN SUKOHARJO",
      "TELEPHONE": "0271-713885",
      "EMAIL": "service.solomtr@yahoo.com",
      "PIC": "IBU HARYANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9493"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "MANDIRI JAYA INTI SEJAHTERA",
      "ALAMAT": "DUSUN III, TELUKAN GROGOL SUKOHARJO, 57552",
      "TELEPHONE": "0271-623293",
      "EMAIL": "mjm.bodyrepair@gmail.com",
      "PIC": "IBU RANI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9494"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "NASMOCO KLATEN",
      "ALAMAT": "JL. RAYA SOLO- YOGYAKARTANO 28-29, BELANG WETAN , KLATEN UTARA , KLATEN",
      "TELEPHONE": "0272 3359595",
      "EMAIL": "bp.nasmocoklaten@gmail.com",
      "PIC": "BP RIFA'I",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9495"
    },
    {
      "WILAYAH": "Solo",
      "NAMA BENGKEL": "Baraca Motor",
      "ALAMAT": "Jl. Surya No. 56 B Surakarta",
      "TELEPHONE": "T: 0271-655800",
      "EMAIL": "baracamotor@gmail.com;adm.baracamotor@gmail.com",
      "PIC": "Wawan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3521"
    },
    {
      "WILAYAH": "Solo",
      "NAMA BENGKEL": "Mitra Kerja Mobilindo (MKM)",
      "ALAMAT": "Jl. Adi Sucipto No. 6 Colomadu",
      "TELEPHONE": "T: 0271-7653555, F: 0271-737599",
      "EMAIL": "mkm_solo@yahoo.com",
      "PIC": "Ibu Nurlaela",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3522"
    },
    {
      "WILAYAH": "Solo",
      "NAMA BENGKEL": "Nasmoco Solo Ring Road",
      "ALAMAT": "Jl. Ring Road Utara Km.9 Sroyo Jaten Karanganyar",
      "TELEPHONE": "T: 0271-8202303, F: 0271-8202302",
      "EMAIL": "estimator.bamsolo@gmail.com",
      "PIC": "Ibu Anna / Ibu Astri / Adnan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3523"
    },
    {
      "WILAYAH": "Solo",
      "NAMA BENGKEL": "PT. Mitra Pratama Mobilindo",
      "ALAMAT": "Jl. Raya Solo-Kartosuro KM.5 Pabelan, Kartosuro - Sukoharjo",
      "TELEPHONE": "T : 0271 - 725817 ; F : 0271 - 725819",
      "EMAIL": "helmi.mpmpabelan@gmail.com",
      "PIC": "Ibu Merry",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3524"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Auto 168 Solo",
      "ALAMAT": "Jl Adi Sumarmo RT.002 RW.006 Karanganyar, Solo",
      "TELEPHONE": "0271-7462377",
      "EMAIL": "auto168_admsl@yahoo.co.id",
      "PIC": "Handri",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310981"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Baraca Motor",
      "ALAMAT": "Jl Surya No.56B Purwodiningratan, Solo",
      "TELEPHONE": "0271-655800",
      "EMAIL": "baracamotor@gmail.com",
      "PIC": "Eddy Hartono",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310982"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Mandiri Jaya Inti Sejahtera ( Bengkel MJM )",
      "ALAMAT": "Jl Ciu, Telukan, Grogol, Sukoharjo",
      "TELEPHONE": "0271-6726442",
      "EMAIL": "mjm.bodyrepair@gmail.com",
      "PIC": "Rani",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310983"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Mitra Kerja Mobilindo ( Bengkel MKM )",
      "ALAMAT": "Jl Adi Sucipti No.21 Colomadu, Karanganyar",
      "TELEPHONE": "0271-7653555",
      "EMAIL": "mkm_solo@yahoo.com",
      "PIC": "Banu",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310984"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Montecarlo Solo",
      "ALAMAT": "Jl Dr Supomo No. 6A Solo",
      "TELEPHONE": "0271-713244",
      "EMAIL": "brc.montecarlosolo@gmail.com",
      "PIC": "Hanik",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310985"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Nitro 1",
      "ALAMAT": "Jl Dr Rajiman 452A Laweyan, Solo",
      "TELEPHONE": "0271-724940",
      "EMAIL": "cvnitro1@yahoo.com",
      "PIC": "Hetty",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310986"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Bintang Asia Global / Hyundai Solo Baru",
      "ALAMAT": "Jl Ir Soekarno Solobaru, Kawasan The Park Mall",
      "TELEPHONE": "0271-7880845",
      "EMAIL": "triendri1988@gmail.com ; service_hyundaisolobaru@yahoo.com",
      "PIC": "Tri Endri",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310987"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Honda Adi Sucipto",
      "ALAMAT": "Jl Adi Sucipto No.150B Jajar, Solo",
      "TELEPHONE": "0271-737735",
      "EMAIL": "honda.adisucipto@gmail.com",
      "PIC": "Fendy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310988"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Honda Solo Baru",
      "ALAMAT": "Jl Ir Soekarno Solobaru, Sukoharjo",
      "TELEPHONE": "0271-626058",
      "EMAIL": "honda_solobaru@yahoo.com",
      "PIC": "Rudi Sudjarwo",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310989"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Honda Perkasa Klaten",
      "ALAMAT": "Jl Raya Solo - Jogja No.168 Tangkisan pos, Klaten",
      "TELEPHONE": "0271-3391655",
      "EMAIL": "ita.mustafa@hondaperkasaklaten.co.id",
      "PIC": "Ita Mustafa",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310990"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Honda Bintang Madiun",
      "ALAMAT": "Jl Basuki Rahmad No.3 Madiun",
      "TELEPHONE": "-",
      "EMAIL": "bodyrepair@hondabintangmadiun",
      "PIC": "Dimas",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310991"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Toyota Nasmoco Karanganyar",
      "ALAMAT": "Jl Ringroad Utara Km.9 Jatem, Karanganyar",
      "TELEPHONE": "0271-8202303",
      "EMAIL": "bp_bamsolo@yahoo.com",
      "PIC": "Yoga",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310992"
    },
    {
      "WILAYAH": "SOLO",
      "NAMA BENGKEL": "Mitra Pratama Mobilindo ( Authorized Mitsubishi )",
      "ALAMAT": "Jl Solo -Kartasura KM.5 Pabelan, Sukoharjo",
      "TELEPHONE": "0271-714852",
      "EMAIL": "mpm_bodyrepair@yahoo.co.id",
      "PIC": "Lusi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310993"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "CV. CIPENDAWA MAKMUR ABADI 1",
      "ALAMAT": "JL. RAYA SUKABUMI NO.77D CIANJUR",
      "TELEPHONE": "(0263)263355",
      "EMAIL": "cipendawa77@yahoo.com",
      "PIC": "BPK. YANCE",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9496"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "CV. CIPENDAWA MAKMUR ABADI 2",
      "ALAMAT": "JL. RAYA BANDUNG KM. 2-3 NO. 28 RT.001/005 KEL. BOJONG KEC KARANG TENGAH KAB. CIANJUR",
      "TELEPHONE": "(0263)267223",
      "EMAIL": "",
      "PIC": "BPK. UJANG, BPK YADI",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9497"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "CV. CIPENDAWA MAKMUR ABADI 3",
      "ALAMAT": "JL..PELABUHAN II NO. 272A RT. 004/013 DAYEUHLUHUR WARUDOYONG KOTA SUKABUMI",
      "TELEPHONE": "(0266) 6252866",
      "EMAIL": "cipendawa272@yahoo.com",
      "PIC": "IBU NITA",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9498"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "CV. BUARAN MOTOR",
      "ALAMAT": "JL. SEJAHTERA NO.99 CIPANENGAH, SUKABUMI",
      "TELEPHONE": "(0266) 233343",
      "EMAIL": "bmbodyrepair@gmail.com",
      "PIC": "BPK. AGUS",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9499"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "CV. HANJAYA",
      "ALAMAT": "JL.  DR. MUWARDI NO. 151 BYPASS KEL. BOJONGHERANG, KEC. CIANJUR, KAB. CIANJUR",
      "TELEPHONE": "(0263) 2291998",
      "EMAIL": "hjmmotor@yahoo.co.id",
      "PIC": "BPK. RISAL",
      "NAMA ASURANSI": "ASURANSI ACA",
      "COSTUMER SERVICE": "(021) 3199 9500"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "",
      "ALAMAT": "Jl. Lingkar Selatan No. 202 ( Jalur Baros) Kp. Sudajaya Rt. 06/02 Jaya Raksa- Baros Sukabumi",
      "TELEPHONE": "(0266) 6226308",
      "EMAIL": "",
      "PIC": "Trisna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3177"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "PT. Astra International Tbk",
      "ALAMAT": "Jl. Raya Cianjur -Sukabumi Blok Cipetir Desa Priangan Kec. Sukalarang Kab. Sukabumi",
      "TELEPHONE": "(0266) 262000",
      "EMAIL": "ari.rahman@tso.astra.co.id",
      "PIC": "Widi / Putra",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3178"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "PT. Rapih Manis Kuat ( New RMK)",
      "ALAMAT": "Jl. Pramuka No.17, Sukabumi",
      "TELEPHONE": "(0266) 222275",
      "EMAIL": "damar.harin.pratama@new-rmk.com",
      "PIC": "Yuni / Trisna",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3179"
    },
    {
      "WILAYAH": "SUKABUMI",
      "NAMA BENGKEL": "Auto 1",
      "ALAMAT": "Jl. Raya Sukabumi No.103, Sukabumi",
      "TELEPHONE": "(0263) 263737",
      "EMAIL": "auto1cjr@yahoo.com",
      "PIC": "Dewi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3180"
    },
    {
      "WILAYAH": "Sukabumi",
      "NAMA BENGKEL": "Cipendawa Motor - Sukabumi",
      "ALAMAT": "Jl. Pelabuhan II No. 272 A, Dayeuhluhur, Warudoyong Sukabumi",
      "TELEPHONE": "T: 0266-6252866",
      "EMAIL": "cipendawa272@yahoo.com",
      "PIC": "Ibu Liza / Hendra",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3525"
    },
    {
      "WILAYAH": "Sukabumi",
      "NAMA BENGKEL": "Auto 2000 Sukabumi",
      "ALAMAT": "JL.Raya Cianjur-Sukabumi km 8 Kp cipetir RT 01/01 Desa Priangan jaya",
      "TELEPHONE": "T : 0266-262000 ; F : 0266-263551",
      "EMAIL": "derys.purnamasukandar@tso.astra.co.id",
      "PIC": "Darto / Firmansyah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3526"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "YUSPEED",
      "ALAMAT": "Jl.Raya Lontar No. 28",
      "TELEPHONE": "(031) 7458574",
      "EMAIL": "-",
      "PIC": "Bpk. Yusuf Kosasih",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6395"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "MASAGUNG",
      "ALAMAT": "Jl.Kedungsari No.37",
      "TELEPHONE": "(031) 534 1574, 532 1437",
      "EMAIL": "-",
      "PIC": "Bpk.Agus Setiohadi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6396"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "KARSTEN",
      "ALAMAT": "Jl.Kedungdoro No.50",
      "TELEPHONE": "(031) 532 2060",
      "EMAIL": "-",
      "PIC": "Bpk.Agus Setiohadi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6397"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "NGAGEL TAMA MOTOR",
      "ALAMAT": "Jl.Bung Tomo No11",
      "TELEPHONE": "(031) 5042444, 5041455",
      "EMAIL": "-",
      "PIC": "Bpk.Buyung Sutikno",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6398"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "SARANA CAT",
      "ALAMAT": "Jl. Jemursari No.60",
      "TELEPHONE": "(031)8481361",
      "EMAIL": "-",
      "PIC": "Bpk Hiskia N. Irawan",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6399"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "MONOLITH NUSANTARA",
      "ALAMAT": "Jl. Sidosermo No. II /58",
      "TELEPHONE": "(031)883512,8477755",
      "EMAIL": "-",
      "PIC": "Bpk. Riza Widhirianto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6400"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "MAJU JAYA (khusus Truck)",
      "ALAMAT": "Jl. Nambangan No.126",
      "TELEPHONE": "(031) 70642316",
      "EMAIL": "-",
      "PIC": "Bpk.Yoyok",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6401"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Auto 2000  (Khusus Toyota)",
      "ALAMAT": "Jl. Raya Kenjeran No. 522-524, Surabaya",
      "TELEPHONE": "031-3892001",
      "EMAIL": "virgiawan.listianto@tso.astra.co.id",
      "PIC": "Pande Ketut Mardi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3181"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Bumen Redja Abadi  (khusus Mitsubishi)",
      "ALAMAT": "Jl. Raya Larangan No.2, Candi Sidoarjo - Surabaya",
      "TELEPHONE": "031-8950467",
      "EMAIL": "supri_bra@yahoo.co.id",
      "PIC": "Dewa Ayu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3182"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "CV. Speed Indo Pratama (SIP)",
      "ALAMAT": "Jl. Tenggilis Tengah I No.28, Surabaya",
      "TELEPHONE": "031-8496648, 031-8499649",
      "EMAIL": "sipnsip@ymail.com",
      "PIC": "Ayu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3183"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Haagenkar Autobody (khusus premium car / TSI >300 jt)",
      "ALAMAT": "Jl. Tenggilis Utara No.4-6, Surabaya",
      "TELEPHONE": "031-99852888",
      "EMAIL": "haagenkar@gmail.com",
      "PIC": "Ketut Yasa / Yanik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3184"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Hutan Alam",
      "ALAMAT": "Jl. Kenjeran 615, Surabaya",
      "TELEPHONE": "031-3894858, 031-3992336",
      "EMAIL": "bengkelhutanalam@yahoo.com",
      "PIC": "Dimas",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3185"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Indokreatif",
      "ALAMAT": "Jl. Supersemar 48 Kediri - Jawa Timur",
      "TELEPHONE": "0354-6990880",
      "EMAIL": "indokreatif.kediri@gmail.com, indokreatif@ymail.com",
      "PIC": "Sularno",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3186"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT. Sejahtera Buana Trada/ISI (Khusus Suzuki)",
      "ALAMAT": "Jl. Raya kenjeran No. 552-554, Surabaya",
      "TELEPHONE": "031-99923322",
      "EMAIL": "subhan.sbtbp@gmail.com",
      "PIC": "Andi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3187"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Jepang Motor",
      "ALAMAT": "Jl. Cendrawasih No. 21 RW/RT 06/02, Larangan, Candi, Sidoarjo",
      "TELEPHONE": "031-8073551",
      "EMAIL": "jepangmotor.cs@gmail.com",
      "PIC": "Sudarpa / Erik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3188"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Karsten",
      "ALAMAT": "Jl. Kedungdoro 50A-52, Surabaya",
      "TELEPHONE": "031-5322060",
      "EMAIL": "aghostmac@gmail.com, martha.masagung@gmail.com, santiwidyuta97.masagung@gmail.com",
      "PIC": "Dewi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3189"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Nissan Wiyung",
      "ALAMAT": "Jl. Raya Menganti No. 33, RT 005 RW 003, Kelurahan Jajar Tunggal, Kecamatan Wiyung, Surabaya",
      "TELEPHONE": "031 99751504",
      "EMAIL": "fauzianto.mahmud@nissan.indomobil.co.id",
      "PIC": "Tika Hariyanti",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3190"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT. United Motors Centre (UMC) (khusus Suzuki)",
      "ALAMAT": "Jl. A. Yani No.40-44, Surabaya",
      "TELEPHONE": "031-8290638, 031-8280612",
      "EMAIL": "service.jember@umcsuzuki.com",
      "PIC": "Tia",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3191"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Satrya Motor",
      "ALAMAT": "Jl. Jemusari Selatan IV Kav.22-23, Surabaya",
      "TELEPHONE": "031-8436368",
      "EMAIL": "satryamotor.sby@gmail.com",
      "PIC": "Bpk. Syahrul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3192"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Surya Anugrah Gempita/Honda Bintang Madiun (khusus merk Honda)",
      "ALAMAT": "Jl. Basuki Rahmad RT 10 RW 04 No.3 Kel. Sukosari, Kec. Kartoharjo, Madiun",
      "TELEPHONE": "0351-2812531, 2812532",
      "EMAIL": "bodyrepair@hondabintangmadiun.co.id",
      "PIC": "adriansyah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3193"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Surya Karya Global/Honda Surabaya (khusus merk Honda)",
      "ALAMAT": "Jl. Kranggan No. 96-98 RT. 003 RW. 002, Bubutan, Surabaya",
      "TELEPHONE": "031-99242944",
      "EMAIL": "acct.skg@gmail.com",
      "PIC": "Cipto / Cecep",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3194"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Surya Marina Kencana",
      "ALAMAT": "Kawasan Ragam Jemundo, Blok B No. 27  Sidoarjo",
      "TELEPHONE": "031-7872025",
      "EMAIL": "smarinakencana@gmail.com",
      "PIC": "Solihin / Ahmad",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3195"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Toyota Asri/CV. Asri (khusus Toyota)",
      "ALAMAT": "Jl. Stadion 146 Kemiri, Sidoarjo 61219",
      "TELEPHONE": "031-99715737",
      "EMAIL": "dwikun2076@gmail.com; rochmawati.23@gmail.com",
      "PIC": "Yuli",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3196"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "W.I.N.",
      "ALAMAT": "Jl. Mastrip 224, Surabaya",
      "TELEPHONE": "031-7669900",
      "EMAIL": "win_autobodyshop@yahoo.com liawinauto@gmail.com",
      "PIC": "Nia",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3197"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda Tuban/PT. Utomo Internasional Global  (khusus merk Honda)",
      "ALAMAT": "Jl. Dr. Wahidin Sudirohusodo 28 A, Tuban",
      "TELEPHONE": "0356- 8831888",
      "EMAIL": "acct3.uig@gmail.com",
      "PIC": "Ali / Asep",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3198"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Abadi Top Color",
      "ALAMAT": "Jl. Raya Lontar No. 27",
      "TELEPHONE": "T: 031-7421451, F: 031-7402049",
      "EMAIL": "abaditopcolor@yahoo.com",
      "PIC": "Purwanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3527"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Diamond Motor",
      "ALAMAT": "Jl. Ploso Baru No. 171-175",
      "TELEPHONE": "T: 031-3891756",
      "EMAIL": "diamond_mtr@yahoo.com",
      "PIC": "Ahmad Hidayat",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3528"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Bintang Jaya",
      "ALAMAT": "Jl. Raya Tubanan Lama Gg. Mawar X/70",
      "TELEPHONE": "T:  0853-20072135; 082143438108",
      "EMAIL": "bintangjayanew@gmail.com",
      "PIC": "tjaswan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3529"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "CX Autoworks",
      "ALAMAT": "Jl. Rungkut Asri Timur XVII No. 25",
      "TELEPHONE": "T: 031-8721012, F: 031-8721012",
      "EMAIL": "cxautoworks25@gmail.com",
      "PIC": "Mohammad Zaid",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3530"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Cahaya Body Painting",
      "ALAMAT": "Jl Bendul Merisi Tengah 71 Surabaya",
      "TELEPHONE": "T : 031-99851142",
      "EMAIL": "cahaya_painting@yahoo.com",
      "PIC": "Peter / Ian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3531"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Win Auto",
      "ALAMAT": "Jl. Mastrip 224, Surabaya",
      "TELEPHONE": "T : 031-7669900",
      "EMAIL": "win_autobodyshop@yahoo.com",
      "PIC": "Erwin / Kadik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3532"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Graha Cat Auto Body Repair",
      "ALAMAT": "Jl. Raya Banyu Urip No. 310",
      "TELEPHONE": "T: 031-5312449, F: 031-5312446",
      "EMAIL": "grahacat.bodyrepair@gmail.com",
      "PIC": "Lucky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3533"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Haagenkar (Premium cars)",
      "ALAMAT": "Jl. Raya Trenggilis Utara No. 4-6 Surabaya, Jawa Timur",
      "TELEPHONE": "T : 031 - 99852888",
      "EMAIL": "haagenkar@gmail.com; \nbodyrepairhps@gmail.com",
      "PIC": "Gerald Bilardo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3534"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Karya Agung Spet",
      "ALAMAT": "Jl. Jatisari Besar No. 45 Pepelegi, Waru - Sidoarjo",
      "TELEPHONE": "T: 031-8648062, F: 031-8556559",
      "EMAIL": "kag_spet@yahoo.co.id",
      "PIC": "Dedy Sunarya",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3535"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Lee",
      "ALAMAT": "Jl. Dinoyo No. 48",
      "TELEPHONE": "T: 031-5677110, F: 031-5669620",
      "EMAIL": "bengkel_lee@yahoo.com",
      "PIC": "Daryin / Aos",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3536"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Mischan",
      "ALAMAT": "Jl. Kalijudan No. 254",
      "TELEPHONE": "T: 031-3810404, 031-3810606, F: 031-3892340",
      "EMAIL": "mc_autobodyshop@yahoo.com",
      "PIC": "Hendra Susanta",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3537"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Skip Motor",
      "ALAMAT": "Jl. Menur 34",
      "TELEPHONE": "T: 031-5017547, F: 031-5027547",
      "EMAIL": "skipmotor@gmail.com",
      "PIC": "Harry / Ninik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3538"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Speed Indo Pratama (SIP)",
      "ALAMAT": "Jl. Tenggilis Tengah I No. 28",
      "TELEPHONE": "T: 031-8496648, F: 031-8419120",
      "EMAIL": "sipnsip@ymail.com",
      "PIC": "Taufan / Fani",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3539"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Sunsauto",
      "ALAMAT": "Jl. Karang Pilang No. 29",
      "TELEPHONE": "T: 031-7675632",
      "EMAIL": "sunsautobodyrepair@yahoo.com",
      "PIC": "Cecep",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3540"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Trijaya Body Repair",
      "ALAMAT": "Jl. Brigjend Katamso No. 123 Waru, Sidoarjo",
      "TELEPHONE": "T: 031-85581605, 0822-31299789",
      "EMAIL": "trijayabodyrepair_painting@yahoo.co.id",
      "PIC": "Elsa",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3541"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Top Mobil Mojokerto",
      "ALAMAT": "Jl. Empunala No. 425 Mojokerto",
      "TELEPHONE": "T: 031-5281899 / 081334997146",
      "EMAIL": "top.mobilmojokerto@yahoo.com",
      "PIC": "Tira",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3542"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "RAF Auto",
      "ALAMAT": "Jl. By Pass Juanda No. 21 - Surabaya",
      "TELEPHONE": "T: 082110779339 / 082338286206",
      "EMAIL": "rafautobro9991@gmail.com",
      "PIC": "Ambar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3543"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Xpress Oto paint (CV. Wijaya Mandiri)",
      "ALAMAT": "Jl. Jemursari Selatan IV No. 6 Surabaya",
      "TELEPHONE": "T : 031 - 8475104; 0878-5111-6998",
      "EMAIL": "expressotopaint.sby@gmail.com",
      "PIC": "Tri / Winda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3544"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Auto 2000 Kenjeran",
      "ALAMAT": "Jl. Raya Kenjeran 522 � 524, Kalijudan, Surabaya",
      "TELEPHONE": "T: 031-3822000 ;  031-3823737",
      "EMAIL": "eddy.susanto5109@tso.astra.co.id",
      "PIC": "Suyadhi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3545"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Honda Citra Cakra",
      "ALAMAT": "Jl. Emeral Mansion Citra Raya Blok. TX Kav. 6 Citra Land",
      "TELEPHONE": "T: 031-57431188, F: 031-57431199",
      "EMAIL": "bodypaint05.hcc@gmail.com",
      "PIC": "Mahbub Junaidi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3546"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Honda Jemursari",
      "ALAMAT": "Jl. Raya Jemursari No. 213",
      "TELEPHONE": "T: 031-8412222, F: 031-8482626",
      "EMAIL": "jms.bodypaint1@gmail.com",
      "PIC": "Eddy / Sahrul",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3547"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "PT. Surya Auto Global (Honda)",
      "ALAMAT": "Jl. Kranggan No. 96-98 Rt.03/Rw.02, Bubutan, Surabaya, Jawa Timur",
      "TELEPHONE": "T: 031-8412222, F: 031-8482626",
      "EMAIL": "acct.sag@gmail.com",
      "PIC": "Bambang Sisno/Evelyn/Riza",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3548"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Nissan - Datsun Wiyung",
      "ALAMAT": "Jl. Menganti No. 33  Kec. Wiyung ,Surabaya",
      "TELEPHONE": "T: 031- 99751504",
      "EMAIL": "anwar.anas@nissan.indomobil.co.id",
      "PIC": "Anas / Yohan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3549"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Astra Isuzu Sidoarjo",
      "ALAMAT": "Jl. Raya Waru Km. 15 Waru, Sidoarjo",
      "TELEPHONE": "T: 031-8541000",
      "EMAIL": "kuswanto@iso.astra.co.id",
      "PIC": "Kuswanto / Imam Hambali",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3550"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Toyota Asri Sidoarjo",
      "ALAMAT": "Jl Raya Stadion No 146,Desa Kemiri Sidoarjo",
      "TELEPHONE": "T: 031-99715737",
      "EMAIL": "estimator.asrimotor@gmail.com",
      "PIC": "Bakri / Cahyo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3551"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Bumen Redja Abadi Sidoarjo",
      "ALAMAT": "Jl. Raya Larangan No.2, Candi, Sidoarjo",
      "TELEPHONE": "T: 031-8950467",
      "EMAIL": "csbrasdj@yahoo.co.id",
      "PIC": "Bekti / Supriyanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3552"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "PT. United Motors Centre",
      "ALAMAT": "Jl. Raya Waru No. 55 Waru, Sidoarjo",
      "TELEPHONE": "T : 031- 8530311, 8530312; F : 031- 8541835",
      "EMAIL": "admsvc.bodyrepair@umcsuzuki.com",
      "PIC": "Sari (Admin)",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3553"
    },
    {
      "WILAYAH": "Surabaya",
      "NAMA BENGKEL": "Suzuki Kenjeran (PT. Sejahtera Buana Trada)",
      "ALAMAT": "Jl. Kenjeran No. 552, Kalijudan,Kecamatan Mulyorejo, Surabaya",
      "TELEPHONE": "T : 031 -  999 233 32",
      "EMAIL": "bustanul.arifin@gmail.com",
      "PIC": "Bustanul Arifin",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3554"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Autofocus",
      "ALAMAT": "Jl Sidosermo II no 72 A, Surabaya",
      "TELEPHONE": "031-8497622/8497620",
      "EMAIL": "autofocus.bodyrepair@yahoo.co.id",
      "PIC": "Dila",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310994"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "MECHANIC AUTO WORKZ",
      "ALAMAT": "Jl. Kalikepiting 157 A, Surabaya",
      "TELEPHONE": "031-3890888",
      "EMAIL": "bengkelmechanix@gmail.com",
      "PIC": "Didin / Bella",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310995"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Abadi Top Color",
      "ALAMAT": "Jl Raya Lontar Sambikerep No.27, Surabaya",
      "TELEPHONE": "031-7421451",
      "EMAIL": "abaditopcolor@yahoo.com",
      "PIC": "Asmaul",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310996"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "FIGO",
      "ALAMAT": "Jl Sambikerep II No. 12/A, Surabaya",
      "TELEPHONE": "031-7458609",
      "EMAIL": "trisemestaabadicv@yahoo.com",
      "PIC": "Agnes",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310997"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Win Autobody",
      "ALAMAT": "Jl Mastrip No 224, Surabaya",
      "TELEPHONE": "031-7669900",
      "EMAIL": "uutwinauto@gmail.com",
      "PIC": "Uut",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310998"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "TOP SPET ( KHUSUS KENDARAAN DIATAS RP. 450 JT )",
      "ALAMAT": "Jl Raya Sukomanunggal Jaya No.44, Surabaya",
      "TELEPHONE": "031 - 7312397",
      "EMAIL": "topspet@yahoo.com",
      "PIC": "Linda",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65310999"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "JS WORKSHOP",
      "ALAMAT": "Jln Arief Rahman Hakim 153 Surabaya",
      "TELEPHONE": "031-5923250",
      "EMAIL": "js.workshop@yahoo.com",
      "PIC": "Mimi",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311000"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Hutan Alam",
      "ALAMAT": "Jln Kenjeran No 615 Surabaya",
      "TELEPHONE": "031-3894858",
      "EMAIL": "bengkelhutanalam@yahoo.com",
      "PIC": "Arien",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311001"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Bintang Jaya",
      "ALAMAT": "Jln Raya Tubanan Lama Gang MAwar NO X 70 Surabaya Barat",
      "TELEPHONE": 82143438108,
      "EMAIL": "",
      "PIC": "Teddy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311002"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "ASTRA INTERNATIONAL DAIHATSU",
      "ALAMAT": "Jl. Raya Waru Km. 15, Surabaya",
      "TELEPHONE": "031 - 8533 777",
      "EMAIL": "sapto.pamungkas@dso.astra.co.id",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311003"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT ARMADA INTERNATIONAL MOTOR (Daihatsu)",
      "ALAMAT": "Jl Raya Juanda Rt 04/Rw 01, Sedati Agung, Sedati - Sidoarjo",
      "TELEPHONE": "031-8679099",
      "EMAIL": "aimpusat88@gmail.com",
      "PIC": "Bpk. Yudi K",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311004"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT ASCO PRIMA MOBILINDO (Daihatsu)",
      "ALAMAT": "Jl Mastrip No. 180, Mastrip Surabaya",
      "TELEPHONE": "031-5630388",
      "EMAIL": "",
      "PIC": "Bpk. Sunarno",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311005"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "AUTO 2000 (Authorized Toyota)",
      "ALAMAT": "Jl. Kenjeran 522 - 524, Surabaya",
      "TELEPHONE": "031 - 389 2001",
      "EMAIL": "",
      "PIC": "Bpk. Arif Gustam",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311006"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda Surya Karya Global/Honda Surya Agung",
      "ALAMAT": "Jl. Kranggan No.107-109, Surabaya",
      "TELEPHONE": "031-99242944",
      "EMAIL": "acct.skg@gmail.com",
      "PIC": "Ibu Evvy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311007"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda IMSI",
      "ALAMAT": "Jl. Basuki Rahmat No. 33 - 37, Surabaya",
      "TELEPHONE": "031 - 53508888",
      "EMAIL": "admin.bodyrepair.imsi@hondaeastjava.com",
      "PIC": "Bpk. Erwin / Bpk. Indra",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311008"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT SEJAHTERA BUANA TRADA (Suzuki Autorized)",
      "ALAMAT": "Jl Kenjeran No 552-554, Surabaya",
      "TELEPHONE": "031-99923322",
      "EMAIL": "bustanul.arifinn@yahoo.com / harwanto79sbt@gmail.com",
      "PIC": "Bpk. Bustanul",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311009"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "MURNI BERLIAN MOTOR (Mitsubishi Authorized)",
      "ALAMAT": "Jl. Demak 165, Surabaya",
      "TELEPHONE": "031 - 5353531-2",
      "EMAIL": "murni.brp@yahoo.com",
      "PIC": "Bpk. Dimas / Ibu Melisa",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311010"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "HARTONO RAYA MOTOR (Mercedes Benz Authorized)",
      "ALAMAT": "Jl. Demak 166-170, Surabaya",
      "TELEPHONE": "031 - 5311306",
      "EMAIL": "",
      "PIC": "Bpk. Aris Setiawan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311011"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "PT. BUMEN REDJA ABADI, Sidoarjo",
      "ALAMAT": "Jl. Raya Larangan 2, Candi",
      "TELEPHONE": "031-8921401",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311012"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Karya Agung",
      "ALAMAT": "Jl. S. Parman Gang 5 No. 14B Waru - Sidoarjo",
      "TELEPHONE": "031-8548062",
      "EMAIL": "kag_spet@yahoo.co.id",
      "PIC": "Ibu Santi / Bpk. Bima",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311013"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Asri Motor (Toyota)",
      "ALAMAT": "Jl Stadion No. 146 Kemiri, Sidoarjo",
      "TELEPHONE": "031-99715022",
      "EMAIL": "kag_spet@yahoo.co.id",
      "PIC": "Bpk. Rachmanto",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311014"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "SUZUKI UMC WARU",
      "ALAMAT": "Jln Raya Waru No 55, Waru Sidoarjo",
      "TELEPHONE": "031-8530311",
      "EMAIL": "sro.bodyrepair@umcsuzuki.com / sa.bodyrepair@umcsuzuki.com / sabodyrepair.umc.waru@gmail.com",
      "PIC": "Ibu Sari / Bpk. Wasis",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311015"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Karya Agung Gresik",
      "ALAMAT": "Jl Raya Samir Plapan No.80, Gresik",
      "TELEPHONE": 85648001466,
      "EMAIL": "kag_spet@yahoo.co.id",
      "PIC": "Bpk. Endy",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311016"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Moedah",
      "ALAMAT": "Jl. Dr Wahidin Sudirohusodo, Gresik",
      "TELEPHONE": "031-3951250",
      "EMAIL": "",
      "PIC": "Bpk. Agus",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311017"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Auto Star (CV Rona Raya)",
      "ALAMAT": "Jl. RAAK Adinegoro NO. 29 Sooko, Mojokerto",
      "TELEPHONE": 85648001466,
      "EMAIL": "autostar253@yahoo.com / bengkelautostar@gmail.com",
      "PIC": "Bpk. Roshid",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311018"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda Plaza Tuban (Honda Authorized Tuban)",
      "ALAMAT": "Jl. Dr. Wahidin Sudirohusodo No. 28 A, Tuban",
      "TELEPHONE": "031-8548062",
      "EMAIL": "yenirahma23.03@gmail.com",
      "PIC": "Ibu Yeni",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311019"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda Istana Jember (Honda Authorized Jember)",
      "ALAMAT": "Jl. Hayam Wuruk No. 187 - 189",
      "TELEPHONE": "0331-421888",
      "EMAIL": "admin.bengkel@hondajember.com",
      "PIC": "Bpk. Achmad",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311020"
    },
    {
      "WILAYAH": "SURABAYA",
      "NAMA BENGKEL": "Honda Istana Banyuwangi (Honda Authorized Banyuwangi)",
      "ALAMAT": "Jl. Hayam Wuruk No. 187 - 189",
      "TELEPHONE": "0333-417888",
      "EMAIL": "setyo.budi@hondabanyuwangi.com",
      "PIC": "Bpk. Setyo",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311021"
    },
    {
      "WILAYAH": "SURAKARTA",
      "NAMA BENGKEL": "BENGAWAN ABADI MOTOR",
      "ALAMAT": "Jl.Slamet Riyadi 558",
      "TELEPHONE": "(0271) 715355",
      "EMAIL": "-",
      "PIC": "Bpk.Heru Sulardi,Kuncoro",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6402"
    },
    {
      "WILAYAH": "SURAKARTA",
      "NAMA BENGKEL": "NITRO 1",
      "ALAMAT": "Jl.Dr.Radjiman 452 A, Laweyan",
      "TELEPHONE": "(0271) 724940 - 3029899",
      "EMAIL": "-",
      "PIC": "Bpk.Eko / Ibu Nowra / Ibu Myta",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6403"
    },
    {
      "WILAYAH": "SURAKARTA",
      "NAMA BENGKEL": "MONTE CARLO",
      "ALAMAT": "Jl. Dr. Supomo No. 6A",
      "TELEPHONE": "(0271) - 713244",
      "EMAIL": "-",
      "PIC": "BpK. Haidar Noor SE",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6404"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PD.MARGA JAYA",
      "ALAMAT": "Jl.M.H.Thamrin No.8 (d/h.Kebon Nanas)",
      "TELEPHONE": "(021) 5575 3357-9",
      "EMAIL": "-",
      "PIC": "Bpk.Sugi / Ibu Siu Ing (Mami)",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6405"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "METRO AUTO CARE",
      "ALAMAT": "Kav. Komersil III A No. 3",
      "TELEPHONE": "(021) 538 0696",
      "EMAIL": "-",
      "PIC": "Ibu Dian/ Ibu Ane",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6406"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "HANDIJAYA",
      "ALAMAT": "Jl. K.H. Hasyim Ashari No.61",
      "TELEPHONE": "(021) 55744160",
      "EMAIL": "-",
      "PIC": "Bpk. Dadang",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6407"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PT. FIARA UTAMA DJAJA",
      "ALAMAT": "Jl. KH Dewantoro No.7 Sawah Lama",
      "TELEPHONE": "(021) 7445188",
      "EMAIL": "-",
      "PIC": "Bpk. Fenny",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6408"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "CAMS (PT. Cipta Auto Makmur Sentosa)",
      "ALAMAT": "Jalan Telaga Gunung Serpong Raya no 8 Sektor Catalina Paramount Serpong",
      "TELEPHONE": "T : 021-54221066/67",
      "EMAIL": "novrilkristian@gmail.com; foce.cams@gmail.com",
      "PIC": "Bpk. Novril Kristian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3555"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Motoreko Serpong  (PT. Motoreko Mobilindo)",
      "ALAMAT": "Jl. Raya Serpong KM. 7 No. 32, Pakualam, Serpong, Tangerang",
      "TELEPHONE": "T : 021-5312 7778",
      "EMAIL": "motoreko_bodypaint@yahoo.co.id",
      "PIC": "wawan / Ibu Emi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3556"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Dua Sekawan Motor",
      "ALAMAT": "Jl. Imam Bonjol No. 39 Karawaci",
      "TELEPHONE": "T: 021-5514349, 021-5517669",
      "EMAIL": "duasekawanmotor@yahoo.com",
      "PIC": "Suparlan / Bandi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3557"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Motoreko Karawaci (PT. Motoreko Mobilindo)",
      "ALAMAT": "Jalan Raya Binong No.9, Rt.002/002, Kel. Binong, Kec. Curug, Kab. Tangerang, Karawaci - Tangerang",
      "TELEPHONE": "T : 021-2987 2900 - 2",
      "EMAIL": "ekautokarawaci@gmail.com",
      "PIC": "Mimi/Riri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3558"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Gemilang Jaya Motor",
      "ALAMAT": "Jl. KH. Hasyim Ashari No. 44 Cipondoh",
      "TELEPHONE": "T. 021-55701464, 0812-19511010",
      "EMAIL": "gemilangjayamotor@yahoo.com",
      "PIC": "Hendra / Putri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3559"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Joey Car Care",
      "ALAMAT": "Jl. Imam Bonjol No.11 Kav. Pemda Karawaci",
      "TELEPHONE": "T: 021- 55722467",
      "EMAIL": "jcc_krwc@yahoo.com",
      "PIC": "Yanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3560"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Karawaci Indah Motor",
      "ALAMAT": "Jl. Imam Bonjol No. 08, Panunggangan Barat, Tangerang, Banten",
      "TELEPHONE": "T : (021) 5517369 / (021) 55790756",
      "EMAIL": "kimtangerang@gmail.com",
      "PIC": "Fitri",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3561"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Layanan Prima 3 - Kunciran (Certified Autorized Hyundai)",
      "ALAMAT": "Jl. Raya Sultan Ageng Tirtayasa No. 7 Kunciran Indah",
      "TELEPHONE": "T: 021-73446796, 021-73446796",
      "EMAIL": "layprima3@yahoo.com",
      "PIC": "Husen / Estu",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3562"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Marga Jaya",
      "ALAMAT": "Jl. MH. Thamrin No. 8,Cikokol, Kebon Nanas",
      "TELEPHONE": "T: 021-55753357, 021-55753358",
      "EMAIL": "pd_marga_jaya@yahoo.com",
      "PIC": "Siu Ing / Sugi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3563"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "New Marga Jaya Perkasa",
      "ALAMAT": "BSD Tanggerang",
      "TELEPHONE": "T: 08158018888",
      "EMAIL": "newmargajaya@gmail.com",
      "PIC": "Sugi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3564"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Riri Motor",
      "ALAMAT": "Jl. KH. Hasyim Ashari No. 73 Cipondoh",
      "TELEPHONE": "T : 021-5570 2090 / 021-5570 2096",
      "EMAIL": "motor_riri@yahoo.com",
      "PIC": "Ibu Rindi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3565"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Tripancar Jaya",
      "ALAMAT": "Jl. Raya Cisauk Lapan, Kavling Bermis Blok B No.50, RT.007/RW.004, Cisauk \nTangerang",
      "TELEPHONE": "T : 021 - 756 2781",
      "EMAIL": "info@tripancarjaya.com",
      "PIC": "Sigit / Ruby",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3566"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Motoreko Ciputat (PT Wahjoe Poernomo Mobilindo)",
      "ALAMAT": "Jl. Ki Hajar Dewantara No. 12, Ciputat , sawah lama, Tangerang Selatan",
      "TELEPHONE": "T : 021 - 749 8800",
      "EMAIL": "ekauto.ciputat4@gmail.com; ekautociputat@gmail.com",
      "PIC": "Wawan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3567"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "RJM Pro (Relief Jaya Motor)",
      "ALAMAT": "Jl. Terusan PHG no. 99, Tangerang 15810",
      "TELEPHONE": "T : 0812-98565445",
      "EMAIL": "lela99.rjm@gmail.com",
      "PIC": "Ibu Nurlaela",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3568"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "CV. Gemilang Usaha Nusantara (GUN's) Motor",
      "ALAMAT": "Jln. Kenangan No. 38 Kongsi Baru (Terusan PHG) Gading Serpong, Tanggerang",
      "TELEPHONE": "T : 021-29419059 / 089670338933",
      "EMAIL": "gunspaintbodyrepair@gmail.com",
      "PIC": "Ibu Anna / Ibu Astri / Adnan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3569"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "PT. Auto Jaya Tekno",
      "ALAMAT": "Taman Tekno BSD Sektor XI Blok B, No.9, Tanggerang Selatan",
      "TELEPHONE": "T : 021-75875448",
      "EMAIL": "auto.jaya.tekno@gmail.com; ajt.bsd@gmail.com",
      "PIC": "Ibu Merry",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3570"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "PT. Mandiri Buana Sentosa",
      "ALAMAT": "Jl. Raya Cukanggalih Gg Binong RT 02/03 No.9, Curug - Tanggerang",
      "TELEPHONE": "T : 021-59892780 / 59411377",
      "EMAIL": "mandiribuanasentosa@yahoo.com",
      "PIC": "Ibu Liza / Hendra",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3571"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Auto 2000 Pondok Jagung",
      "ALAMAT": "Jl. Raya Serpong Km. 7 Pondok Jagung",
      "TELEPHONE": "T: 021-5395656",
      "EMAIL": "darto@tso.astra.co.id",
      "PIC": "Darto / Firmansyah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3572"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Astra Daihatsu Ciledug",
      "ALAMAT": "Jl. Hos. Cokroaminoto No. 69 Kreo, Ciledug",
      "TELEPHONE": "T: 021-7300194, F: 021-7310196",
      "EMAIL": "dso.ciledug@gmail.com",
      "PIC": "Purwanto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3573"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Auto 2000 Pasar Kemis",
      "ALAMAT": "Jln. Raya Pasar Kemis KM. 5,8 No.1",
      "TELEPHONE": "T : 021 - 22292000 ; F : 021 - 22359930",
      "EMAIL": "ahmad.hidayat@tso.astra.co.id",
      "PIC": "Ahmad Hidayat",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3574"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Astrido Toyota (PT. Astrido Jaya Mobilindo)",
      "ALAMAT": "Jalan Daan Mogot Km. 22,5, Tanah Tinggi, Tangerang",
      "TELEPHONE": "T : 021 -  5526690",
      "EMAIL": "tjaswan@astridogroup.com \nsvctoyota-bptgr@astridogroup.com",
      "PIC": "tjaswan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3575"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "PT.  Kreasi Auto Kencana (AKA) - Nissan",
      "ALAMAT": "Jl Jend Sudirman No 74 Babakan Tangerang",
      "TELEPHONE": "T : 021-55701819",
      "EMAIL": "zaid@auto-kencana.com",
      "PIC": "Mohammad Zaid",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3576"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Honda Bintang Cimone",
      "ALAMAT": "Jl. Gatot Subroto No. 25-26 Cimone, Karawaci",
      "TELEPHONE": "T: 021-29662806, 021-55662225",
      "EMAIL": "peter@hondabintang.com",
      "PIC": "Peter / Ian",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3577"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Honda Permata Serpong",
      "ALAMAT": "Jl. Boulevard Gading Serpong CBD Lot 1",
      "TELEPHONE": "T: 021-29000123, 021-29000111",
      "EMAIL": "bodyrepairhps@gmail.com",
      "PIC": "Erwin / Kadik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3578"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Honda Arta Cikupa",
      "ALAMAT": "JL. Raya Serang KM 14, Dukuh, Cikupa, Tangerang",
      "TELEPHONE": "T : 021 � 2202 8899",
      "EMAIL": "Body.Paint@honda-arta.com; lucky.somantri@honda-arta.com",
      "PIC": "Lucky",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3579"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Nissan Jatake (PT. Wahana Indo Trada)",
      "ALAMAT": "Jl. Gatot Subroto Km. 8, Jatake - Tangerang",
      "TELEPHONE": "T : 021 -  5565 5680",
      "EMAIL": "gerald.bilardo@nissan.indomobil.co.id",
      "PIC": "Gerald Bilardo",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3580"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Astra International - BMW",
      "ALAMAT": "Astra Biz Center, Jl. BSD Raya Utama No. 11 A, Serpong - Tangerang",
      "TELEPHONE": "T : 021 - 30062022",
      "EMAIL": "dedy.sunarya@bmw.astra.co.id",
      "PIC": "Dedy Sunarya",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3581"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Paja Raya Motors (PT. Dipo Internasional Pahala Otomotif)",
      "ALAMAT": "JL. Daan Mogot Km. 23 No. 36, Tangerang",
      "TELEPHONE": "T : 021 - 5522202",
      "EMAIL": "pajaraya_body@yahoo.co.id",
      "PIC": "Daryin / Aos",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3582"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "PT. Nusantara Batavia Motor (Mazda , Mitsubishi)",
      "ALAMAT": "JL. MH Thamrin no.9 cikokol Tangerang",
      "TELEPHONE": "T : 021-55740099 ; 021-55701199 ; 08128808067",
      "EMAIL": "h.susanto@nas.co.id",
      "PIC": "Hendra Susanta",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3583"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Bavaria Mercindo Motor (CBU & Luxury Cars)",
      "ALAMAT": "Jl. Kesatriaan No. 45A, Rempoa, Ciputat",
      "TELEPHONE": "T: 021-74714465, 021-74708782, F: 021-74714465",
      "EMAIL": "bmmotor_br@yahoo.com",
      "PIC": "Harry / Ninik",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3584"
    },
    {
      "WILAYAH": "Tangerang",
      "NAMA BENGKEL": "Honda Trimegah BSD",
      "ALAMAT": "Jl. Pahlawan Seribu Commercial Business District Lot VIII/1, BSD",
      "TELEPHONE": "T: 021-53165858",
      "EMAIL": "taufan@honda-bsd.com; fani@honda-bsd.com",
      "PIC": "Taufan / Fani",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3585"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "Dua Sekawan Motor",
      "ALAMAT": "Jl. Imam Bonjol No. 26 Karawaci - Tangerang",
      "TELEPHONE": "021 - 5536149",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311022"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "Laser Mobilindo",
      "ALAMAT": "Jl. Daan Mogot No.223, Batuceper, Kec. Batuceper, Kota Tangerang, Banten 15122",
      "TELEPHONE": "021 - 5518883 / 5538883",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311023"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "Gemilang Jaya Motor",
      "ALAMAT": "Jl. K.H. Hasyim Ashari No. 44, Cipondoh, Tangerang",
      "TELEPHONE": "021 - 55701464",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311024"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "Karawaci Indah Motor",
      "ALAMAT": "l. Imam Bonjol No.8, Panunggangan Bar., Kec. Cibodas, Kota Tangerang, Banten 15139",
      "TELEPHONE": "0878-8891-2223",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311025"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PT. Ambara Karya Pradana",
      "ALAMAT": "Jl. Raden Saleh No. 8 Karang Tengah Tangerang",
      "TELEPHONE": "021-73455888",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311026"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PT. Bintang Auto Semesta",
      "ALAMAT": "Jl. Gatot Subroto KM 2.5 Cimone, Karawaci, Tangerang",
      "TELEPHONE": "021-55665522",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311027"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PT. Arta Tritunggal Jaya",
      "ALAMAT": "Jl. Raya Serang KM 14, Cikupa",
      "TELEPHONE": "021-2202889",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311028"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "PT. Plaza Toyota Gading Serpong",
      "ALAMAT": "JL. Raya Boulevard Blok M 5 No 11, Gading Serpong, Kel. Pakulonan Barat, Kec. Kelapa Dua, Kab. Tanggerang�",
      "TELEPHONE": "021-29001090",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311029"
    },
    {
      "WILAYAH": "TANGERANG",
      "NAMA BENGKEL": "Nusantara Berlian Motor",
      "ALAMAT": "Jl. MH Thamrin No. 9, Cikokol",
      "TELEPHONE": "021-55740099",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311030"
    },
    {
      "WILAYAH": "TANGERANG SELATAN",
      "NAMA BENGKEL": "Trijaya Makmur Motor",
      "ALAMAT": "Jl. Cabe Raya No. 68A, Pondok Cabe Ilir, Pamulang",
      "TELEPHONE": "021 - 22741478 / 08119988755",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311031"
    },
    {
      "WILAYAH": "TANGERANG SELATAN",
      "NAMA BENGKEL": "Metro Auto Serpong",
      "ALAMAT": "Kavling Komersial III A No.3 Jl. Pahlawan Seribu � BSD City",
      "TELEPHONE": "021 � 5380696",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311032"
    },
    {
      "WILAYAH": "TANGERANG SELATAN",
      "NAMA BENGKEL": "Auto Mekanika",
      "ALAMAT": "Jl. Taman Makam ABRI No. 43, Perigi Baru, Pondok Aren - Tangerang Selatan",
      "TELEPHONE": "021 - 7451737, 7453190",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311033"
    },
    {
      "WILAYAH": "TANGERANG SELATAN",
      "NAMA BENGKEL": "PT Trans Asia Terpadu (TAT)",
      "ALAMAT": "Jl. Raya Pondok Aren No 49 A, Tangerang Selatan",
      "TELEPHONE": 89668029764,
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311034"
    },
    {
      "WILAYAH": "TANGERANG SELATAN",
      "NAMA BENGKEL": "PT. Trimegah Auto Plaza",
      "ALAMAT": "Jl. Pahlawan Seribu Commercial Business District Lot VIII No. 1 BSD, Tangerang Selatan",
      "TELEPHONE": "021-53165858",
      "EMAIL": "",
      "PIC": "",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311035"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Asia Motor Makmur (AMM)",
      "ALAMAT": "Jl. Raya Industri Blok A No.5, Jatake, Tangerang",
      "TELEPHONE": "021-5906106",
      "EMAIL": "ws.amm.jatake@acains.com",
      "PIC": "Winda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3199"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Cakrawala Automotif Rabhasa (khusus Mercy)",
      "ALAMAT": "Jl. RE. Martadinata No. 15, Pamulang Ciputat, Tanggerang Selatan 15417",
      "TELEPHONE": "021-2522292",
      "EMAIL": "rudy.kusmanto@car-mbenz.com, sri.supatmi@car-mbenz.com",
      "PIC": "Yadi / Isa",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3200"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Honda Alam Sutera/PT. Parama Sutera Autotren (Khusus Honda)",
      "ALAMAT": "Jl. Jalur Sutera Boulevard No. 38, Tangerang",
      "TELEPHONE": "021-39729999",
      "EMAIL": "bodyrepair.hta@gmail.com; son.haji@honda-alamsutera.com; sgigis@honda- alamsutera.com",
      "PIC": "Yeni / Teguh",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3201"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Honda Bintang Cimone / PT. Bintang Auto Semesta (khusus merk Honda)",
      "ALAMAT": "Jl. Gatot Subroto KM 2,5 No. 25-26 Cimone Karawaci, Tangerang 15114",
      "TELEPHONE": "021-55665522,\n29662806-08",
      "EMAIL": "peter@hondabintang.com",
      "PIC": "Yudi / Angga",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3202"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Honda Pradana/PT. Ambara Karya Pradana",
      "ALAMAT": "Jl. Raden Saleh No.8 Karang Tengah, Tangerang",
      "TELEPHONE": "021-73455888",
      "EMAIL": "hondapradana@gmail.com",
      "PIC": "Yena",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3203"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Kreasi Auto Kencana",
      "ALAMAT": "Jalan Jend. Sudirman No. 74, Babakan, Tangerang",
      "TELEPHONE": "021-55701819",
      "EMAIL": "zaid@auto-kencana.com",
      "PIC": "Suharto",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3204"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Nissan Jatake",
      "ALAMAT": "Jl. Gatot Subroto Km.8, Jatake - Tangerang",
      "TELEPHONE": "021-55655680",
      "EMAIL": "wsabodyrepair.jatake@nissan.indomobil.co.id",
      "PIC": "Pak Atom / Budi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3205"
    },
    {
      "WILAYAH": "TANGGERANG",
      "NAMA BENGKEL": "Trans Asia Terpadu (TAT)",
      "ALAMAT": "Jl. Raya Pondok Aren No. 49A, Tangerang Selatan",
      "TELEPHONE": "021-29861717",
      "EMAIL": "ws.tat01@acains.com, ws.tat02@acains.com",
      "PIC": "Bapak Bucek",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3206"
    },
    {
      "WILAYAH": "TASIKMALAYA",
      "NAMA BENGKEL": "SINAR CEMERLANG",
      "ALAMAT": "Jl.Ir.H.Juanda",
      "TELEPHONE": "(0265) 314 145",
      "EMAIL": "-",
      "PIC": "Bpk Dedi",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6409"
    },
    {
      "WILAYAH": "Tasikmalaya",
      "NAMA BENGKEL": "Tasik Oto Prima",
      "ALAMAT": "Jl. Paseh No. 32 -33 , Tasikmalaya",
      "TELEPHONE": "T: 0265-2351333, 0838-27781814",
      "EMAIL": "top.tsm.part@gmail.com",
      "PIC": "Cecep",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3586"
    },
    {
      "WILAYAH": "Tebing Tinggi",
      "NAMA BENGKEL": "Kings Auto Service",
      "ALAMAT": "Jl. Batubara No. 19",
      "TELEPHONE": "T: 0621-22436",
      "EMAIL": "elsamirkingsauto@gmail.com",
      "PIC": "Elsa",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3587"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "HONDA TEGAL RAYA",
      "ALAMAT": "Jl. Martoloyo No. 111",
      "TELEPHONE": "0283-320111",
      "EMAIL": "cendy@merdeka-group.com",
      "PIC": "Vincent Asjenwi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3207"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NASMOCO BREBES",
      "ALAMAT": "Jl. Raya Klampok, Wanasari, Brebes",
      "TELEPHONE": "0283-6172300",
      "EMAIL": "nasmocobrebesbody@gmail.com",
      "PIC": "Ricky Irawan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3208"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NIRMALA AUTOMOTIVE",
      "ALAMAT": "Jl. Yos Sudarso No. 13",
      "TELEPHONE": "0283-324004",
      "EMAIL": "nirmala-perkasa@indo.net.id",
      "PIC": "Tio",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3209"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "GEDONG JEMBAR",
      "ALAMAT": "Jl. Brawijaya No. 22 Muarareja",
      "TELEPHONE": "0283-350634",
      "EMAIL": "gedongbengkel@ymail.com",
      "PIC": "Ari",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3210"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NISSAN TEGAL",
      "ALAMAT": "Jl. Kol. Sugiono No. 124 Kemandungan",
      "TELEPHONE": "0283-359789",
      "EMAIL": "danny.mahardhika@nissan.indomobil.co.id",
      "PIC": "Ardy",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3211"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "DAIHATSU TEGAL",
      "ALAMAT": "Jl. Kol. Sugiono No. 104",
      "TELEPHONE": "0283-359677",
      "EMAIL": "siswanto2@dso.astra.co.id",
      "PIC": "Melan",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3212"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "AUTO TED'S",
      "ALAMAT": "Jl. Kapt. Sudibyo No. 73",
      "TELEPHONE": "0283-358727",
      "EMAIL": "teds_autofashion_tegal@hotmail.com",
      "PIC": "Eliz",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3213"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "RAYA KARINDO",
      "ALAMAT": "Jl. KS. Tubun No. 137 Tegal",
      "TELEPHONE": "-",
      "EMAIL": "rayakarindo@gmail.com",
      "PIC": "Hendry",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3214"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "CV. MERDEKA DWI PUTRA",
      "ALAMAT": "Jl. Martoloyo No. 141 Komplek Pasar Beras Tegal",
      "TELEPHONE": "0283-6146785",
      "EMAIL": "admbodypaint78@gmail.com",
      "PIC": "Kepala Bengkel",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3215"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "HONDA PEKALONGAN MOTOR",
      "ALAMAT": "Jl. Dr. Sutomo No. 168",
      "TELEPHONE": "0285-433800",
      "EMAIL": "ida@merdeka-group.com",
      "PIC": "Robby / Edi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3216"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NASMOCO PEKALONGAN",
      "ALAMAT": "Jl. Raya Kalibanger Km.3",
      "TELEPHONE": "0285-420020",
      "EMAIL": "efendi_pkl@yahoo.com",
      "PIC": "Saudah",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3217"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "ESA SAGARA AUTOTARA",
      "ALAMAT": "Jl. Dr. Sutomo RT.005 RW. 013 Desa Sokorejo",
      "TELEPHONE": "0285-435333",
      "EMAIL": "atom_bodyrepair@yahoo.com",
      "PIC": "Ayong",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3218"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NISSAN PEKALONGAN",
      "ALAMAT": "Jl. Dr. Sutomo, Sokorejo",
      "TELEPHONE": "0285-429377",
      "EMAIL": "kodir@nissan.indomobil.co.id",
      "PIC": "Isra Muliadi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3219"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "NEW RAGIL MOTOR",
      "ALAMAT": "Jl. A. Yani No. 16",
      "TELEPHONE": "-",
      "EMAIL": "ragilmotor@gmail.com",
      "PIC": "Dini",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3220"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "PAJAJARAN MOTOR",
      "ALAMAT": "Jl.  A. Yani No. 34",
      "TELEPHONE": "0285-42540001",
      "EMAIL": "pajajaran.motor@gmail.com",
      "PIC": "Liyantono",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3221"
    },
    {
      "WILAYAH": "TEGAL",
      "NAMA BENGKEL": "DAIHATSU PEKALONGAN",
      "ALAMAT": "Jl. Dr. Sutomo No. 12",
      "TELEPHONE": "-",
      "EMAIL": "aan.setiawan@dso.co.id",
      "PIC": "Liyantono",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3222"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "MONACO",
      "ALAMAT": "Jl.Magelang No.153",
      "TELEPHONE": "(0274) 560585",
      "EMAIL": "-",
      "PIC": "Bpk.Samuel H,Mardi H",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6410"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "ATLANTA",
      "ALAMAT": "Jl.Magelang KM.5",
      "TELEPHONE": "(0274) 522414",
      "EMAIL": "-",
      "PIC": "Bpk.Anthony / Bpk.Sapto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6411"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "NEW GENERAL",
      "ALAMAT": "Jl. Magelang KM. 8,5",
      "TELEPHONE": "(0274) - 867807",
      "EMAIL": "-",
      "PIC": "Bp. Gatot Widjaja Tjipto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6412"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "NASMOCO",
      "ALAMAT": "Jl.Gerilya Timur No.56",
      "TELEPHONE": "(0281) 634436",
      "EMAIL": "-",
      "PIC": "Bpk.Tarsono,Abrori",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6413"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "AUTO 1",
      "ALAMAT": "Jl. Magelang KM. 5,2",
      "TELEPHONE": "(0274) - 623663",
      "EMAIL": "-",
      "PIC": "Bpk. Sapto Priyanto",
      "NAMA ASURANSI": "ASURANSI ABI",
      "COSTUMER SERVICE": "(021) 626 6414"
    },
    {
      "WILAYAH": "Yogyakarta",
      "NAMA BENGKEL": "Auto Mobilindo",
      "ALAMAT": "Jl. Kabupaten Km. 3 Mayangan, Sleman",
      "TELEPHONE": "T: 0274-6412968 F: 0274-6412969",
      "EMAIL": "auto_mo@yahoo.com",
      "PIC": "Tira",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3588"
    },
    {
      "WILAYAH": "Yogyakarta",
      "NAMA BENGKEL": "Getas Indah Auto Body Repair",
      "ALAMAT": "Jl. Kebon Agung Getas Tlogoadi Mlati Sleman",
      "TELEPHONE": "T: 0274-865202",
      "EMAIL": "gtbodyrepair@gmail.com",
      "PIC": "Ambar",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3589"
    },
    {
      "WILAYAH": "Yogyakarta",
      "NAMA BENGKEL": "Maestro Yogya",
      "ALAMAT": "Jl.Imogiri Timur Km.07 Yogyakarta",
      "TELEPHONE": "T: 0274-4396699, 0274-4396568",
      "EMAIL": "bengkel_maestro@yahoo.co.id",
      "PIC": "Tri / Winda",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3590"
    },
    {
      "WILAYAH": "Yogyakarta",
      "NAMA BENGKEL": "Nasmoco Bantul",
      "ALAMAT": "Jl. Ringroad Selatan, Taman Tirto, Kasihan, Bantul�",
      "TELEPHONE": "T : 0274 � 4469567�",
      "EMAIL": "bp.bantul@yahoo.co.id; bp.bantul@gmail.com",
      "PIC": "Suyadhi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3591"
    },
    {
      "WILAYAH": "Yogyakarta",
      "NAMA BENGKEL": "Grandstar - Sumber Baru",
      "ALAMAT": "Jl. Palagan Tentara Pelajar Km. 7,8 Yogyakarta, Jawa Tengah",
      "TELEPHONE": "T : 0274-867755, 867766",
      "EMAIL": "grandstar@indosat.net.id",
      "PIC": "Mahbub Junaidi",
      "NAMA ASURANSI": "ASURANSI RELIANCE",
      "COSTUMER SERVICE": "(021) 8082 3592"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "C-MAESTRO",
      "ALAMAT": "JL. IMOGIRI BARAT NO.155B YOGYAKARTA",
      "TELEPHONE": "274,4396699",
      "EMAIL": "",
      "PIC": "Ibu Winda / Ibu Kholifah",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311036"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "BENGKEL M1",
      "ALAMAT": "JL. PALAGAN TENTARA PELAJAR YOGYAKARTA",
      "TELEPHONE": "274,868966",
      "EMAIL": "",
      "PIC": "Bpk. Kuncoro",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311037"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "BANDUNG BONDOWOSO",
      "ALAMAT": "JL. BULOG UKRIM NO 7, YOGYAKARTA",
      "TELEPHONE": "0274 - 4986623",
      "EMAIL": "",
      "PIC": "Bpk. Waskito",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311038"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "DJOKDJA DAB",
      "ALAMAT": "Jl. KALI URANG KM 16, KLEDOKAN, SLEMAN, YOGYAKARTA",
      "TELEPHONE": "0274 - 4547049",
      "EMAIL": "",
      "PIC": "Bpk. Sigit",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311039"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "TARUNA MOTOR",
      "ALAMAT": "JL. GITO GATI, BANARAN, TRIADI, SLEMAN",
      "TELEPHONE": 81220201197,
      "EMAIL": "",
      "PIC": "Bpk. Sri / Bpk. Parlan",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311040"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "GT AUTO",
      "ALAMAT": "JL. MAGELANG, JOMBOR YOGYAKARTA",
      "TELEPHONE": "0274 - 868955",
      "EMAIL": "",
      "PIC": "Bpk. Gatot / Bpk. Bahar",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311041"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "HONDA ANUGERAH BODY&PAINT",
      "ALAMAT": "JL. RAYA JANTI NO.91 KARANGJAMBE BANGUNTAPAN BANTUL 55198",
      "TELEPHONE": "274.488.508",
      "EMAIL": "",
      "PIC": "Bpk. Wahyu / Bpk. Seno",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311042"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "HONDA TUGU",
      "ALAMAT": "JL. HOS COKROAMINOTO 157 A YOGYAKARTA",
      "TELEPHONE": "274.620.408",
      "EMAIL": "",
      "PIC": "Ibu Sri Utami",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311043"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "NASMOCO BANTUL",
      "ALAMAT": "JL. RINGROAD SELATAN RT.02 RW.06 TAMANTIRTO KASIHAN",
      "TELEPHONE": "2.744.469.567",
      "EMAIL": "",
      "PIC": "Bpk. Warsito / Bpk. Dika",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311044"
    },
    {
      "WILAYAH": "YOGYAKARTA",
      "NAMA BENGKEL": "ASTRA DAIHATSU",
      "ALAMAT": "JL. MAGELANG KM.7,2 YOGYAKARTA",
      "TELEPHONE": "274.868.075",
      "EMAIL": "",
      "PIC": "Bpk. Aga",
      "NAMA ASURANSI": "ASURANSI SAHABAT",
      "COSTUMER SERVICE": "(021) 65311045"
    }
]

export default bengkel