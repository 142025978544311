<template>
    <section class="container mx-auto py-20">
        <h1 class="text-center text-3xl mb-10">Daftar Bengkel Rekanan Asuransi</h1>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            styleClass="vgt-table vgt-table2 condensed bordered striped"
                    
            >
        </vue-good-table>
    </section>
</template>

<script>
import bengkel from '@/data/bengkel.js'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
export default {
    components: {
        VueGoodTable
    },
    data() {
        return {
            columns: [
                {
                    label: 'Wilayah',
                    field: 'WILAYAH',
                    filterOptions: {
                        styleClass: 'class1', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        filterValue: 'JAKARTA TIMUR', // initial populated value for this filter
                        filterDropdownItems: this.getDistinct(bengkel, 'WILAYAH'), // dropdown (with selected values) instead of text input
                        trigger: 'enter', //only trigger on enter not on keyup 
                        },
                    },
                {
                    label: 'Nama Bengkel',
                    field: 'NAMA BENGKEL'
                },
                {
                    label: 'Alamat',
                    field: 'ALAMAT'
                },
                {
                    label: 'Telephone',
                    field: 'TELEPHONE'
                },
                {
                    label: 'Email',
                    field: 'EMAIL'
                },
                {
                    label: 'Pic',
                    field: 'PIC'
                },
                {
                    label: 'Asuransi',
                    field: 'NAMA ASURANSI',
                    filterOptions: {
                        styleClass: 'class1', // class to be added to the parent th element
                        enabled: true, // enable filter for this column
                        // filterValue: 'BALI', // initial populated value for this filter
                        filterDropdownItems: this.getDistinct(bengkel, 'NAMA ASURANSI'), // dropdown (with selected values) instead of text input
                        trigger: 'enter', //only trigger on enter not on keyup                        
                    }
                }
            ],
            rows: bengkel
        }
    },
    methods: {
        getDistinct(yoursArray, name) {
          let r;  
          if(yoursArray) {
              r = Array.from(new Set(yoursArray.map(item => item[name].toUpperCase())))
          }
          return r
        },
    },
    created() {
        this.columns[0].filterDropdownItems = this.getDistinct(this.bengkel, 'WILAYAH')
    }
}
</script>

<style>
.vgt-table2 {
    font-size: 12px !important;
}
</style>